<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                @click="loadMoreData()"
                                v-on="on"
                            >
                                <v-icon>cloud_download</v-icon>
                            </v-btn>
                        </template>
                        <span>Load More Data</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshUsers()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>
                </v-col>

                <!-- Search Result Indicator -->
                <v-col sm="4">
                </v-col>

                <!-- Action Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <!-- <v-spacer></v-spacer> -->
                    <div class="text-right">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="black white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openGenerateJournalsDialog()"
                                >
                                    <v-icon medium>mdi-book</v-icon> Generate Journals Report
                                </v-btn>
                            </template>
                            <span>Generate Journals Report</span>
                        </v-tooltip>
                        
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openAddClassificationDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add a New Classification</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%" @paneResize="paneResizing">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="user_details_headers"
                    :items="users"
                    item-key="_id"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :loading="data_table_loading"
                    :disable-pagination="true"
                >
                    <!-- Display create date -->
                    <template v-slot:[`item.created`]="{ item }">
                        <td>{{item.created.split('T')[0]}}</td>
                    </template>
                    <!-- Captured By -->
                    <template v-slot:[`item.captured_by`]="{ item }" >
                        <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                            {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                        </td>
                    </template>
                </v-data-table>
            </div>
            <multipane-resizer></multipane-resizer>
            <div class="pane tab-nav" :style="{ height: '30%', maxHeight: '50%', minHeight: '30%' }">
                <v-tabs
                    v-model="sub_tabs"
                    color="transparent"
                    class="tab-nav"
                    hide-slider
                    height="35px"
                    grow
                >
                    <!-- Keywords Sub Tab -->
                    <template>
                        <v-tab key="statement_keywords_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Statement Keywords
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                </v-tabs>
                <v-tabs-items v-model="sub_tabs">
                    <!-- Sub-Tab Toolbar -->
                    <v-toolbar
                        color="secondary"
                        dark
                        class="pa-0"
                        height="50"
                        extension-height="3"
                    >
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <!-- Statement Keywords Sub-Tab Content -->
                    <v-tab-item key="statement_keywords_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="user_role_details_headers"
                            :items="statement_keywords"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="selectUserRoleTabRow"
                            item-class="secondary"
                            hide-default-footer
                        ></v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </multipane>
        <AddClassificationDialog
            :dialog="open_add_user_dialog"
        />
        <GenerateJournalsDialog
            :dialog="open_generate_journals_dialog"
        />
    </div>
</template>
<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import AddClassificationDialog from './dialogs/add_classification';
import GenerateJournalsDialog from './dialogs/generate_journals.vue';

import { eventBus } from "../../../main";
import { emitAlert } from "../../../utils/api";

import { getClassifications } from "./gql/queries";

export default {
    components: {
        Multipane,
        MultipaneResizer,
        AddClassificationDialog,
        GenerateJournalsDialog,
    },

    data: () => ({
        sub_tabs: null,
        main_tab_height: '250px',
        sub_tab_height:'180',

        // main data-table row
        selectedClassification: null,

        // Keywords sub-tab data-table row
        selectedClassificationKeyword: null,
        selectedClassificationKeywordRow: null,

        user_details_headers: [
            { text: 'Name', value: 'name' },
            { text: 'Category', value: 'category' },
            { text: 'Created', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        user_role_details_headers: [
            { text: 'Keyword', value: 'match_key_word' },
        ],
        
        // Table data models
        statement_keywords: [],

        // Dialogs
        
        users: [],
        open_add_user_dialog: false,
        open_generate_journals_dialog: false,

        data_table_loading: false,

        page: 1,
        limit: 50,
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openAddClassificationDialog() {
            this.open_add_user_dialog = true;
        },
        openGenerateJournalsDialog () {
            this.open_generate_journals_dialog = true;
        },

        //------------------- CLOSE DIALOGS---------------------//
        closeAddClassificationDialog(new_classification) {
            if (new_classification !== null) {
                this.users.push(new_classification);
            }
            this.open_add_user_dialog = false;
        },
        closeGenerateJournalsDialog () {
            this.open_generate_journals_dialog = false;
        },

        unselectSubTabRows() {
            // Unselect statement-keywords sub-tab row
            this.selectedClassificationKeyword = null;
            if (this.selectedClassificationKeywordRow !== null) {
                this.selectedClassificationKeywordRow.select(false);
            }
        },
        selectMainTableRow(item, item_metadata) {
            this.unselectSubTabRows();

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                this.selectedClassification = item;
                // Update statement-keywords model
                this.statement_keywords = item.key_words_filters;
            } else {
                this.selectedClassification = null;
                // Unighlight selected row
                item_metadata.select(false);

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                // Update member statement-keywords model
                this.statement_keywords = [];
            }
        },
        selectUserRoleTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                this.selectedClassificationKeyword = item;
                this.selectedClassificationKeywordRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedClassificationKeyword = null;
                this.selectedClassificationKeywordRow = null;
            }
        },

        async loadMoreData() {
            this.data_table_loading = true;
            this.page += 1;
            
            try {
                const res = await this.$apollo
                                    .query({
                                        query: getClassifications,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BANK_STATEMENT_CLASSIFICATION',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }
                
                this.data_table_loading = false;
                this.users = this.users.concat(res.data.getBusinessObject.business_objects);
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async refreshUsers() {
            this.data_table_loading = true;
            this.users = [];

            this.page = 1;

            try {
                const res = await this.$apollo
                                    .query({
                                        query: getClassifications,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BANK_STATEMENT_CLASSIFICATION',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.users = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        paneResizing(pane, container, size) {
            const size_int = parseInt(size);

            this.main_tab_height = size;
            const max_screen_height = window.innerHeight - 300;

            const sub_tab_percentage = 1 - (size_int/max_screen_height);
            this.sub_tab_height = sub_tab_percentage * max_screen_height;
        }
    },

    async mounted() {
        eventBus.$on('CLOSE_ADD_CLASSIFICATION_DIALOG', this.closeAddClassificationDialog);
        eventBus.$on('CLOSE_GENERATE_JOURNALS_DIALOG', this.closeGenerateJournalsDialog);
        
        /**
         * The following logic is for manually triggering a pane-divider movement
         * so as to get the windows to properly resize
         */
        this.paneResizing(null, null, 250);

        try {
            this.data_table_loading = true;
            const res = await this.$apollo
                                    .query({
                                        query: getClassifications,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BANK_STATEMENT_CLASSIFICATION',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.data_table_loading = false;
            this.users = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>