import gql from 'graphql-tag'

export const businessProcessProspectPolicy = gql`
    mutation businessProcessProspectPolicy($input: ProspectPolicyInput!) {
        businessProcessProspectPolicy(input: $input) {
            errors
            message
            business_object {
                ... on ProspectPolicy {
                    __typename
                    _id
                    prospect_number

                    payment_date
                    # scheme_level_payment
                    payment_information {
                        premium_payer {
                            payment_method
                            percentage_amount
                        }
                        scheme {
                            payment_method
                            percentage_amount
                        }
                    }

                    collection_banking_details {
                        _id
                        same_as_settlement
                        bank {
                            _id
                            name
                            universal_branch_code
                        }
                        account_type
                        account_number
                        branch_name
                        branch_number
                    }

                    settlement_banking_details {
                        _id
                        bank {
                            _id
                            name
                            universal_branch_code
                        }
                        account_type
                        account_number
                        branch_name
                        branch_number
                    }

                    principal_member {
                        _id
                        is_premium_payer
                        name
                        surname
                        id_number
                        phone
                        email
                        physical_address {
                            line1
                            line2
                            line3
                            line4
                        }
                        id_doc {
                            id
                            mime_type
                            name
                        }
                    }

                    premium_payer {
                        _id
                        name
                        surname
                        id_number
                        phone
                        email
                        physical_address {
                            line1
                            line2
                            line3
                            line4
                        }
                    }

                    dependents {
                        _id
                        name
                        surname
                        id_number
                        spouse_to_principal
                        elected_dependent
                    }

                    scheme {
                        _id
                        name
                    }
                    agent {
                        _id
                        agent_number
                        entity {
                            _id
                            name
                            surname
                            id_number
                        }
                    }
                    product {
                        _id
                        name
                        markup_fixed
                        markup_percentage

                        primary_product {
                            _id
                            name
                            underwriter_premium
                            broker_commission
                            broker_markup

                            
                            payout_structure {
                                _id
                                payout_amount
                                category {
                                    _id
                                    name
                                    membership_category
                                    start_age
                                    end_age
                                }
                            }
                        }
                    }
                    status
                    created
                    created_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }

                    documents {
                        _id
                        type
                        mime_type
                        name

                        start_date
                        end_date

                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                }
            }
        }
    }
`

export const businessValidatePolicy = gql`
    mutation businessValidatePolicy($policy_id: String!) {
        businessValidatePolicy(policy_id: $policy_id) {
            policy {
                _id
                policy_number
            }
            overall_status
            validation_results {
                id
                rule_id
                rule_name
                policy_param_value
                min
                max
                valid
                waived
                fail_reason
            }
        }
    }
`

export const businessProcess = gql`
    mutation businessProcess($input: BusinessProcessTriggerInput!) {
        businessProcess(input: $input) {
            errors
            message
        }
    }
`