import gql from 'graphql-tag'

export const entityProcessAgent = gql`
    mutation entityProcessAgent($input: AgentInput!) {
        entityProcessAgent(input: $input) {
            errors
            message
            business_object {
                ... on Agent {
                    __typename
                    _id

                    agent_number
                    branch {
                        _id
                        name
                        branch_number
                    }
                    entity {
                        _id
                        name
                        surname
                        id_number
                    }
                    recruiting_agent {
                        _id
                        agent_number
                        entity {
                            _id
                            name
                            surname
                            id_number
                        }
                    }

                    partnership_network
                    created
                    captured_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }

                    allowed_funeral_products {
                        _id
                        product {
                            _id
                            name
                            partnership_network
                            markup_fixed
                            markup_percentage
                            partnership_network
                            created

                            primary_product {
                                _id
                                name
                                underwriter_premium
                                broker_commission
                                broker_markup
                                
                                payout_structure {
                                    _id
                                    payout_amount
                                    category {
                                        _id
                                        name
                                        membership_category
                                        start_age
                                        end_age
                                    }
                                }
                            }
                        }
                        created
                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                    }

                    commission_rules {
                        _id
                        type
                        amount
                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                    }
                    branch_history {
                        _id
                        branch {
                            _id
                            name
                            branch_number
                        }
                        start_date
                        created
                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                    }
                    documents {
                        _id
                        type
                        mime_type
                        name

                        start_date
                        end_date
                        approved
                        approved_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                }
            }
        }
    }
`