<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="5" style="margin-top:10px">
                    <v-tooltip bottom v-if="SEARCH_MODE === false">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                @click="loadMoreData()"
                                v-on="on"
                            >
                                <v-icon>cloud_download</v-icon>
                            </v-btn>
                        </template>
                        <span>Load More Data</span>
                    </v-tooltip>

                    <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="openSearchBondDialog()"
                            >
                                <v-icon>search</v-icon>
                            </v-btn>
                        </template>
                        <span>Search for Bonds</span>
                    </v-tooltip> -->

                    <v-tooltip bottom v-if="SEARCH_MODE === false">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshBonds()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="selectedBond !== null">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="openEditBondDialog()"
                            >
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </template>
                        <span>Edit Record</span>
                    </v-tooltip>
                </v-col>

                <!-- Search Result Indicator -->
                <v-col sm="3">
                    <v-card-actions v-if="SEARCH_MODE === true">
                        <h3>Search Results</h3>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="clearSearchResults()"
                                    v-on="on"
                                >
                                    <v-icon small>search</v-icon>
                                    <v-icon small>close</v-icon>
                                </v-btn>
                            </template>
                            <span>Clear Search Results</span>
                        </v-tooltip>
                    </v-card-actions>
                </v-col>

                <!-- Action Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <!-- <v-spacer></v-spacer> -->
                    <div class="text-right">
                        
                        <!-------------------------------------------------------------------------------->
                        <!----------------------------------LIFE CYCLE BUTTONS---------------------------->
                        <!-------------------------------------------------------------------------------->
                        <!-- ADD NEW CORPORATE BOND -->
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openAddCorporateBondDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Onboard a Corporate Bond</span>
                        </v-tooltip>

                        <!-- GENERATE BOND APPLICATION FORM -->
                        <v-tooltip
                            bottom
                            v-if="(
                                selectedBond !== null &&
                                selectedBond.status === BOND_STATUSES.DRAFT
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="primary white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openViewBondApplicationFormDialog(selectedBond._id)"
                                >
                                    <v-icon medium>mdi-file-document</v-icon>
                                </v-btn>
                            </template>
                            <span>Generate Application Form</span>
                        </v-tooltip>

                        <!-- ACTIVATE -->
                        <v-tooltip
                            v-if="selectedBond !== null && selectedBond.status === BOND_STATUSES.DRAFT"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="black white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="activateBond()"
                                >
                                    <v-icon medium>mdi-checkbox-marked-circle-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Activate Bond</span>
                        </v-tooltip>

                        <v-tooltip
                            bottom
                            v-if="(
                                selectedBond !== null &&
                                selectedBond.status === BOND_STATUSES.ACTIVE
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="black white--text"
                                    class="mx-2"
                                    v-on="on"
                                    :disabled="data_table_loading === true"
                                    @click="runInterestAllocationProcessForBond()"
                                >
                                <v-icon medium>mdi-download</v-icon> Run Interest Allocation Process
                                </v-btn>
                            </template>
                            <span>Run Interest Allocation Process</span>
                        </v-tooltip>

                        <!-- PAYOUT BOND -->
                        <v-tooltip
                            v-if="selectedBond !== null && selectedBond.status === BOND_STATUSES.PENDING_PAYOUT"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="black white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="markBondAsPaidOut()"
                                >
                                    <v-icon medium>mdi-cash-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Mark Bond as Paid-Out</span>
                        </v-tooltip>

                        <!-- GET DEBIT ORDER STATUS -->
                        <!-- <v-tooltip
                            bottom
                            v-if="(
                                selectedBond !== null &&
                                (
                                    selectedBond.status === BOND_STATUSES.ACTIVE &&
                                    selectedBond.bond_monthly_payment_config.payment_method === 'DEBIT ORDER'
                                )
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber primary--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openViewDebitOrderStatus()"
                                >
                                    <v-icon medium>mdi-cash-100</v-icon>
                                </v-btn>
                            </template>
                            <span>Get Debit Order Status</span>
                        </v-tooltip> -->
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%" @paneResize="paneResizing">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="bond_details_headers"
                    :items="bonds"
                    item-key="bond_number"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :loading="data_table_loading"
                    :disable-pagination="true"
                >
                    <!-- Display Formated bond number -->
                    <template v-slot:item.bond_number="{ item }">
                        <td>B{{item.bond_number}}</td>
                    </template>
                    <!-- Display Formated effective date -->
                    <template v-slot:item.effective_date="{ item }">
                        <td>{{item.effective_date.split('T')[0]}}</td>
                    </template>
                    <!-- Display Formated start date -->
                    <template v-slot:item.start_date="{ item }">
                        <td>{{item.start_date.split('T')[0]}}</td>
                    </template>
                    <!-- Display Formated end date -->
                    <template v-slot:item.end_date="{ item }">
                        <td>{{item.end_date.split('T')[0]}}</td>
                    </template>
                    <!-- Display Formated capture date -->
                    <template v-slot:item.created="{ item }">
                        <td>{{item.created.split('T')[0]}}</td>
                    </template>
                    <!-- Display Formated amount -->
                    <template v-slot:item.amount="{ item }">
                        <td>R {{formatNumber(item.amount) }}</td>
                    </template>
                    <!-- Display Formated entity -->
                    <template v-slot:item.main_entity="{ item }">
                        <td>
                            {{
                                item.main_entity !== null && item.main_entity !== undefined ? 
                                item.main_entity.name + ' ' + item.main_entity.surname + ' (' + item.main_entity.id_number + ')':
                                ''
                            }}
                        </td>
                    </template>
                    <!-- Display Formated beneficiary_entity -->
                    <template v-slot:item.beneficiary_entity="{ item }">
                        <td>
                            {{
                                item.beneficiary_entity !== null && item.beneficiary_entity !== undefined ? 
                                item.beneficiary_entity.name + ' ' + item.beneficiary_entity.surname + ' (' + item.beneficiary_entity.id_number + ')':
                                ''
                            }}
                        </td>
                    </template>
                    <!-- Display Formated agent -->
                    <template v-slot:item.agent="{ item }">
                        <td>
                            {{
                                item.agent !== null && item.agent !== undefined ? 
                                item.agent.entity.name + ' ' + item.agent.entity.surname + ' (' + item.agent.agent_number + ')':
                                ''
                            }}
                        </td>
                    </template>
                    <!-- Display Formated scheme -->
                    <template v-slot:item.scheme="{ item }">
                        <td>
                            {{
                                item.scheme !== null && item.scheme !== undefined ? 
                                item.scheme.name + ' (' + item.scheme.scheme_number + ')':
                                ''
                            }}
                        </td>
                    </template>
                    <!-- Display Formated principal Amount -->
                    <template v-slot:item.pricing_config.principal_amount="{ item }">
                        <td>
                            {{
                                item.pricing_config.principal_amount !== null && item.pricing_config.principal_amount !== undefined ? 
                                'R '+item.pricing_config.principal_amount :
                                ''
                            }}
                        </td>
                    </template>
                    <!-- Display Formated Bond Term -->
                    <template v-slot:item.pricing_config.bond_term="{ item }">
                        <td>
                            {{
                                item.pricing_config.bond_term !== null && item.pricing_config.bond_term !== undefined ? 
                                item.pricing_config.bond_term + ' ' + item.pricing_config.bond_term_units :
                                ''
                            }}
                        </td>
                    </template>
                    <!-- Display Formated Bond Type -->
                    <template v-slot:item.pricing_config.bond_type="{ item }">
                        <td v-if="item.pricing_config.bond_type === 1">FIXED-TERM LUMP SUM</td>
                        <td v-else-if="item.pricing_config.bond_type === 2">MONTHLY INSTALLMENT</td>
                        <td v-else-if="item.pricing_config.bond_type === 3">ANNUTITY</td>
                        <td v-else>Unknown</td>
                    </template>
                    <!-- User who captured the details -->
                    <template v-slot:item.captured_by="{ item }" >
                        <td v-if="item.captured_by !== null">
                            <span v-if="item.captured_by.entity !== null">
                                {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                            </span>
                            <span v-else>N/A</span>
                        </td>
                    </template>
                </v-data-table>
            </div>
            <multipane-resizer></multipane-resizer>
            <div class="pane tab-nav" :style="{ height: '30%', maxHeight: '50%', minHeight: '30%' }">
                <v-tabs
                    v-model="sub_tabs"
                    color="transparent"
                    class="tab-nav"
                    hide-slider
                    height="35px"
                    grow
                >
                    <!-- Documents Sub Tab -->
                    <template>
                        <v-tab key="documents_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Documents
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Pricing-Parameters Sub Tab -->
                    <template>
                        <v-tab key="pricing_parameters_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Bond Parameters
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Entity-Banking Details Sub Tab -->
                    <template>
                        <v-tab key="entity_banking_details_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Entity Banking
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Transactions History Sub Tab -->
                    <template>
                        <v-tab key="transactions_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Transactions History
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Installment Schedule Sub Tab -->
                    <!-- <template>
                        <v-tab key="installment_schedule_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Installment Schedule
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template> -->
                </v-tabs>
                <v-tabs-items v-model="sub_tabs">
                    <!-- Sub-Tab Toolbar -->
                    <v-toolbar
                        color="secondary"
                        dark
                        class="pa-0"
                        height="50"
                        extension-height="3"
                    >
                        <!-- Show following buttons only for the DOCUMENTS sub-tab -->
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 0 &&
                                selectedBond !== null &&
                                selectedBond.status === BOND_STATUSES.DRAFT
                            )"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddDocumentsDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Bond Document(s)</span>
                        </v-tooltip>
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 0 &&
                                selectedDocument !== null &&
                                selectedBond !== null &&
                                selectedBond.status === BOND_STATUSES.DRAFT
                            )"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openDeleteBondDocumentDialog()"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Bond Document</span>
                        </v-tooltip>
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 0 &&
                                selectedDocument !== null &&
                                selectedBond !== null
                            )"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openViewDocumentDialog()"
                                >
                                    <v-icon>content_paste</v-icon>
                                </v-btn>
                            </template>
                            <span>View Document Info</span>
                        </v-tooltip>

                        <!-- Transactions History Sub-Tab-Buttons -->
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 3 &&
                                selectedBond !== null &&
                                selectedBond.status === BOND_STATUSES.ACTIVE
                        
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="black white--text"
                                    small
                                    @click="openWithdrawBondFundsDialog()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-minus-circle</v-icon> Withdraw Bond Funds
                                </v-btn>
                            </template>
                            <span>Withdraw Bond Funds</span>
                        </v-tooltip>
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 3 &&
                                selectedBond !== null &&
                                (
                                    selectedBond.status === BOND_STATUSES.ACTIVE ||
                                    selectedBond.status === BOND_STATUSES.REPAID ||
                                    selectedBond.status === BOND_STATUSES.STAGE_1_IMPAIRABLE ||
                                    selectedBond.status === BOND_STATUSES.STAGE_2_IMPAIRABLE ||
                                    selectedBond.status === BOND_STATUSES.STAGE_3_IMPAIRABLE ||
                                    selectedBond.status === BOND_STATUSES.IMPAIRED
                                )
                        
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="amber black--text"
                                    small
                                    @click="refreshBondTransactions()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh Payment Events</span>
                        </v-tooltip>
                        <!-- <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 5 &&
                                selectedBond !== null
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="amber black--text"
                                    small
                                    @click="refreshBondTransactions()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-chart-line</v-icon>
                                </v-btn>
                            </template>
                            <span>Get Bond Premium Schedule</span>
                        </v-tooltip> -->
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <!-- Documents Sub-Tab Content -->
                    <v-tab-item key="documents_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="document_details_headers"
                            :items="bond_documents"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectDocumentsTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display create date -->
                            <template v-slot:item.created="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- User who created the details -->
                            <template v-slot:item.captured_by="{ item }" >
                                <td v-if="item.captured_by !== null">
                                    <span v-if="item.captured_by.entity !== null">
                                        {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                    </span>
                                    <span v-else>N/A</span>
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Pricing Parameters Sub-Tab Content -->
                    <v-tab-item key="pricing_parameters_sub_tab">
                        <v-card
                            :height="sub_tab_height"
                            style="overflow-y:scroll"
                        >
                            <v-card-text v-if="selectedBond !== null">
                                <!-- <v-row>
                                    <v-col cols="12" sm="12"><h2>Fees</h2></v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="selectedBond.pricing_config.fees_config.monthly_admin_fee"
                                            prefix="ZAR"
                                            color="secondary"
                                            label="Monthly Admin Fee"
                                            persistent-hint
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="selectedBond.pricing_config.fees_config.initiation_fee_principal_percentage"
                                            prefix="%"
                                            color="secondary"
                                            label="Init Fee Percentage"
                                            persistent-hint
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-checkbox
                                            v-model="selectedBond.pricing_config.fees_config.initiation_fee_paid_at_inception"
                                            color="secondary"
                                            label="Init Fee Paid At Inception"
                                            disabled
                                            required
                                        ></v-checkbox>
                                    </v-col>
                                </v-row> -->

                                <v-row>
                                    <v-col cols="12" sm="12"><h2>Interest</h2></v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="selectedBond.pricing_config.annual_interest_rate"
                                            prefix="%"
                                            color="secondary"
                                            label="Annual Interest Rate"
                                            persistent-hint
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="selectedBond.pricing_config.effective_daily_compound_interest"
                                            prefix="%"
                                            color="secondary"
                                            label="Effective Daily Compound Interest Rate"
                                            persistent-hint
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <!-- Annuity -->
                                <!-- <v-row v-if="selectedBond.pricing_config.bond_type === 3"> -->
                                <v-row>
                                    <v-col cols="12" sm="12"><h2>Annuity Details</h2></v-col>
                                </v-row>
                                <!-- <v-row v-if="selectedBond.pricing_config.bond_type === 3"> -->
                                <v-row>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="selectedBond.annuity_payout_amount"
                                            color="secondary"
                                            label="Annuity Monthly Payout"
                                            prefix="ZAR"
                                            persistent-hint
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="selectedBond.annuity_elected_monthly_payout_date"
                                            color="secondary"
                                            label="Annuity Monthly Payout Date"
                                            persistent-hint
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <!-- Monthly Installments -->
                                <!-- <v-row v-if="selectedBond.pricing_config.bond_type === 2"> -->
                                <v-row>
                                    <v-col cols="12" sm="12"><h2>Monthly Installments</h2></v-col>
                                </v-row>
                                <!-- <v-row v-if="selectedBond.pricing_config.bond_type === 2"> -->
                                <v-row>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="selectedBond.bond_monthly_payment_config.payment_method"
                                            color="secondary"
                                            label="Payment Method"
                                            persistent-hint
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="selectedBond.bond_monthly_payment_config.payment_frequency"
                                            color="secondary"
                                            label="Payment Frequency"
                                            persistent-hint
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="selectedBond.bond_monthly_payment_config.elected_payment_date"
                                            color="secondary"
                                            label="Elected Payment Date"
                                            persistent-hint
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <!-- Entity-Banking Details -->
                    <v-tab-item key="entity_banking_details_sub_tab">
                        <v-card
                            :height="sub_tab_height"
                            style="overflow-y:scroll"
                        >
                            <v-card-text v-if="selectedBond !== null">
                                <v-row
                                    v-if="(
                                        selectedBond.bond_monthly_payment_config.payment_method === 'DEBIT ORDER'
                                    )"
                                >
                                    <v-col cols="12" sm="12"><h2>Collection Details</h2></v-col>
                                    <v-col cols="12" sm="12">
                                        <v-radio-group v-model="selectedBond.collection_banking" disabled>
                                            <v-radio
                                                v-for="bankObj in selectedBond.main_entity.bank_account_details.filter(bank => { return bank.deleted === false })"
                                                :key="bankObj._id"
                                                :label="`${bankObj.bank.name} - ${bankObj.account_number}`"
                                                :value="bankObj._id"
                                                color="secondary"
                                            ></v-radio>
                                        </v-radio-group>
                                        <h1
                                            style="color:red"
                                            v-if="selectedBond.main_entity.bank_account_details.filter(bank => { return bank.deleted === false }).length === 0"
                                        >
                                            No Banking Details Captured
                                        </h1>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12"><h2>Payout Details</h2></v-col>
                                    <v-col cols="12" sm="12">
                                        <v-radio-group v-model="selectedBond.payout_banking" disabled>
                                            <v-radio
                                                v-for="bankObj in selectedBond.main_entity.bank_account_details.filter(bank => { return bank.deleted === false })"
                                                :key="bankObj._id"
                                                :label="`${bankObj.bank.name} - ${bankObj.account_number}`"
                                                :value="bankObj._id"
                                                color="secondary"
                                            ></v-radio>
                                        </v-radio-group>
                                        <h1
                                            style="color:red"
                                            v-if="selectedBond.main_entity.bank_account_details.filter(bank => { return bank.deleted === false }).length === 0"
                                        >
                                            No Banking Details Captured
                                        </h1>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <!-- Transactions History Sub-Tab Content -->
                    <v-tab-item key="transactions_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="transactions_headers"
                            :items="transactions"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="tobeDefined"
                            item-class="secondary"
                            hide-default-footer
                            :loading="transactions_loading"
                            :disable-pagination="true"
                        >
                            <!-- Transaction Date -->
                            <template v-slot:item.transaction_date="{ item }">
                                <td>{{item.transaction_date.split('T')[0]}}</td>
                            </template>
                            <!-- Display Formated created date -->
                            <template v-slot:item.created="{ item }">
                                <td>{{item.created !== null ? item.created.split('T')[0] + '  @  ' + item.created.split('T')[1].substr(0,8) : ''}}</td>
                            </template>
                            <!-- Display Formated amount -->
                            <template v-slot:item.amount="{ item }">
                                <td>R {{item.amount}}</td>
                            </template>
                            <!-- Display Formated balance -->
                            <template v-slot:item.balance="{ item }">
                                <td>R {{item.balance}}</td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Installment Schedule Sub-Tab Content -->
                    <v-tab-item key="installment_schedule_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="installment_schedule_headers"
                            :items="installment_schedule"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="tobeDefined"
                            item-class="secondary"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display Formated deadline_date: false, -->
                            <template v-slot:item.deadline_date="{ item }">
                                <td>{{item.deadline_date.split('T')[0]}}</td>
                            </template>
                            <!-- Display Formated installment_item_carried_over: false, -->
                            <template v-slot:item.installment_item_carried_over="{ item }">
                                <td>
                                    <span v-if="item.installment_item_carried_over === true">YES</span>
                                    <span v-else>NO</span>
                                </td>
                            </template>

                            <!-- Display Formated premium_principal -->
                            <template v-slot:item.premium_principal="{ item }">
                                <td>
                                    R {{item.premium_principal}} / <b>{{item.premium_principal_target}}</b>

                                    <span v-if="item.premium_principal_target > 0">
                                        <span v-if="item.premium_principal === item.premium_principal_target"><v-icon color="success">mdi-check</v-icon></span>
                                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                    </span>
                                    <span v-else>
                                        <v-icon color="success">mdi-minus-circle-outline</v-icon>
                                    </span>
                                </td>
                                <!-- <td>
                                    R {{item.premium_principal}}
                                    <span v-if="item.installment_item_carried_over === false">
                                        <span v-if="item.premium_principal_collected === true"><v-icon color="success">mdi-check</v-icon></span>
                                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                    </span>
                                    <span v-else>
                                        <span v-if="item.premium_principal === 0">
                                            <v-icon color="success">mdi-minus-circle-outline</v-icon>
                                        </span>
                                        <span v-else>
                                            <span v-if="item.premium_principal_collected === true"><v-icon color="success">mdi-check</v-icon></span>
                                            <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                        </span>
                                    </span>
                                </td> -->
                            </template>
                            <!-- Display Formated premium_admin_fee -->
                            <template v-slot:item.premium_admin_fee="{ item }">
                                <td>
                                    R {{item.premium_admin_fee}} / <b>{{item.premium_admin_fee_target}}</b>

                                    <span v-if="item.premium_admin_fee_target > 0">
                                        <span v-if="item.premium_admin_fee === item.premium_admin_fee_target"><v-icon color="success">mdi-check</v-icon></span>
                                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                    </span>
                                    <span v-else>
                                        <v-icon color="success">mdi-minus-circle-outline</v-icon>
                                    </span>
                                </td>
                                <!-- <td>
                                    R {{item.premium_admin_fee}}
                                    <span v-if="item.installment_item_carried_over === false">
                                        <span v-if="item.premium_admin_fee_collected === true"><v-icon color="success">mdi-check</v-icon></span>
                                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                    </span>
                                    <span v-else>
                                        <span v-if="item.premium_admin_fee === 0">
                                            <v-icon color="success">mdi-minus-circle-outline</v-icon>
                                        </span>
                                        <span v-else>
                                            <span v-if="item.premium_admin_fee_collected === true"><v-icon color="success">mdi-check</v-icon></span>
                                            <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                        </span>
                                    </span>
                                </td> -->
                            </template>
                            <!-- Display Formated premium_initiation_fee -->
                            <template v-slot:item.premium_initiation_fee="{ item }">
                                <td>
                                    R {{item.premium_initiation_fee}} / <b>{{item.premium_initiation_fee_target}}</b>

                                    <span v-if="item.premium_initiation_fee_target > 0">
                                        <span v-if="item.premium_initiation_fee === item.premium_initiation_fee_target"><v-icon color="success">mdi-check</v-icon></span>
                                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                    </span>
                                    <span v-else>
                                        <v-icon color="success">mdi-minus-circle-outline</v-icon>
                                    </span>
                                </td>
                                <!-- <td>
                                    R {{item.premium_initiation_fee}}
                                    <span v-if="item.installment_item_carried_over === false">
                                        <span v-if="item.premium_initiation_fee_collected === true"><v-icon color="success">mdi-check</v-icon></span>
                                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                    </span>
                                    <span v-else>
                                        <span v-if="item.premium_initiation_fee === 0">
                                            <v-icon color="success">mdi-minus-circle-outline</v-icon>
                                        </span>
                                        <span v-else>
                                            <span v-if="item.premium_initiation_fee_collected === true"><v-icon color="success">mdi-check</v-icon></span>
                                            <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                        </span>
                                    </span>
                                </td> -->
                            </template>
                            <!-- Display Formated premium_interest -->
                            <template v-slot:item.premium_interest="{ item }">
                                <td>
                                    R {{item.premium_interest}} / <b>{{item.premium_interest_target}}</b>

                                    <span v-if="item.premium_interest_target > 0">
                                        <span v-if="item.premium_interest === item.premium_interest_target"><v-icon color="success">mdi-check</v-icon></span>
                                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                    </span>
                                    <span v-else>
                                        <v-icon color="success">mdi-minus-circle-outline</v-icon>
                                    </span>
                                </td>
                                <!-- <td>
                                    R {{item.premium_interest}}
                                    <span v-if="item.installment_item_carried_over === false">
                                        <span v-if="item.premium_interest_collected === true"><v-icon color="success">mdi-check</v-icon></span>
                                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                    </span>
                                    <span v-else>
                                        <span v-if="item.premium_interest === 0">
                                            <v-icon color="success">mdi-minus-circle-outline</v-icon>
                                        </span>
                                        <span v-else>
                                            <span v-if="item.premium_interest_collected === true"><v-icon color="success">mdi-check</v-icon></span>
                                            <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                        </span>
                                    </span>
                                </td> -->
                            </template>

                            <!-- Total theoretical -->
                            <template v-slot:item.premium_total_theoretical="{ item }">
                                <v-chip
                                    color="green"
                                    dark
                                >
                                    R {{ item.premium_total_theoretical }}
                                </v-chip>
                            </template>
                            <!-- Total collected -->
                            <template v-slot:item.premium_total_collected="{ item }">
                                <v-chip
                                    :color="item.premium_total_theoretical === item.premium_total_collected ? 'green' : 'red'"
                                    dark
                                >
                                    R {{ item.premium_total_collected }}
                                </v-chip>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </multipane>
        <AddCorporateBondDialog
            :dialog="open_corporate_bond_dialog"
        />
        <SearchBondsDialog
            :dialog="open_search_bonds_dialog"
        />
        <AddBondDocumentDialog
            v-if="selectedBond !== null"
            :dialog="open_add_document_dialog"
            :bond="selectedBond"
        />
        <DeleteBondDocumentDialog
            v-if="selectedDocument !== null"
            :dialog="open_delete_bond_document_dialog"
            :bond="selectedBond"
            :document="selectedDocument"
        />
        <ViewDocumentDialog
            v-if="selectedDocument !== null"
            :dialog="open_view_bond_document_dialog"
            :document="selectedDocument"
            param_path="corporate_bond"
            :param_id="selectedBond._id"
        />
        <WithdrawBondFundsDialog
            v-if="selectedBond !== null"
            :dialog="open_withdraw_bond_funds_dialog"
            :bond="selectedBond"
        />
        <ViewDebitOrderStatusDialog
            v-if="selectedBond !== null"
            :dialog="open_view_debit_order_status_dialog"
            :loan="selectedBond"
        />
        <EditBasicLoanDialog
            v-if="selectedBond !== null"
            :dialog="open_edit_bond_dialog"
            :loan_original="selectedBond"
            :loan_copy="selectedBondCopy"
        />

        <!-- Generate Application Form -->
        <DocPreviewDialog
            :dialog="open_application_form_preview_dialog"
            :url="bond_application_form_url"
            :doc_type="bond_application_form_doc_type"
            @CLOSE_DOC_PREVIEW_DIALOG="closePolicyCertificatePreviewDialog()"
        />

        <!-- Life Cycle Management -->
        <ActivateBondDialog
            v-if="selectedBond !== null"
            :dialog="open_activate_bond_dialog"
            :bond="selectedBond"
        />
        <LoanPayoutDialog
            v-if="selectedBond !== null"
            :dialog="open_bond_payout_dialog"
            :loan="selectedBond"
        />
    </div>
</template>
<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import AddCorporateBondDialog from './dialogs/add_corporate_bond';
import SearchBondsDialog from './dialogs/search_bonds.vue';
import AddBondDocumentDialog from './dialogs/add_bond_documents.vue';
import DeleteBondDocumentDialog from './dialogs/delete_bond_document.vue';
import ViewDocumentDialog from '../../components/common_dialogs/view_document_info';
import WithdrawBondFundsDialog from './dialogs/withdraw_bond_funds.vue';
import ViewDebitOrderStatusDialog from './dialogs/view_debit_order_status.vue';
import EditBasicLoanDialog from './dialogs/edit_basic_loan_details.vue';
import DocPreviewDialog from '../../components/doc_preview';

// // Life cycle managers
import ActivateBondDialog from './dialogs/activate_bond.vue';
import LoanPayoutDialog from './dialogs/life_cycle/pending_payout/payout_loan.vue';

import { eventBus } from "../../main";
import { emitAlert, emitSuccess, generateBondApplicationForm } from "../../utils/api";
import { BOND_STATUSES } from "../../../config";


import converter from "../../mixins/converter";

import { businessProcessCorporateBond } from "./gql/mutations";
import { getCorporateBonds, getFinanceTransactionsList } from "./gql/queries";

export default {
    mixins: [converter],
    components: {
        Multipane,
        MultipaneResizer,
        AddCorporateBondDialog,
        SearchBondsDialog,
        AddBondDocumentDialog,
        DeleteBondDocumentDialog,
        ViewDocumentDialog,
        WithdrawBondFundsDialog,
        ViewDebitOrderStatusDialog,
        EditBasicLoanDialog,
        DocPreviewDialog,

        // Bond Life Cycle Dialogs
        ActivateBondDialog,
        LoanPayoutDialog,
    },

    computed: {
        
    },

    data: () => ({
        sub_tabs: null,
        main_tab_height: '250px',
        sub_tab_height:'180',

        // main data-table row
        selectedBond: null,
        selectedRow: null,

        // documents sub-tab data-table row
        selectedDocument: null,
        selectedDocumentRow: null,

        bond_details_headers: [
            { text: 'Bond Number', value: 'bond_number' },
            { text: 'Bond Type', value: 'pricing_config.bond_type' },
            { text: 'Main Entity', value: 'main_entity' },
            { text: 'Beneficiary', value: 'beneficiary_entity' },
            { text: 'Agent', value: 'agent' },
            // { text: 'Monthly Repayment Date', value: 'repayment_info.elected_repayment_time_point', width: '6%' },
            // { text: 'Payment Method', value: 'repayment_info.payment_method' },
            { text: 'Amount', value: 'pricing_config.principal_amount' },
            { text: 'Bond Term', value: 'pricing_config.bond_term' },
            { text: 'Status', value: 'status' },
            // { text: 'Effective Date', value: 'effective_date' },
            { text: 'Start Date', value: 'start_date' },
            { text: 'End Date', value: 'end_date' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Created By', value: 'captured_by' },
        ],
        document_details_headers: [
            { text: 'Document Type', value: 'type' },
            { text: 'Name', value: 'name' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        transactions_headers: [
            { text: 'Transaction Date', value: 'transaction_date' },
            { text: 'Created', value: 'created' },
            { text: 'Payment Method', value: 'from.name' },
            // { text: 'Financial Calendar Month', value: 'fin_calendar_month.name' },
            { text: 'System Reference', value: 'reference' },
            { text: 'Customer Reference', value: 'additional_reference' },
            { text: 'Amount', value: 'amount' },
            { text: 'Running Balance', value: 'balance' },
        ],
        installment_schedule_headers: [
            { text: 'Installment Number', value: 'installment_number' },
            { text: 'Deadline Date', value: 'deadline_date' },
            { text: 'Installment Status', value: 'installment_status' },
            { text: 'Carried Over', value: 'installment_item_carried_over' },

            { text: 'Principal', value: 'premium_principal' },
            { text: 'Admin Fee', value: 'premium_admin_fee' },
            { text: 'Init Fee', value: 'premium_initiation_fee' },
            { text: 'Interest', value: 'premium_interest' },

            { text: 'Total Expected', value: 'premium_total_theoretical' },
            { text: 'Total Collected', value: 'premium_total_collected' },
        ],
        
        // Table data models
        bond_documents: [],
        transactions: [],
        installment_schedule: [],

        // Dialogs
        
        bonds: [],
        open_corporate_bond_dialog: false,
        open_search_bonds_dialog: false,
        open_add_document_dialog: false,
        open_delete_bond_document_dialog: false,
        open_view_bond_document_dialog: false,
        open_withdraw_bond_funds_dialog: false,
        open_activate_bond_dialog: false,
        open_view_debit_order_status_dialog: false,
        open_edit_bond_dialog: false,

        // Application Form
        open_application_form_preview_dialog: false,

        // Life cycle management
        open_bond_payout_dialog: false,
        bond_application_form_doc_type: 'pdf',
        bond_application_form_url: null,

        data_table_loading: false,
        SEARCH_MODE: false,
        page: 1,
        limit: 50,

        BOND_STATUSES: BOND_STATUSES,
        
        selectedBondCopy: null,

        transactions_loading: false,
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openAddCorporateBondDialog() {
            this.open_corporate_bond_dialog = true;
        },
        openSearchBondDialog() {
            this.unselectSelectedRow();
            this.open_search_bonds_dialog = true;
        },
        openAddDocumentsDialog() {
            this.open_add_document_dialog = true;
        },
        openDeleteBondDocumentDialog() {
            this.open_delete_bond_document_dialog = true;
        },
        openViewDocumentDialog() {
            this.open_view_bond_document_dialog = true;
        },
        openWithdrawBondFundsDialog() {
            this.open_withdraw_bond_funds_dialog = true;
        },
        openViewDebitOrderStatus() {
            this.open_view_debit_order_status_dialog = true;
        },
        openEditBondDialog() {
            this.open_edit_bond_dialog = true;
        },
        openViewBondApplicationFormDialog(bond_id) {
            generateBondApplicationForm(bond_id).then(res => {
                const url = window.URL.createObjectURL(new Blob([res], {type: 'application/pdf'}));
                
                this.bond_application_form_url =  url;
                this.open_application_form_preview_dialog = true;
            });
        },

        resetBondEditForm(updatedBond) {
            if (
                parseFloat(updatedBond.amount) !== this.selectedBond.amount ||
                parseFloat(updatedBond.installments) !== this.selectedBond.installments ||
                parseFloat(updatedBond.elected_monthly_repayment_date) !== this.selectedBond.elected_monthly_repayment_date ||
                updatedBond.payment_method !== this.selectedBond.payment_method ||

                updatedBond.elected_monthly_repayment_date !== this.selectedBond.elected_monthly_repayment_date
            ) {
                // this.selectedBondCopy = Object.assign({}, this.selectedBond);
                this.selectedBondCopy = JSON.parse( JSON.stringify(this.selectedBond) );
            }
        },
        //------------------------------------------------------------------------------------//
        //-------------------------------- LIFE CYCLE MANAGEMENT -----------------------------//
        //------------------------------------------------------------------------------------//

        // Draft
        activateBond() {
            this.open_activate_bond_dialog = true;
        },
        markBondAsPaidOut() {
            this.open_bond_payout_dialog = true;
        },

        //------------------- CLOSE DIALOGS---------------------//
        updateUITableModel(new_bond) {
            if (new_bond !== null) {
                const obj_index = this.bonds.map(obj => { return obj._id; }).indexOf(new_bond._id)
                this.bonds[obj_index] = new_bond;

                this.selectedBond = new_bond;
                this.selectedBondCopy = JSON.parse( JSON.stringify(new_bond) );

                 // Deep copy bonds for refreshing main data table
                const temp_arr = Array.from(this.bonds);
                this.bonds = temp_arr;

                // Also update the documents tab
                this.bond_documents = new_bond.documents.filter(doc => { return !doc.deleted });
            }
        },
        closeAddCorporateBondDialog(new_bond) {
            if (new_bond !== null) {
                this.bonds.push(new_bond);
            }
            this.open_corporate_bond_dialog = false;
        },
        closeEditBondDialog(new_bond) {
            this.updateUITableModel(new_bond);
            this.open_edit_bond_dialog = false;
        },
        closeSearchBondsDialog(searchResults) {
            if (searchResults !== null) {
                this.bonds = searchResults;
                this.SEARCH_MODE = true;

                // Update bond documents model
                this.bond_documents = [];
            }
            this.open_search_bonds_dialog = false;
        },
        closeAddBondDocumentsDialog(new_bond) {
            this.updateUITableModel(new_bond);
            this.open_add_document_dialog = false;
        },
        closeDeleteBondDocumentDialog(new_bond) {
            if (new_bond !== null) {
                // Then a delete action actually happened
                this.selectedDocument = null;
                this.selectedDocumentRow = null;
            }
            this.updateUITableModel(new_bond);
            this.open_delete_bond_document_dialog = false;
        },
        closeViewServerDocumentsDialog() {
            this.open_view_bond_document_dialog = false;
        },
        closeLoanPayoutDialog(canceled) {
            if (canceled === false) {
                this.selectedBond = null;

                // Also update the documents tab
                this.bond_documents = [];

                this.refreshBonds();
                this.unselectSubTabRows();
            }

            this.open_bond_payout_dialog = false;
        },
        closeBondActivationDialog(new_bond) {
            this.updateUITableModel(new_bond);
            this.open_activate_bond_dialog = false;
        },
        closeWithdrawBondFundsDialog(new_transaction) {
            if (new_transaction !== null) {
                // Prepend to transactions array
                this.transactions.unshift(new_transaction);

                // Prepare balance
                if (new_transaction.from._id === this.selectedBond._id) {
                    new_transaction.balance = new_transaction.balance_from;
                }

                if (new_transaction.to._id === this.selectedBond._id) {
                    new_transaction.balance = new_transaction.balance_to;
                }
            }
            this.open_withdraw_bond_funds_dialog = false;
        },
        closeViewDebitOrderStatus() {
            this.open_view_debit_order_status_dialog = false;
        },
        closePolicyCertificatePreviewDialog() {
            this.bond_application_form_url = null;
            this.open_application_form_preview_dialog = false;
        },


        // Row Selector Control
        unselectSubTabRows() {
            // Unselect documents sub-tab row
            this.selectedDocument = null;
            if (this.selectedDocumentRow !== null) {
                this.selectedDocumentRow.select(false);
            }
        },
        selectMainTableRow(item, item_metadata) {
            this.unselectSubTabRows();

            // console.log('ABA: ', item);
            // console.log('ZZZ: ', item_metadata);
            this.selectedBondCopy = JSON.parse( JSON.stringify(item) );
            // console.log(this.selectedBondCopy);

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                this.selectedRow = item_metadata;

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                this.selectedBond = item;
                // Update bond documents model
                this.bond_documents = item.documents.filter(doc => { return !doc.deleted });
                // // Update installment schedule
                // this.installment_schedule = item.installment_schedule;
                
                this.transactions = [];
                // Create bond copy
                // this.selectedBondCopy = JSON.parse( JSON.stringify(this.selectedBond) );



                // this.selectedBondCopy = Object.assign({}, this.selectedBond);
                // console.log('ABA: ', this.selectedBond);
            } else {
                this.selectedBond = null;
                // Unighlight selected row
                item_metadata.select(false);
                this.selectedRow = null;

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                // Update bond documents model
                this.bond_documents = [];
                // // Update installment schedule
                // this.installment_schedule = [];
                this.transactions = [];

                // ----------------------UPDATE VISIBILITY OF ACTION BUTTONS -----------------------//
            }
        },
        selectDocumentsTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedDocument = item;
                this.selectedDocumentRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedDocument = null;
                this.selectedDocumentRow = null;
            }
        },
        tobeDefined(){},

        clearSearchResults() {
            // Unselect a row if there was any
            this.unselectSelectedRow();

            this.SEARCH_MODE = false;
            this.refreshBonds();
        },

        unselectSelectedRow() {
            if (this.selectedRow !== null) {
                this.selectedRow.select(false);
                this.selectedRow = null;

                // Update bond documents model
                this.bond_documents = [];
                // Update transactions model
                this.transactions = [];
                // // Update installment schedule
                // this.installment_schedule = [];
            }
            this.selectedBond = null;
        },

        async loadMoreData() {
            this.data_table_loading = true;

            this.page += 1;

            try {
                const res = await this.$apollo
                                        .query({
                                            query: getCorporateBonds,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'CORPORATE_BOND',
                                                pagination: {
                                                    page: this.page,
                                                    limit: this.limit
                                                }
                                            },
                                        });
                this.data_table_loading = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
    
                this.bonds = this.bonds.concat( res.data.getBusinessObject.business_objects );
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async refreshBonds() {
            this.data_table_loading = true;
            this.bonds = [];

            this.page = 1; //, limit = 20;

            try {
                const res = await this.$apollo
                                        .query({
                                            query: getCorporateBonds,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'CORPORATE_BOND',
                                                pagination: {
                                                    page: this.page,
                                                    limit: this.limit
                                                }
                                            },
                                        });
                this.data_table_loading = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
    
                this.bonds = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
        paneResizing(pane, container, size) {
            const size_int = parseInt(size);

            this.main_tab_height = size;
            const max_screen_height = window.innerHeight - 300;

            const sub_tab_percentage = 1 - (size_int/max_screen_height);
            this.sub_tab_height = sub_tab_percentage * max_screen_height;
        },

        async refreshBondTransactions() {
            try {
                this.transactions_loading = true;
                const res = await this.$apollo
                                    .query({
                                        query: getFinanceTransactionsList,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'FINANCE_ACCOUNT_TRANSACTION',
                                            pagination: {
                                                page: 1,
                                                limit: 400
                                            },
                                            searchInput: {
                                                account_id: this.selectedBond._id
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.transactions_loading = false;
                    return;
                }


                this.transactions_loading = false;
                this.transactions = res.data.getBusinessObject.business_objects;

                // Prepare balance
                // let runningBalance = 0;
                // let runningAmount = 0;
                for (const trans of this.transactions) {
                    // if (this.selectedBond._id) {
                    //     trans.balance = runningBalance;
                    // }

                    if (trans.from._id === this.selectedBond._id) {
                        trans.balance = trans.balance_from;
                    }

                    if (trans.to._id === this.selectedBond._id) {
                        trans.balance = trans.balance_to;
                    }

                    // if (this.transactions.indexOf(trans) === 0) {
                    //     if (trans.from._id === this.selectedBond._id) {
                    //         // trans.balance = trans.from_running_balance;
                    //         trans.balance = trans.from.balance;
                    //     }
    
                    //     if (trans.to._id === this.selectedBond._id) {
                    //         // trans.balance = trans.to_running_balance;
                    //         trans.balance = trans.to.balance;
                    //     }

                    //     runningBalance = trans.balance;
                    //     runningAmount = trans.amount;
                    // } else {
                    //     // NB: We're working the running balances backwards, so we've filpped the signs


                    //     if (trans.from._id === this.selectedBond._id) {
                    //         // trans.balance = trans.from_running_balance;

                    //         // runningBalance = runningBalance + trans.amount;
                    //         runningBalance = runningBalance + runningAmount;
                    //         // trans.balance = trans.from.balance;
                    //         // trans.balance = runningBalance;

                    //     }
    
                    //     if (trans.to._id === this.selectedBond._id) {
                    //         // trans.balance = trans.to_running_balance;

                    //         // runningBalance = runningBalance - trans.amount;
                    //         runningBalance = runningBalance - runningAmount;
                    //         // trans.balance = trans.to.balance;
                    //         // trans.balance = runningBalance;
                    //     }
                    //     trans.balance = runningBalance;
                    //     runningAmount = trans.amount;
                    // }
                }
            } catch (error) {
                this.transactions_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async runInterestAllocationProcessForBond() {
            this.data_table_loading = true;

            try {
                const payloadObj = {
                    OP_TYPE: 'RUN_INTEREST_ALLOCATION_PROCESS',
                    _id: this.selectedBond._id,
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessCorporateBond,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.businessProcessCorporateBond.errors === true) {
                    emitAlert(res.data.businessProcessCorporateBond.message);
                    this.data_table_loading = false;
                    return;
                }

                emitSuccess(res.data.businessProcessCorporateBond.message);
                this.data_table_loading = false;

                // Update UI model
                this.updateUITableModel(res.data.businessProcessCorporateBond.business_object);
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessCorporateBond.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },
    },

    async mounted() {
        eventBus.$on('CLOSE_ADD_CORPORATE_BOND_DIALOG', this.closeAddCorporateBondDialog);
        eventBus.$on('CLOSE_ADD_BOND_DOCUMENTS_DIALOG', this.closeAddBondDocumentsDialog);
        eventBus.$on('CLOSE_DELETE_BOND_DOCUMENT_DIALOG', this.closeDeleteBondDocumentDialog);
        eventBus.$on('CLOSE_VIEW_DOCUMENT_DIALOG', this.closeViewServerDocumentsDialog);
        eventBus.$on('CLOSE_BOND_SEARCH_DIALOG', this.closeSearchBondsDialog);
        eventBus.$on('CLOSE_WITHDRAW_BOND_FUNDS_DIALOG', this.closeWithdrawBondFundsDialog);
        eventBus.$on('CLOSE_VIEW_DEBIT_ORDER_STATUS_DIALOG', this.closeViewDebitOrderStatus);
        eventBus.$on('CLOSE_EDIT_LOAN_DIALOG', this.closeEditBondDialog);

        // Life cycle
        eventBus.$on('CLOSE_LOAN_PAYOUT_DIALOG', this.closeLoanPayoutDialog);
        eventBus.$on('CLOSE_BOND_ACTIVATION_DIALOG', this.closeBondActivationDialog);

        // Form reseters
        eventBus.$on('RESET_LOAN_EDIT_FORM', this.resetBondEditForm);


        /**
         * The following logic is for manually triggering a pane-divider movement
         * so as to get the windows to properly resize
         */
        this.paneResizing(null, null, 250);

        try {
            this.data_table_loading = true;
            const res = await this.$apollo
                                    .query({
                                        query: getCorporateBonds,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'CORPORATE_BOND',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            this.data_table_loading = false;
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                return;
            }

            this.bonds = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>