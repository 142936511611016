<template>
    <v-row justify="center">
        <!-- <v-dialog
            v-model="dialog"
            fullscreen
            persistent
        > -->
        <v-dialog
            v-model="dialog"
            persistent
        >
            <div style="background:white; overflow-y:auto; height:100%">
                <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d;">
                    <v-spacer></v-spacer>
                    <v-toolbar-title height="50">
                        <!-- <b>Capture A Policy Application</b> -->
                        <b>Capture A Policy Application {{ policy_details.selected_cover_amount === null ? '' : ` (Cover Amount: ${policy_details.selected_cover_amount.display})` }}</b>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="mx-2"
                                color="red"
                                small
                                v-on="on"
                                @click="resetForm()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Reset Form</span>
                    </v-tooltip>
                    <v-btn icon @click="closeAddPolicyDialog()" v-if="server_response_obj === null">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-stepper v-model="e1">
                    <v-stepper-header>
                        <v-stepper-step :complete="e1 > 1" step="1" color="success">Basic Details</v-stepper-step>
                        <v-divider></v-divider>
                        <!-- <v-stepper-step :complete="e1 > 2" step="2" color="success">Principal Member</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="e1 > 3" step="3" color="success">Premium Payer</v-stepper-step>
                        <v-divider></v-divider> -->
                        <v-stepper-step :complete="e1 > 2" step="2" color="success">Members</v-stepper-step>
                        <v-divider></v-divider>
                        <!-- <v-stepper-step :complete="e1 > 5" step="5" color="success">Quote Results</v-stepper-step>
                        <v-divider></v-divider> -->
                        <v-stepper-step step="3" color="success">Quote Results</v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                        <!-- Basic Details -->
                        <v-stepper-content step="1">
                            <v-card>
                                <!-- BASIC DETAILS -->
                                <v-card-text>
                                    <v-row style="background:#4490a030">
                                        <v-col cols="12" sm="12" >
                                            <h2>Basic Details</h2>
                                            <!-- <hr style="height:1px !important; background-color:grey !important; border: none !important;"> -->
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-select
                                                v-model="policy_details.selected_cover_amount"
                                                :items="cover_amounts_list"
                                                item-value="value"
                                                item-text="display"
                                                label="Cover Amount"
                                                color="secondary"
                                                hint="Cover Amount"
                                                return-object
                                                persistent-hint
                                                required
                                            ></v-select>
                                        </v-col>
                                        <!-- <v-col cols="12" sm="4">
                                            <v-select
                                                v-model="policy_details.product"
                                                :items="products"
                                                item-value="_id"
                                                item-text="name"
                                                label="Product"
                                                color="secondary"
                                                return-object
                                                :disabled="refreshing_product_lookup_list"

                                                hint="You can click the refresh icon next to the field if the product list is incomplete"
                                                persistent-hint

                                                required
                                            > -->
                                                <!-- Format prepend inner icon -->
                                                <!-- <template v-slot:prepend-inner>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon
                                                                v-on="on"
                                                                color="success"
                                                                @click="refreshProductLookupList()"
                                                            >
                                                                mdi-refresh
                                                            </v-icon>
                                                        </template>
                                                        <span>Refresh Product List</span>
                                                    </v-tooltip>
                                                </template>
                                            </v-select>
                                            <v-progress-linear
                                                color="green"
                                                class="mb-0"
                                                height="4"
                                                indeterminate
                                                :active="refreshing_product_lookup_list"
                                            ></v-progress-linear>
                                        </v-col> -->

                                        <v-col cols="12" sm="4">
                                            <v-text-field
                                                v-model="policy_details.elected_payment_date"
                                                :rules="elected_payment_date_rules"
                                                ref="elected_payment_date_field"
                                                color="secondary"
                                                label="Elected Payment Date"
                                                hint="Between 1 and 31"
                                                persistent-hint
                                                type="number"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <!-- <v-select
                                                v-if="parseFloat(policy_details.payment_information.premium_payer.percentage_amount) > 0"
                                                v-model="policy_details.payment_information.premium_payer.payment_method"
                                                :items="payment_methods"
                                                label="Payment Method"
                                                color="secondary"
                                                hint="Premium Payer Payment method"
                                                persistent-hint
                                                required
                                            ></v-select> -->
                                            <v-select
                                                v-if="parseFloat(policy_details.payment_information.premium_payer.percentage_amount) > 0"
                                                v-model="policy_details.payment_information.premium_payer.payment_method"
                                                :items="['CASH', 'DEBIT ORDER', 'EFT']"
                                                label="Payment Method"
                                                color="secondary"
                                                hint="Premium Payer Payment method"
                                                persistent-hint
                                                required
                                            ></v-select>
                                            <v-text-field
                                                v-else
                                                value="NOT APPLICABLE"
                                                hint="Member Payment method"
                                                persistent-hint
                                                disabled
                                                required
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="4">
                                            <AgentSearch :member="policy_details" label="Agent Who Brought The Policy" />
                                        </v-col>

                                        <v-col cols="12" sm="4" v-if="policy_details.payment_information.premium_payer.payment_method === 'SCHEME'">
                                            <SchemeSearch :parent="policy_details" label="Search Insurance Scheme" />
                                        </v-col>

                                        <!-- <v-col cols="12" sm="4" >
                                            <v-text-field
                                                v-model="policy_details.status"
                                                label="Status"
                                                disabled
                                                required
                                            ></v-text-field>
                                        </v-col> -->
                                    </v-row>
                                </v-card-text>
                                <br>
                                <!-- PAYMENT DETAILS -->
                                <!-- <v-card-text v-if="policy_details.product !== null"> -->
                                <v-card-text>
                                    <!-- <v-row style="background:#f6c20a73; padding-bottom:10px">
                                        <v-col cols="12" sm="12" >
                                            <h2>Policy Payment Details</h2>
                                        </v-col> -->
                                        <!-- <v-col cols="12" sm="2">
                                            <v-text-field
                                                :value="selected_product_premium"
                                                prefix="ZAR"
                                                color="secondary"
                                                label="Montly Premium"
                                                hint="The premium of the selected product"
                                                persistent-hint
                                                type="number"
                                                disabled
                                                required
                                            ></v-text-field>
                                        </v-col> -->
                                        <!-- <v-col cols="12" sm="4">
                                            <v-text-field
                                                v-model="policy_details.elected_payment_date"
                                                :rules="elected_payment_date_rules"
                                                ref="elected_payment_date_field"
                                                color="secondary"
                                                label="Elected Payment Date"
                                                hint="Between 1 and 31"
                                                persistent-hint
                                                type="number"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-select
                                                v-if="parseFloat(policy_details.payment_information.premium_payer.percentage_amount) > 0"
                                                v-model="policy_details.payment_information.premium_payer.payment_method"
                                                :items="payment_methods"
                                                label="Payment Method"
                                                color="secondary"
                                                hint="Premium Payer Payment method"
                                                persistent-hint
                                                required
                                            ></v-select>
                                            <v-text-field
                                                v-else
                                                value="NOT APPLICABLE"
                                                hint="Member Payment method"
                                                persistent-hint
                                                disabled
                                                required
                                            ></v-text-field>
                                        </v-col> -->
                                        
                                    <!-- </v-row> -->
                                    <!-- SCHEME PAYMENT OPTION -->
                                    <!-- <v-row
                                        v-if="parseFloat(policy_details.payment_information.premium_payer.percentage_amount) < 100"
                                        style="background:#4490a030; padding-bottom:10px"
                                    >
                                        <v-col cols="12" sm="4">
                                            <v-text-field
                                                value="SCHEME"
                                                label="Payer Type"
                                                color="secondary"
                                                disabled
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-select
                                                v-if="policy_details.payment_information.scheme.percentage_amount > 0"
                                                v-model="policy_details.payment_information.scheme.payment_method"
                                                :items="payment_methods"
                                                label="Payment Method"
                                                color="secondary"
                                                hint="Scheme Payment method"
                                                persistent-hint
                                                required
                                            ></v-select>
                                            <v-text-field
                                                v-else
                                                value="NOT APPLICABLE"
                                                hint="Scheme Payment method"
                                                persistent-hint
                                                disabled
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3" >
                                            <v-text-field
                                                v-model="policy_details.payment_information.scheme.percentage_amount"
                                                prefix="%"
                                                color="secondary"
                                                label="Percentage Amount"
                                                type="number"
                                                disabled
                                                required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row> -->
                                </v-card-text>
                                <!-- Button Section -->
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <!-- <v-btn
                                        fab
                                        color="amber"
                                        :disabled="goToStep2BtnDisabled()"
                                        @click="goToStep2()"
                                    >
                                        <v-icon large>keyboard_arrow_right</v-icon>
                                    </v-btn> -->
                                    <v-btn
                                        fab
                                        color="amber"
                                        :disabled="goToStep2BtnDisabled()"
                                        @click="goToStep4()"
                                    >
                                        <v-icon large>keyboard_arrow_right</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>

                        <!-- Principal Member Details Section -->
                        <v-stepper-content step="22">
                            <v-card>
                                <v-card-text>
                                    <div style="background:#4490a030; border:1px #4490a030 solid; padding:10px;">
                                        <!--  -->
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-row>
                                                    <v-col cols="12" sm="10">
                                                        <MemberSearch :member="policy_details.principal_member"/>
                                                    </v-col>
                                                    <v-col cols="12" sm="2">
                                                        <v-tooltip bottom v-if="policy_details.principal_member.member === null">
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn
                                                                    color="primary white--text"
                                                                    fab
                                                                    v-on="on"
                                                                    @click="openCaptureEntityDialog('principal_member')"
                                                                >
                                                                    <v-icon>add</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Capture Entity</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12" sm="1"></v-col>
                                            <v-col cols="12" sm="5">
                                                <v-switch
                                                    v-model="policy_details.principal_member.is_premium_payer"
                                                    color="success"
                                                    label="Is Premium Payer?"
                                                ></v-switch>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <br>

                                    <!-- Basic Details -->
                                    <div
                                        v-if="policy_details.principal_member.member !== null"
                                        style="background:#e9e9e9; border:1px #aaaaaa solid; padding:10px;"
                                    >
                                        <h3>Basic Details</h3>
                                        <v-row>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="policy_details.principal_member.name"
                                                    color="secondary"
                                                    label="Name"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="policy_details.principal_member.surname"
                                                    color="secondary"
                                                    label="Surname"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="policy_details.principal_member.phone"
                                                    color="secondary"
                                                    label="Phone"
                                                    type="number"
                                                    hint="e.g. 0721234567"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="policy_details.principal_member.email"
                                                    color="secondary"
                                                    label="Email"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row style="background:#e0cb99;">
                                            <v-col cols="12" sm="5">
                                                <v-text-field
                                                    v-model="policy_details.principal_member.id_number"
                                                    type="number"
                                                    color="secondary"
                                                    label="ID Number"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="1">
                                                <template>
                                                    <div>
                                                        <span>Id Valid</span><br>
                                                        <span v-if="policy_details.principal_member.id_number_valid === true"><v-icon color="success">mdi-check</v-icon></span>
                                                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                                    </div>
                                                </template>
                                            </v-col>
                                            <v-col cols="12" sm="2">
                                                <v-text-field
                                                    v-model="policy_details.principal_member.age"
                                                    color="secondary"
                                                    hint="Principal Member Age"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="2">
                                                <v-text-field
                                                    v-model="policy_details.principal_member.gender"
                                                    color="secondary"
                                                    hint="Gender"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="2">
                                                <v-text-field
                                                    v-model="policy_details.principal_member.cover_amount"
                                                    prefix="R"
                                                    color="secondary"
                                                    hint="Payout Amount"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <br>

                                    <!-- Banking Details -->
                                    <!-- <div
                                        v-if="policy_details.principal_member.member !== null"
                                        style="background:#e9e9e9; border:1px #aaaaaa solid; padding:10px;"
                                    >
                                        <h3>Settlement Banking Details</h3>
                                        <v-row>
                                            <v-col cols="12" sm="12">
                                                <v-radio-group v-model="policy_details.principal_member.settlement_banking">
                                                    <v-radio
                                                        v-for="bankObj in policy_details.principal_member.member.bank_account_details.filter(bank => { return bank.deleted === false })"
                                                        :key="bankObj._id"
                                                        :label="`${bankObj.bank.name} - ${bankObj.account_number}`"
                                                        :value="bankObj._id"
                                                        color="secondary"
                                                    ></v-radio>
                                                </v-radio-group>
                                                <h1
                                                    style="color:red"
                                                    v-if="policy_details.principal_member.member.bank_account_details.filter(bank => { return bank.deleted === false }).length === 0"
                                                >
                                                    No Banking Details Captured
                                                </h1>
                                            </v-col>
                                        </v-row>
                                    </div> -->
                                    <br>

                                    <!-- Physical Address -->
                                    <div
                                        v-if="policy_details.principal_member.member !== null"
                                        style="background:#e9e9e9; border:1px #aaaaaa solid; padding:10px;"
                                    >
                                        <h3>Physical Address</h3>
                                        <v-row>
                                            <v-col cols="12" sm="4">
                                                <v-text-field
                                                    v-model="policy_details.principal_member.physical_address.line1"
                                                    color="secondary"
                                                    label="Line 1"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4">
                                                <v-text-field
                                                    v-model="policy_details.principal_member.physical_address.line2"
                                                    color="secondary"
                                                    label="Line 2"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="2">
                                                <v-text-field
                                                    v-model="policy_details.principal_member.physical_address.line3"
                                                    color="secondary"
                                                    label="Line 3"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="2">
                                                <v-text-field
                                                    v-model="policy_details.principal_member.physical_address.line4"
                                                    type="number"
                                                    color="secondary"
                                                    label="Postal Code"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <br>

                                    <!-- Documents -->
                                    <div
                                        v-if="policy_details.principal_member.member !== null"
                                        style="background:#e9e9e9; border:1px #aaaaaa solid; padding:10px;"
                                    >
                                        <h3>ID Document</h3>
                                        <v-row v-if="policy_details.principal_member.id_doc.server_object !== null">
                                            <!-- ID Doc -->
                                            <v-col cols="12" sm="11">
                                                <v-text-field
                                                    :value="policy_details.principal_member.id_doc.server_object === null ? '' : policy_details.principal_member.id_doc.server_object.name"
                                                    color="secondary"
                                                    label="ID Document"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="1">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            fab
                                                            color="amber"
                                                            v-on="on"
                                                            @click="openViewDocumentDialog()"
                                                            :disabled="policy_details.principal_member.id_doc.server_object === null"
                                                        >
                                                            <v-icon>remove_red_eye</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Preview File</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                        <v-row v-else>
                                            <v-col cols="12" sm="12">
                                                <h1 style="color:red">
                                                    No Id Document Uploaded
                                                </h1>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <br>
                                </v-card-text>
                                <!-- Button Section -->
                                <v-card-actions>
                                    <v-btn
                                        fab
                                        color="amber"
                                        @click="e1 = 1"
                                    >
                                        <v-icon large>keyboard_arrow_left</v-icon>
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        fab
                                        color="amber"
                                        :disabled="goToStep3BtnDisabled()"
                                        @click="goToStep3()"
                                    >
                                        <v-icon large>keyboard_arrow_right</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>

                        <!-- Premium Payer details -->
                        <v-stepper-content step="33">
                            <v-card>
                                <v-card-text>
                                    <!-- Basic Details -->
                                    <div style="background:#4490a030; border:1px #4490a030 solid; padding:10px;">
                                        <h3>Basic Details</h3>

                                        <v-row>
                                            <v-col cols="12" sm="5">
                                                <v-switch
                                                    v-model="policy_details.principal_member.is_premium_payer"
                                                    color="success"
                                                    label="Is Premium Payer?"
                                                ></v-switch>
                                            </v-col>
                                            <v-col cols="12" sm="1"></v-col>
                                            <v-col cols="12" sm="6" v-if="policy_details.principal_member.is_premium_payer === false">
                                                <v-row>
                                                    <v-col cols="12" sm="10">
                                                        <MemberSearch :member="policy_details.premium_payer"/>
                                                    </v-col>
                                                    <v-col cols="12" sm="2">
                                                        <v-tooltip bottom v-if="policy_details.premium_payer.member === null">
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn
                                                                    color="primary white--text"
                                                                    fab
                                                                    v-on="on"
                                                                    @click="openCaptureEntityDialog('premium_payer')"
                                                                >
                                                                    <v-icon>add</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Capture Entity</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <br>

                                    <!-- Basic Details -->
                                    <div
                                        v-if="policy_details.premium_payer.member !== null"
                                        style="background:#e9e9e9; border:1px #aaaaaa solid; padding:10px;"
                                    >
                                        <v-row>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="policy_details.premium_payer.name"
                                                    color="secondary"
                                                    label="Name"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="policy_details.premium_payer.surname"
                                                    color="secondary"
                                                    label="Surname"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="policy_details.premium_payer.phone"
                                                    color="secondary"
                                                    label="Phone"
                                                    type="number"
                                                    hint="e.g. 0721234567"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="policy_details.premium_payer.email"
                                                    color="secondary"
                                                    label="Email"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row style="background:#e0cb99;">
                                            <v-col cols="12" sm="5">
                                                <v-text-field
                                                    v-model="policy_details.premium_payer.id_number"
                                                    type="number"
                                                    color="secondary"
                                                    label="ID Number"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="1">
                                                <template>
                                                    <div>
                                                        <span>Id Valid</span><br>
                                                        <span v-if="policy_details.premium_payer.id_number_valid === true"><v-icon color="success">mdi-check</v-icon></span>
                                                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                                    </div>
                                                </template>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <br>

                                    <!-- Banking Details -->
                                    <div
                                        v-if="(
                                            policy_details.premium_payer.member !== null &&
                                            policy_details.payment_information.premium_payer.payment_method === 'DEBIT ORDER'
                                        )"
                                        style="background:#e9e9e9; border:1px #aaaaaa solid; padding:10px;"
                                    >
                                        <h3>Collection Banking Details</h3>
                                        <v-row>
                                            <v-col cols="12" sm="12">
                                                <v-radio-group v-model="policy_details.premium_payer.collection_banking">
                                                    <v-radio
                                                        v-for="bankObj in policy_details.premium_payer.member.bank_account_details.filter(bank => { return bank.deleted === false })"
                                                        :key="bankObj._id"
                                                        :label="`${bankObj.bank.name} - ${bankObj.account_number}`"
                                                        :value="bankObj._id"
                                                        color="secondary"
                                                    ></v-radio>
                                                </v-radio-group>
                                                <h1
                                                    style="color:red"
                                                    v-if="policy_details.premium_payer.member.bank_account_details.filter(bank => { return bank.deleted === false }).length === 0"
                                                >
                                                    No Banking Details Captured
                                                </h1>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <br>

                                    <!-- Physical Address -->
                                    <div
                                        v-if="policy_details.premium_payer.member !== null"
                                        style="background:#e9e9e9; border:1px #aaaaaa solid; padding:10px;"
                                    >
                                        <h3>Physical Address</h3>
                                        <v-row>
                                            <v-col cols="12" sm="4">
                                                <v-text-field
                                                    v-model="policy_details.premium_payer.physical_address.line1"
                                                    color="secondary"
                                                    label="Line 1"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4">
                                                <v-text-field
                                                    v-model="policy_details.premium_payer.physical_address.line2"
                                                    color="secondary"
                                                    label="Line 2"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="2">
                                                <v-text-field
                                                    v-model="policy_details.premium_payer.physical_address.line3"
                                                    color="secondary"
                                                    label="Line 3"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="2">
                                                <v-text-field
                                                    v-model="policy_details.premium_payer.physical_address.line4"
                                                    type="number"
                                                    color="secondary"
                                                    label="Postal Code"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-card-text>
                                <!-- Button Section -->
                                <v-card-actions>
                                    <v-btn
                                        fab
                                        color="amber"
                                        @click="e1 = 2"
                                    >
                                        <v-icon large>keyboard_arrow_left</v-icon>
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        fab
                                        color="amber"
                                        :disabled="goToStep4BtnDisabled()"
                                        @click="goToStep4()"
                                    >
                                        <v-icon large>keyboard_arrow_right</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>

                        <!-- Dependent details -->
                        <v-stepper-content step="2">
                            <v-card>
                                <v-card-text>
                                    <div
                                        v-for="(dependent, i) of policy_details.dependents"
                                        :key="i"
                                    >
                                        <DependentForm
                                            :dependent="dependent"
                                            :product="policy_details.product"
                                            :show_numbering="true"
                                            :payment_method="policy_details.payment_information.premium_payer.payment_method"
                                        />
                                        <br>
                                        <br>
                                    </div>
                                </v-card-text>
                                <!-- Button Section -->
                                <v-card-actions>
                                    <v-btn
                                        fab
                                        color="amber"
                                        @click="e1 = 1"
                                    >
                                        <v-icon large>keyboard_arrow_left</v-icon>
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        fab
                                        color="amber"
                                        :disabled="goToStep5BtnDisabled()"
                                        @click="goToStep5()"
                                    >
                                        <v-icon large>keyboard_arrow_right</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>

                        <!-- PREMIUM DETAILS -->
                        <v-stepper-content step="3">
                            <v-card>
                                <v-card-text v-if="submitting === false">
                                    <v-row style="background:#4490a030">
                                        <v-col cols="12" sm="12" class="text-center">
                                            <h2>Premium Details</h2>
                                        </v-col>
                                        <v-col
                                            v-if="quoting_premium === true"
                                            cols="12"
                                            sm="12"
                                            class="text-center"
                                        >
                                            <v-progress-circular
                                                color="secondary"
                                                indeterminate
                                                size="150"
                                                width="9"
                                            >
                                                <h4 class="text-center">Calculating Premium</h4>
                                            </v-progress-circular>
                                        </v-col>
                                        <v-col
                                            v-if="quoted_policy.product_name !== null"
                                            cols="12"
                                            sm="12"
                                            class="text-center"
                                        >
                                            <hr><br/>
                                            <h3><b>Product Name:</b> <span style="color:red;">{{ quoted_policy.select_product === null ? 'NO MATCHING PRODUCT FOUND' : quoted_policy.select_product.name }}</span></h3>
                                            <h3><b>Maximum Age:</b> <span style="color:red;">{{ quoted_policy.maximum_age }}</span></h3>
                                            <h3><b>Premium:</b> <span style="color:red;">R{{ quoted_policy.premium }}</span></h3>
                                            <h3><b>Cover Amount:</b> <span style="color:red;">R{{ quoted_policy.cover_amount }}</span></h3>
                                        </v-col>
                                    </v-row>
                                </v-card-text>

                                <v-card-text class="text-center" v-if="submitting === true">
                                    Submitting Policy Details
                                    <v-progress-linear
                                        indeterminate
                                        color="green"
                                        class="mb-0"
                                        height="5"
                                        :active="submitting"
                                    ></v-progress-linear>
                                </v-card-text>
                                <v-list flat v-if="submitting == true">
                                    <v-subheader>Saving Policy Info</v-subheader>
                                    <v-list-item-group color="primary">
                                        <v-list-item
                                            v-for="(item, i) in backendActionItems"
                                            :key="i"
                                        >
                                            <v-list-item-icon>
                                                <v-icon>done</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                                <!-- Button Section -->
                                <v-card-actions>
                                    <v-btn
                                        fab
                                        color="amber"
                                        @click="() => { e1 = 2 ; submitting = false;}"
                                    >
                                        <v-icon large>keyboard_arrow_left</v-icon>
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        large
                                        color="success"
                                        :disabled="submitBtnDisabled()"
                                        @click="savePolicy()"
                                    >
                                        <v-icon large>save</v-icon> Accept & Save
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>

                        <!-- Submission Results Section -->
                        <!-- <v-stepper-content step="6">
                            <v-card>
                                <v-card-text class="text-center" v-if="submitting">
                                    Submitting Policy Details
                                    <v-progress-linear
                                        indeterminate
                                        color="green"
                                        class="mb-0"
                                        height="5"
                                        :active="submitting"
                                    ></v-progress-linear>
                                </v-card-text>
                                <v-list flat v-if="submitting">
                                    <v-subheader>Saving Policy Info</v-subheader>
                                    <v-list-item-group color="primary">
                                        <v-list-item
                                            v-for="(item, i) in backendActionItems"
                                            :key="i"
                                        >
                                            <v-list-item-icon>
                                                <v-icon>done</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-card>
                        </v-stepper-content> -->
                    </v-stepper-items>
                </v-stepper>
            </div>
        </v-dialog>
        <AddEntity
            :dialog="capture_entity_dialog"
            :identifier="add_entity_identifier"
        />
        <ViewDocumentDialog
            v-if="policy_details.principal_member.id_doc.server_object !== null"
            :dialog="open_view_member_document_dialog"
            :document="policy_details.principal_member.id_doc.server_object"
            param_path="entity"
            :param_id="policy_details.principal_member.member._id"
        />
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../../utils/api";
import MemberSearch from "../components/member_search.vue";
import AgentSearch from "../components/agent_search.vue";
import DependentForm from "../components/DependentForm.vue";
import SchemeSearch from "../components/scheme_search";

import { idValid, extractBirthDate, extractGender } from "../../../../../utils/id_number_check";
import { calculateEntityAge } from "../../../../../utils/age";
import { eventBus } from "../../../../../main";
import {
    policy_roles,
    payment_methods,
    marital_statuses,
    bank_account_types,
} from "../../../../../../config";
// import Signature from '../components/signature.vue';
import { Buffer } from 'buffer';

import { getProducts, getBanks, getSchemes } from "../gql/queries";
import { businessProcessProspectPolicy, calculatePolicyQuotePricing } from "../gql/mutations";

import AddEntity from "../../../../people/dialogs/onboarding.vue";
import ViewDocumentDialog from '../../../../../components/common_dialogs/view_document_info';

export default {
    components: {
        MemberSearch,
        AgentSearch,
        DependentForm,
        // Signature,
        SchemeSearch,
        AddEntity,
        ViewDocumentDialog,
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        e1: 1,
        signaturePad: 0,

        capture_entity_dialog: false,
        add_entity_identifier: null,

        open_view_member_document_dialog: false,

        default_opened_banking_expansion_panel: 0,
        marital_statuses: marital_statuses,

        strokeOptions: {
            size: 16,
            thinning: 0.75,
            smoothing: 0.5,
            streamline: 0.5,
        },

        // File props
        file_url: null,
        doc_type: null,

        backendActionItems: [],

        cover_amounts_list: [
            {
                display: 'R2 000', // The reason for this mismatch is regarding the deal we have wit CASA
                amount: 3000
            },
            {
                display: 'R5 000',
                amount: 5000
            },
            {
                display: 'R10 000',
                amount: 10000
            },
            {
                display: 'R15 000',
                amount: 15000
            },
            {
                display: 'R20 000',
                amount: 20000
            },
        ],

        policy_details: {
            agent: null,
            product: null,
            scheme: null,
            status: 'DRAFT',
            elected_payment_date: '',
            selected_cover_amount: null,

            // -------------------------------------------------
            // --------------- POLICY PAYMENT INFO -------------
            // -------------------------------------------------
            payment_information: {
                premium_payer: {
                    payment_method: null,
                    percentage_amount: 100
                },
                scheme: {
                    payment_method: 'NOT APPLICABLE',
                    percentage_amount: 0
                },
            },

            // -------------------------------------------------
            // ---------------- MEMBERSHIP DETAILS -------------
            // -------------------------------------------------
            principal_member: {
                is_premium_payer: false,

                // Existing server entity
                member: null,

                // Member details
                name: '',
                surname: '',
                id_number: '',
                gender: '',
                id_number_valid: false,
                phone: '',
                email: '',
                cover_amount: 0,
                age: 0,

                settlement_banking: null,

                physical_address: {
                    line1: '',
                    line2: '',
                    line3: '',
                    line4: '',
                },
                id_doc: {
                    file: null,
                    server_object: null
                },
            },
            premium_payer: {
                member: null,

                name: '',
                surname: '',
                id_number: '',
                gender: '',
                id_number_valid: false,
                phone: '',
                email: '',

                collection_banking: null,

                physical_address: {
                    line1: '',
                    line2: '',
                    line3: '',
                    line4: '',
                },
            },
            dependents: [
                {
                    member: null,

                    id: 1,
                    name: '',
                    surname: '',
                    id_number: '',
                    gender: '',
                    id_number_valid: false,
                    is_principal: false,
                    is_premium_payer: false,
                    spouse_to_principal: false,
                    elected_dependent: false,

                    collection_banking: null,
                },
                {
                    member: null,

                    id: 2,
                    name: '',
                    surname: '',
                    id_number: '',
                    gender: '',
                    id_number_valid: false,
                    is_principal: false,
                    is_premium_payer: false,
                    spouse_to_principal: false,
                    elected_dependent: false,

                    collection_banking: null,
                },
                {
                    member: null,

                    id: 3,
                    name: '',
                    surname: '',
                    id_number: '',
                    gender: '',
                    id_number_valid: false,
                    is_principal: false,
                    is_premium_payer: false,
                    spouse_to_principal: false,
                    elected_dependent: false,

                    collection_banking: null,
                },
                {
                    member: null,

                    id: 4,
                    name: '',
                    surname: '',
                    id_number: '',
                    gender: '',
                    id_number_valid: false,
                    is_principal: false,
                    is_premium_payer: false,
                    spouse_to_principal: false,
                    elected_dependent: false,

                    collection_banking: null,
                },
                {
                    member: null,

                    id: 5,
                    name: '',
                    surname: '',
                    id_number: '',
                    gender: '',
                    id_number_valid: false,
                    is_principal: false,
                    is_premium_payer: false,
                    spouse_to_principal: false,
                    elected_dependent: false,

                    collection_banking: null,
                },
                {
                    member: null,

                    id: 6,
                    name: '',
                    surname: '',
                    id_number: '',
                    gender: '',
                    id_number_valid: false,
                    is_principal: false,
                    is_premium_payer: false,
                    spouse_to_principal: false,
                    elected_dependent: false,

                    collection_banking: null,
                },
                {
                    member: null,

                    id: 7,
                    name: '',
                    surname: '',
                    id_number: '',
                    gender: '',
                    id_number_valid: false,
                    is_principal: false,
                    is_premium_payer: false,
                    spouse_to_principal: false,
                    elected_dependent: false,

                    collection_banking: null,
                },
                {
                    member: null,

                    id: 8,
                    name: '',
                    surname: '',
                    id_number: '',
                    gender: '',
                    id_number_valid: false,
                    is_principal: false,
                    is_premium_payer: false,
                    spouse_to_principal: false,
                    elected_dependent: false,

                    collection_banking: null,
                },
                {
                    member: null,

                    id: 9,
                    name: '',
                    surname: '',
                    id_number: '',
                    gender: '',
                    id_number_valid: false,
                    is_principal: false,
                    is_premium_payer: false,
                    spouse_to_principal: false,
                    elected_dependent: false,

                    collection_banking: null,
                },
                {
                    member: null,

                    id: 10,
                    name: '',
                    surname: '',
                    id_number: '',
                    gender: '',
                    id_number_valid: false,
                    is_principal: false,
                    is_premium_payer: false,
                    spouse_to_principal: false,
                    elected_dependent: false,

                    collection_banking: null,
                },
            ],
        },

        quoted_policy: {
            maximum_age: 0,
            cover_amount: 0,
            premium: 0,
            select_product: null
        },
        
        products: [],
        banks_list: [],
        schemes: [],
        bank_account_types: bank_account_types,
        policy_roles: policy_roles,

        status: 'DRAFT',
        server_response_obj: null,
        submitting: false,
        quoting_premium: false,

        payment_methods: payment_methods,


        refreshing_product_lookup_list: false,
        refreshing_scheme_lookup_list: false,
        refreshing_banks_lookup_list: false,

        elected_payment_date_rules: [
            v => !!v || 'Elected payment date is required',
            v => (parseFloat(v) > 0 && parseFloat(v) < 32) || 'Must be between 1 and 31'
        ],
    }),
    computed: {
        derived_scheme_level_payment() {
            return parseFloat(this.policy_details.payment_information.scheme.percentage_amount) > 0;
        },
        paymentOptionAmount() {
            return parseFloat(this.policy_details.payment_information.premium_payer.percentage_amount);
        },
        selected_product_premium() {
            if (this.policy_details.product !== null) {
                const primary_product = this.policy_details.product.primary_product;
                const underwriter_price = primary_product.underwriter_premium + primary_product.broker_markup;

                const secondary_product = this.policy_details.product;

                const reseller_markup_percentage = underwriter_price * (secondary_product.markup_percentage/100);
                const reseller_markup_fixed = secondary_product.markup_fixed;
                
                const reseller_price = underwriter_price + reseller_markup_percentage + reseller_markup_fixed;
                return reseller_price;
            }

            return 0;
        }
    },
    methods: {
        closeAddPolicyDialog() {
            eventBus.$emit('CLOSE_ADD_POLICY_DIALOG', this.server_response_obj);
        },

        openCaptureEntityDialog(identifier) {
            this.add_entity_identifier = identifier;
            this.capture_entity_dialog = true;
        },
        closeCaptureEntityDialog(newEntity, identifier) {
            this.add_entity_identifier = null;
            this.capture_entity_dialog = false;

            if (newEntity !== null) {
                switch (identifier) {
                    case 'principal_member':
                        this.policy_details.principal_member.member = newEntity;
                        break;
                    case 'premium_payer':
                        this.policy_details.premium_payer.member = newEntity;
                        break;
                }
            }
        },
        closeCaptureEntityDialogDepForm(newEntity, identifier) {
            if (newEntity !== null) {
                switch (identifier) {
                    case 'dependent_1':
                        this.policy_details.dependents[0].member = newEntity;
                        break;
                    case 'dependent_2':
                        this.policy_details.dependents[1].member = newEntity;
                        break;
                    case 'dependent_3':
                        this.policy_details.dependents[2].member = newEntity;
                        break;
                    case 'dependent_4':
                        this.policy_details.dependents[3].member = newEntity;
                        break;
                    case 'dependent_5':
                        this.policy_details.dependents[4].member = newEntity;
                        break;
                    case 'dependent_6':
                        this.policy_details.dependents[5].member = newEntity;
                        break;
                    case 'dependent_7':
                        this.policy_details.dependents[6].member = newEntity;
                        break;
                    case 'dependent_8':
                        this.policy_details.dependents[7].member = newEntity;
                        break;
                    case 'dependent_9':
                        this.policy_details.dependents[8].member = newEntity;
                        break;
                }
            }
        },

        openViewDocumentDialog() {
            this.open_view_member_document_dialog = true;
        },
        closeViewServerDocumentsDialog() {
            this.open_view_member_document_dialog = false;
        },

        goToStep2BtnDisabled() {
            if (
                // this.policy_details.product === null ||
                this.policy_details.selected_cover_amount === null ||
                this.policy_details.payment_information.premium_payer.payment_method === null ||
                (
                    isNaN(parseFloat(this.policy_details.elected_payment_date)) ||
                    parseFloat(this.policy_details.elected_payment_date) < 1 ||
                    parseFloat(this.policy_details.elected_payment_date) > 31
                )
            ) {
                return true;
            }
            if (this.policy_details.agent === null) {
                return true;
            }
            return false;
        },
        
        goToStep2() {
            this.e1 = 2;
        },

        goToStep3BtnDisabled() {
            // if (this.policy_details.principal_member.settlement_banking === null) {
            //     return true;
            // }
            if (this.policy_details.principal_member.id_doc.server_object === null) {
                return true;
            }
            return false;
        },
        goToStep3() {
            this.e1 = 3;
        },

        goToStep4BtnDisabled() {
            if (this.policy_details.payment_information.premium_payer.payment_method === 'DEBIT ORDER') {
                if (this.policy_details.premium_payer.collection_banking === null) {
                    return true;
                }
            }
            return false;
        },
        goToStep4() {
            this.e1 = 2;
        },

        goToStep5BtnDisabled() {
            const validPrincipal = this.policy_details.dependents
                                                        .filter(item => {
                                                            return (
                                                                item.is_principal === true &&
                                                                item.member !== null
                                                            );
                                                        });
            if (validPrincipal.length !== 1) {
                return true;
            }
            const validPremiumPayer = this.policy_details.dependents
                                                        .filter(item => {
                                                            return (
                                                                item.is_premium_payer === true &&
                                                                item.member !== null
                                                            );
                                                        });
            if (validPremiumPayer.length !== 1) {
                return true;
            }
            const validElectedDependent = this.policy_details.dependents
                                                        .filter(item => {
                                                            return (
                                                                item.elected_dependent === true &&
                                                                item.member !== null
                                                            );
                                                        });
            if (validElectedDependent.length !== 1) {
                return true;
            }
            
            return false;
        },
        getMaxAge() {
            const filledInDependents = this.policy_details.dependents.filter(item => { return item.member !== null; });
            const memberAges = filledInDependents.map(dependent => {
                let returnValue = null;
                if (typeof(dependent.id_number) === 'string') {
                    if (dependent.id_number.length >= 6) {
                        const birthDateObj = extractBirthDate(dependent.id_number);
                        const memberAge = calculateEntityAge(birthDateObj.century_string + birthDateObj.partial_date_string);
                        returnValue = memberAge;
                    }
                }
                return returnValue;
            });

            const principalMemberAge = this.policy_details.principal_member.age;
            memberAges.push(principalMemberAge);

            const maxAge = Math.max(...memberAges);
            return maxAge;
        },
        async goToStep5() {
            // this.e1 = 5;

            this.quoting_premium = true;
            this.e1 = 3;
            
            // Prepare payload
            // Dependents
            const electedDependent = this.policy_details.dependents
                                                            .filter(dep => {
                                                                return (
                                                                    dep.member !== null &&
                                                                    dep.elected_dependent === true
                                                                )
                                                            });

            const validDependents = this.policy_details.dependents
                                                            .filter(dep => {
                                                                return (
                                                                    dep.member !== null &&
                                                                    dep.is_principal === false &&
                                                                    dep.is_premium_payer === false &&
                                                                    dep.elected_dependent === false
                                                                )
                                                            });
            const payloadObj = {
                agent_id: this.policy_details.agent._id,
                max_age: this.getMaxAge(),
                cover_amount: this.policy_details.selected_cover_amount.amount,
                dependent_count: electedDependent.length + validDependents.length,
            };

            // Submit quote request
            try {
                const res = await this.$apollo
                                            .mutate({
                                                mutation: calculatePolicyQuotePricing,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                
                // Show Results Dialog
                const serverResponse = res.data.calculatePolicyQuotePricing;
                this.quoted_policy.maximum_age = serverResponse.maximum_age;
                this.quoted_policy.cover_amount = serverResponse.cover_amount;
                this.quoted_policy.premium = serverResponse.premium;
                this.quoted_policy.select_product = serverResponse.selected_product;

                this.quoting_premium = false;

                // emitSuccess(res.data.calculatePolicyQuotePricing.message);
                // this.closeAddPolicyDialog();

                // // Reset form
                // this.resetForm();
            } catch (error) {
                this.quoting_premium = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.calculatePolicyQuotePricing.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        submitBtnDisabled() {
            if (this.quoted_policy.select_product === null) {
                return true;
            }
            return false;
        },

        resetForm() {
            this.server_response_obj = null;

            this.e1 = 1;
            this.submitting = false;
            this.backendActionItems = [];

            this.quoted_policy = {
                maximum_age: 0,
                cover_amount: 0,
                premium: 0,
                select_product: null
            };

            this.policy_details = {
                agent: null,
                product: null,
                scheme: null,
                status: 'DRAFT',
                elected_payment_date: '',
                selected_cover_amount: null,

                // -------------------------------------------------
                // --------------- POLICY PAYMENT INFO -------------
                // -------------------------------------------------
                payment_information: {
                    premium_payer: {
                        payment_method: null,
                        percentage_amount: 100
                    },
                    scheme: {
                        payment_method: 'NOT APPLICABLE',
                        percentage_amount: 0
                    },
                },

                // -------------------------------------------------
                // ---------------- MEMBERSHIP DETAILS -------------
                // -------------------------------------------------
                principal_member: {
                    is_premium_payer: false,

                    // Existing server entity
                    member: null,

                    // Member details
                    name: '',
                    surname: '',
                    id_number: '',
                    gender: '',
                    id_number_valid: false,
                    phone: '',
                    email: '',
                    cover_amount: 0,
                    age: 0,

                    settlement_banking: null,

                    physical_address: {
                        line1: '',
                        line2: '',
                        line3: '',
                        line4: '',
                    },
                    id_doc: {
                        file: null,
                        server_object: null
                    },
                },
                premium_payer: {
                    member: null,

                    name: '',
                    surname: '',
                    id_number: '',
                    gender: '',
                    id_number_valid: false,
                    phone: '',
                    email: '',

                    collection_banking: null,

                    physical_address: {
                        line1: '',
                        line2: '',
                        line3: '',
                        line4: '',
                    },
                },
                dependents: [
                    {
                        member: null,

                        id: 1,
                        name: '',
                        surname: '',
                        id_number: '',
                        gender: '',
                        id_number_valid: false,
                        is_principal: false,
                        is_premium_payer: false,
                        spouse_to_principal: false,
                        elected_dependent: false,

                        collection_banking: null,
                    },
                    {
                        member: null,

                        id: 2,
                        name: '',
                        surname: '',
                        id_number: '',
                        gender: '',
                        id_number_valid: false,
                        is_principal: false,
                        is_premium_payer: false,
                        spouse_to_principal: false,
                        elected_dependent: false,

                        collection_banking: null,
                    },
                    {
                        member: null,

                        id: 3,
                        name: '',
                        surname: '',
                        id_number: '',
                        gender: '',
                        id_number_valid: false,
                        is_principal: false,
                        is_premium_payer: false,
                        spouse_to_principal: false,
                        elected_dependent: false,

                        collection_banking: null,
                    },
                    {
                        member: null,

                        id: 4,
                        name: '',
                        surname: '',
                        id_number: '',
                        gender: '',
                        id_number_valid: false,
                        is_principal: false,
                        is_premium_payer: false,
                        spouse_to_principal: false,
                        elected_dependent: false,

                        collection_banking: null,
                    },
                    {
                        member: null,

                        id: 5,
                        name: '',
                        surname: '',
                        id_number: '',
                        gender: '',
                        id_number_valid: false,
                        is_principal: false,
                        is_premium_payer: false,
                        spouse_to_principal: false,
                        elected_dependent: false,

                        collection_banking: null,
                    },
                    {
                        member: null,

                        id: 6,
                        name: '',
                        surname: '',
                        id_number: '',
                        gender: '',
                        id_number_valid: false,
                        is_principal: false,
                        is_premium_payer: false,
                        spouse_to_principal: false,
                        elected_dependent: false,

                        collection_banking: null,
                    },
                    {
                        member: null,

                        id: 7,
                        name: '',
                        surname: '',
                        id_number: '',
                        gender: '',
                        id_number_valid: false,
                        is_principal: false,
                        is_premium_payer: false,
                        spouse_to_principal: false,
                        elected_dependent: false,

                        collection_banking: null,
                    },
                    {
                        member: null,

                        id: 8,
                        name: '',
                        surname: '',
                        id_number: '',
                        gender: '',
                        id_number_valid: false,
                        is_principal: false,
                        is_premium_payer: false,
                        spouse_to_principal: false,
                        elected_dependent: false,

                        collection_banking: null,
                    },
                    {
                        member: null,

                        id: 9,
                        name: '',
                        surname: '',
                        id_number: '',
                        gender: '',
                        id_number_valid: false,
                        is_principal: false,
                        is_premium_payer: false,
                        spouse_to_principal: false,
                        elected_dependent: false,

                        collection_banking: null,
                    },
                    {
                        member: null,

                        id: 10,
                        name: '',
                        surname: '',
                        id_number: '',
                        gender: '',
                        id_number_valid: false,
                        is_principal: false,
                        is_premium_payer: false,
                        spouse_to_principal: false,
                        elected_dependent: false,

                        collection_banking: null,
                    },
                ],
            };

            // Reset field validations
            if (this.$refs.elected_payment_date_field) this.$refs.elected_payment_date_field.resetValidation();

            // if (this.$refs.principal_member_name) this.$refs.principal_member_name.resetValidation();
            // if (this.$refs.principal_member_surname) this.$refs.principal_member_surname.resetValidation();
            // if (this.$refs.principal_member_email) this.$refs.principal_member_email.resetValidation();
            // if (this.$refs.principal_member_phone) this.$refs.principal_member_phone.resetValidation();
            // if (this.$refs.principal_member_id_number) this.$refs.principal_member_id_number.resetValidation();

            // if (this.$refs.premium_payer_name) this.$refs.premium_payer_name.resetValidation();
            // if (this.$refs.premium_payer_surname) this.$refs.premium_payer_surname.resetValidation();
            // if (this.$refs.premium_payer_email) this.$refs.premium_payer_email.resetValidation();
            // if (this.$refs.premium_payer_phone) this.$refs.premium_payer_phone.resetValidation();
            // if (this.$refs.premium_payer_id_number) this.$refs.premium_payer_id_number.resetValidation();

            // DEPENDENTS
            eventBus.$emit('RESET_DEPENDENT_FORM_FIELDS');
        },

        async savePolicy() {
            this.submitting = true;
            this.e1 = 3;
            
            // Prepare payload
            const payloadObjOriginal = this.extractPolicyPayload();
            // if (payloadObjOriginal !== null) {
            //     console.log(payloadObjOriginal)
            //     return
            // }
            
            // const payloadObj = JSON.parse( JSON.stringify(payloadObjOriginal) );
            // delete payloadObj.principal_member.id_doc.file;

            // Then submit the policy
            try {
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessProspectPolicy,
                                                variables: {
                                                    input: {
                                                        OP_TYPE: 'CREATE',
                                                        ...payloadObjOriginal
                                                    }
                                                },
                                            });
                if (res.data.businessProcessProspectPolicy.errors === true) {
                    emitAlert(res.data.businessProcessProspectPolicy.message);
                    this.submitting = false;
                    return;
                }

                this.server_response_obj = res.data.businessProcessProspectPolicy.business_object;

                emitSuccess(res.data.businessProcessProspectPolicy.message);
                this.closeAddPolicyDialog();

                // Reset form
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessProspectPolicy.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        extractPolicyPayload() {
            const premiumPayerObj = this.policy_details.dependents.filter(item => {
                                                                return item.is_premium_payer === true;
                                                            })[0];
            const principalMemberObj = this.policy_details.dependents.filter(item => {
                                                                return item.is_principal === true;
                                                            })[0];
            // Prepare payload
            const payloadObj = {
                // Top level info
                agent: this.policy_details.agent !== null ? this.policy_details.agent._id : null,
                scheme: this.policy_details.scheme,
                // product: this.policy_details.product === null ? null : this.policy_details.product._id,
                product: this.quoted_policy.select_product._id,
                status: this.policy_details.status,
                
                // Payment info
                payment_date: parseFloat(this.policy_details.elected_payment_date),
                scheme_level_payment: (parseFloat(this.policy_details.payment_information.scheme.percentage_amount) > 0),
                payment_information: {
                    premium_payer: {
                        payment_method: null,
                        percentage_amount: 0
                    },
                    scheme: {
                        payment_method: null,
                        percentage_amount: 0
                    },
                },

                // Membership info
                principal_member: {
                    // is_premium_payer: this.policy_details.principal_member.is_premium_payer,
                    // entity: this.policy_details.principal_member.member._id,
                    entity: principalMemberObj.member._id,
                    // settlement_banking: this.policy_details.principal_member.settlement_banking
                    settlement_banking: null
                },

                premium_payer: {
                    // entity: this.policy_details.premium_payer.member._id,
                    entity: premiumPayerObj.member._id,
                    // collection_banking: this.policy_details.premium_payer.collection_banking
                    collection_banking: premiumPayerObj.collection_banking
                },

                dependents: [],
            };

            // Dependents
            const electedDependent = this.policy_details.dependents
                                                            .filter(dep => {
                                                                return (
                                                                    dep.member !== null &&
                                                                    dep.elected_dependent === true
                                                                )
                                                            })
                                                            .map(dep => {
                                                                return {
                                                                    entity: dep.member._id,
                                                                    spouse_to_principal: dep.spouse_to_principal,
                                                                    elected_dependent: dep.elected_dependent,
                                                                }
                                                            });

            const validDependents = this.policy_details.dependents
                                                            .filter(dep => {
                                                                return (
                                                                    dep.member !== null &&
                                                                    dep.is_principal === false &&
                                                                    dep.is_premium_payer === false &&
                                                                    dep.elected_dependent === false
                                                                )
                                                            })
                                                            .map(dep => {
                                                                return {
                                                                    entity: dep.member._id,
                                                                    spouse_to_principal: dep.spouse_to_principal,
                                                                    elected_dependent: dep.elected_dependent,
                                                                }
                                                            });

            if (electedDependent.length > 0) {
                for (const dep of electedDependent) {
                    validDependents.push(dep);
                }
            }
            payloadObj.dependents = validDependents;

            // Set payment information
            payloadObj.payment_information.premium_payer.payment_method = this.policy_details.payment_information.premium_payer.payment_method;
            payloadObj.payment_information.premium_payer.percentage_amount = parseFloat(this.policy_details.payment_information.premium_payer.percentage_amount);
            
            payloadObj.payment_information.scheme.payment_method = this.policy_details.payment_information.scheme.payment_method;
            payloadObj.payment_information.scheme.percentage_amount = parseFloat(this.policy_details.payment_information.scheme.percentage_amount);

            if (payloadObj.payment_information.premium_payer.payment_method === null) {
                payloadObj.payment_information.premium_payer.payment_method = 'NOT APPLICABLE';
            }

            if (payloadObj.payment_information.scheme.payment_method === null) {
                payloadObj.payment_information.scheme.payment_method = 'NOT APPLICABLE';
            }
            const sanitizedPayload = JSON.parse(JSON.stringify(payloadObj));

            return sanitizedPayload;
        },

        async refreshProductLookupList() {
            this.refreshing_product_lookup_list = true;

            try {
                // Load Products
                const res = await this.$apollo
                                    .query({
                                        query: getProducts,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'INSURANCE_PRODUCT_SECONDARY',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                this.refreshing_product_lookup_list = false;

                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
                
                const filteredList = res.data.getBusinessObject.business_objects.filter(item => { return item.primary_product.active === true });
                this.products = filteredList;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
        async refreshSchemesLookupList() {
            this.refreshing_scheme_lookup_list = true;

            try {
                // Load Schemes
                const res = await this.$apollo
                                    .query({
                                        query: getSchemes,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'SCHEME',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                this.refreshing_scheme_lookup_list = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
                this.schemes = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
        async refreshBanksLookupList() {
            this.refreshing_banks_lookup_list = true;

            try {
                // Load Banks
                const res = await this.$apollo
                                    .query({
                                        query: getBanks,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BANK',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                this.refreshing_banks_lookup_list = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
                
                this.banks_list = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

    },
    async mounted() {
        eventBus.$on('CLOSE_ONBOARDING_DIALOG', this.closeCaptureEntityDialog);
        eventBus.$on('CLOSE_ONBOARDING_DIALOG_FROM_DEPENDENT_FROM', this.closeCaptureEntityDialogDepForm);
        eventBus.$on('CLOSE_VIEW_DOCUMENT_DIALOG', this.closeViewServerDocumentsDialog);

        eventBus.$on('SIGNATURE_CHANGED', (signatureData) => {
            const signature_purpose = signatureData.signature_purpose;
            const data_url = signatureData.data_url;

            if (data_url !== null) {
                // Convert base64 data url to binary
                const array = []
                const blobBin = Buffer.from(data_url.split(',')[1], 'base64').toString('binary')
                for (let i = 0; i < blobBin.length; i++) {
                    array.push(blobBin.charCodeAt(i))
                }
                // const file = new Blob([new Uint8Array(array)], {type: 'image/png'});

                // Set signature
                if (signature_purpose === 'PRINCIPAL_MEMBER') {
                    // this.policy_details.principal_member.digital_signature = file;

                    // if (this.policy_details.principal_member.is_premium_payer === true) {
                    //     this.policy_details.premium_payer.digital_signature = file;
                    // }
                }

                if (signature_purpose === 'PREMIUM_PAYER') {
                    // this.policy_details.principal_member.digital_signature = file;
                }
            } else {
                if (signature_purpose === 'PRINCIPAL_MEMBER') {
                    // this.policy_details.principal_member.digital_signature = null;

                    // if (this.policy_details.principal_member.is_premium_payer === true) {
                    //     this.policy_details.premium_payer.digital_signature = null;
                    // }
                }

                if (signature_purpose === 'PREMIUM_PAYER') {
                    // this.policy_details.principal_member.digital_signature = null;
                }
            }
        })

        // Load lookup data
        this.submitting = true;

        try {
            // Load Products
            let res = await this.$apollo
                                    .query({
                                        query: getProducts,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'INSURANCE_PRODUCT_SECONDARY',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            // this.products = res.data.getBusinessObject.business_objects;
            const filteredList = res.data.getBusinessObject.business_objects.filter(item => { return item.primary_product.active === true });
            this.products = filteredList;

            // Load Schemes
            res = await this.$apollo
                                    .query({
                                        query: getSchemes,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'SCHEME',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            this.schemes = res.data.getBusinessObject.business_objects;

            // Load banks
            res = await this.$apollo
                                    .query({
                                        query: getBanks,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BANK',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            this.banks_list = res.data.getBusinessObject.business_objects;

            this.submitting = false;
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },
    watch: {
        'policy_details.principal_member': {
            handler (principalMemberObj) {
                this.policy_details.premium_payer.member = null;
                this.policy_details.premium_payer.collection_banking = null;

                if (principalMemberObj.is_premium_payer === true) {
                    this.policy_details.premium_payer.name = principalMemberObj.name;
                    this.policy_details.premium_payer.surname = principalMemberObj.surname;
                    this.policy_details.premium_payer.phone = principalMemberObj.phone;
                    this.policy_details.premium_payer.email = principalMemberObj.email;
                    this.policy_details.premium_payer.id_number = principalMemberObj.id_number;

                    this.policy_details.premium_payer.physical_address.line1 = principalMemberObj.physical_address.line1;
                    this.policy_details.premium_payer.physical_address.line2 = principalMemberObj.physical_address.line2;
                    this.policy_details.premium_payer.physical_address.line3 = principalMemberObj.physical_address.line3;
                    this.policy_details.premium_payer.physical_address.line4 = principalMemberObj.physical_address.line4;

                    this.policy_details.premium_payer.member = this.policy_details.principal_member.member;
                } else {
                    this.policy_details.premium_payer.name = '';
                    this.policy_details.premium_payer.surname = '';
                    this.policy_details.premium_payer.phone = '';
                    this.policy_details.premium_payer.email = '';
                    this.policy_details.premium_payer.id_number = '';

                    this.policy_details.premium_payer.physical_address.line1 = '';
                    this.policy_details.premium_payer.physical_address.line2 = '';
                    this.policy_details.premium_payer.physical_address.line3 = '';
                    this.policy_details.premium_payer.physical_address.line4 = '';

                    // Resest premium payer filed validations
                    // if (this.$refs.premium_payer_name) this.$refs.premium_payer_name.resetValidation();
                    // if (this.$refs.premium_payer_surname) this.$refs.premium_payer_surname.resetValidation();
                    // if (this.$refs.premium_payer_email) this.$refs.premium_payer_email.resetValidation();
                    // if (this.$refs.premium_payer_phone) this.$refs.premium_payer_phone.resetValidation();
                    // if (this.$refs.premium_payer_id_number) this.$refs.premium_payer_id_number.resetValidation();
                }
            },
            deep: true
        },
        'policy_details.principal_member.member': {
            handler(serverEntity) {
                if (serverEntity !== null) {
                    // ---------------------------------------------------
                    // ---------------- BASIC DETAILS --------------------
                    // ---------------------------------------------------
                    this.policy_details.principal_member.name = serverEntity.name;
                    this.policy_details.principal_member.surname = serverEntity.surname;


                    // ---------------------------------------------------
                    // ---------------- CONTACT DETAILS ------------------
                    // ---------------------------------------------------
                    const serverEntityPhone = serverEntity.contact_details.filter(cont => {
                        return (
                            cont.type === 'Mobile' &&
                            cont.deleted === false &&
                            cont.primary === true
                        );
                    })[0];
                    const serverEntityEmail = serverEntity.contact_details.filter(cont => {
                        return (
                            cont.type === 'Email' &&
                            cont.deleted === false
                        );
                    })[0];

                    this.policy_details.principal_member.phone = serverEntityPhone === undefined ? '' : serverEntityPhone.details.replace(/\s/g, ''); // remove all spaces
                    this.policy_details.principal_member.email = serverEntityEmail === undefined ? '' : serverEntityEmail.details;
                    
                    this.policy_details.principal_member.id_number = serverEntity.id_number;

                    // ---------------------------------------------------
                    // -------------------- ADDRESS ----------------------
                    // ---------------------------------------------------
                    const serverEntityAddress = serverEntity.addresses.filter(addr => {
                        return (
                            (
                                addr.type === 'Residential' ||
                                addr.type === 'Postal + Residential'
                            ) &&
                            addr.deleted === false
                        );
                    })[0];

                    if (serverEntityAddress !== undefined) {
                        this.policy_details.principal_member.physical_address.line1 = serverEntityAddress.line1;
                        this.policy_details.principal_member.physical_address.line2 = serverEntityAddress.line2;
                        this.policy_details.principal_member.physical_address.line3 = serverEntityAddress.line3;
                        this.policy_details.principal_member.physical_address.line4 = serverEntityAddress.line4;
                    }

                    // ---------------------------------------------------
                    // -------------------- ID DOC -----------------------
                    // ---------------------------------------------------
                    const serverEntityIDDoc = serverEntity.documents.filter(doc => {
                        return (
                            doc.type === 'ID Document' &&
                            doc.deleted === false
                        );
                    })[0];

                    this.policy_details.principal_member.id_doc.server_object = serverEntityIDDoc === undefined ? null : serverEntityIDDoc;
                } else {
                    this.policy_details.principal_member.name = '';
                    this.policy_details.principal_member.surname = '';

                    this.policy_details.principal_member.phone = '';
                    this.policy_details.principal_member.email = '';
                    this.policy_details.principal_member.id_number = '';

                    this.policy_details.principal_member.physical_address.line1 = '';
                    this.policy_details.principal_member.physical_address.line2 = '';
                    this.policy_details.principal_member.physical_address.line3 = '';
                    this.policy_details.principal_member.physical_address.line4 = '';

                    this.policy_details.principal_member.id_doc.server_object = null;
                }
            },
            deep: true
        },
        'policy_details.premium_payer.member': {
            handler(serverEntity) {
                if (serverEntity !== null) {
                    // ---------------------------------------------------
                    // ---------------- BASIC DETAILS --------------------
                    // ---------------------------------------------------
                    this.policy_details.premium_payer.name = serverEntity.name;
                    this.policy_details.premium_payer.surname = serverEntity.surname;


                    // ---------------------------------------------------
                    // ---------------- CONTACT DETAILS ------------------
                    // ---------------------------------------------------
                    const serverEntityPhone = serverEntity.contact_details.filter(cont => {
                        return (
                            cont.type === 'Mobile' &&
                            cont.deleted === false &&
                            cont.primary === true
                        );
                    })[0];
                    const serverEntityEmail = serverEntity.contact_details.filter(cont => {
                        return (
                            cont.type === 'Email' &&
                            cont.deleted === false
                        );
                    })[0];

                    this.policy_details.premium_payer.phone = serverEntityPhone === undefined ? '' : serverEntityPhone.details.replace(/\s/g, ''); // remove all spaces;
                    this.policy_details.premium_payer.email = serverEntityEmail === undefined ? '' : serverEntityEmail.details;
                    
                    this.policy_details.premium_payer.id_number = serverEntity.id_number;

                    // ---------------------------------------------------
                    // -------------------- ADDRESS ----------------------
                    // ---------------------------------------------------
                    const serverEntityAddress = serverEntity.addresses.filter(addr => {
                        return (
                            (
                                addr.type === 'Residential' ||
                                addr.type === 'Postal + Residential'
                            ) &&
                            addr.deleted === false
                        );
                    })[0];

                    if (serverEntityAddress !== undefined) {
                        this.policy_details.premium_payer.physical_address.line1 = serverEntityAddress.line1;
                        this.policy_details.premium_payer.physical_address.line2 = serverEntityAddress.line2;
                        this.policy_details.premium_payer.physical_address.line3 = serverEntityAddress.line3;
                        this.policy_details.premium_payer.physical_address.line4 = serverEntityAddress.line4;
                    }
                } else {
                    this.policy_details.premium_payer.name = '';
                    this.policy_details.premium_payer.surname = '';

                    this.policy_details.premium_payer.phone = '';
                    this.policy_details.premium_payer.email = '';
                    this.policy_details.premium_payer.id_number = '';

                    this.policy_details.premium_payer.physical_address.line1 = '';
                    this.policy_details.premium_payer.physical_address.line2 = '';
                    this.policy_details.premium_payer.physical_address.line3 = '';
                    this.policy_details.premium_payer.physical_address.line4 = '';
                }
            },
            deep: true
        },
        
        'policy_details.principal_member.id_number': {
            handler (val) {
                this.policy_details.principal_member.id_number_valid = false;

                // ---------------------------------------------------------
                // ---------------------- CHECK IF VALID -------------------
                // ---------------------------------------------------------
                if (val.length === 13) {
                    const valid = idValid(val);

                    if (valid) {
                        this.policy_details.principal_member.id_number_valid = true;
                    }
                    
                    this.policy_details.principal_member.gender = extractGender(val);

                    // calculate age and payout (if the id number was pasted without progressive typing)
                    const birthDateObj = extractBirthDate(val);
                    const memberAge = calculateEntityAge(birthDateObj.century_string + birthDateObj.partial_date_string);
                    this.policy_details.principal_member.age = memberAge;

                    if (this.policy_details.product !== null) {
                        const benefitPayout = this.policy_details.product.primary_product.payout_structure.filter(payoutObj => {
                            return (
                                payoutObj.category.membership_category === 'Principal Member' &&
                                memberAge >= payoutObj.category.start_age &&
                                memberAge <= payoutObj.category.end_age
                            );
                        });

                        if (benefitPayout[0])
                            this.policy_details.principal_member.cover_amount = benefitPayout[0].payout_amount;
                    }
                }

                // ---------------------------------------------------------
                // ----------------- CALCULATE AGE AND PAYOUT --------------
                // ---------------------------------------------------------
                if (val.length === 6) {
                    const birthDateObj = extractBirthDate(val);
                    const memberAge = calculateEntityAge(birthDateObj.century_string + birthDateObj.partial_date_string);
                    this.policy_details.principal_member.age = memberAge;

                    if (this.policy_details.product !== null) {
                        const benefitPayout = this.policy_details.product.primary_product.payout_structure.filter(payoutObj => {
                            return (
                                payoutObj.category.membership_category === 'Principal Member' &&
                                memberAge >= payoutObj.category.start_age &&
                                memberAge <= payoutObj.category.end_age
                            );
                        });

                        if (benefitPayout[0])
                            this.policy_details.principal_member.cover_amount = benefitPayout[0].payout_amount;
                    }
                }
                if (val.length < 6) {
                    this.policy_details.principal_member.age = null;
                    this.policy_details.principal_member.cover_amount = 0;
                }

                // ---------------------------------------------------------
                // ---------------------- EXTRACT GENDER -------------------
                // ---------------------------------------------------------
                if (val.length === 10) {
                    this.policy_details.principal_member.gender = extractGender(val);
                }
                if (val.length < 10) {
                    this.policy_details.principal_member.gender = null;
                }
            },
            deep: true
        },
        'policy_details.premium_payer.id_number': {
            handler (val) {
                this.policy_details.premium_payer.id_number_valid = false;

                // Check if valid
                if (val.length === 13) {
                    const valid = idValid(val);

                    if (valid) {
                        this.policy_details.premium_payer.id_number_valid = true;
                    }
                    
                    this.policy_details.premium_payer.gender = extractGender(val);
                }

                // extract gender
                if (val.length === 10) {
                    this.policy_details.premium_payer.gender = extractGender(val);
                }

                if (val.length < 10) {
                    this.policy_details.premium_payer.gender = null;
                }
            },
            deep: true
        },
        'policy_details.payment_information.premium_payer.percentage_amount' (val) {
            if (parseFloat(val) > 100) this.policy_details.payment_information.premium_payer.percentage_amount = '100';
        },

        paymentOptionAmount(amount) {
            if (parseFloat(amount) >= 0) {
                this.policy_details.payment_information.scheme.percentage_amount = 100 - parseFloat(amount);

                if (this.policy_details.payment_information.scheme.percentage_amount === 0) {
                    this.policy_details.payment_information.scheme.payment_method = 'NOT APPLICABLE';
                }

                return;
            }

            this.policy_details.payment_information.scheme.percentage_amount = 0;
            this.policy_details.payment_information.premium_payer.payment_method = 'NOT APPLICABLE';
            this.policy_details.payment_information.scheme.payment_method = null;
        },
    }
}
</script>