<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="2" style="margin-top:10px">
                    <v-tooltip bottom v-if="SEARCH_MODE === false">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                @click="loadMoreData()"
                                v-on="on"
                            >
                                <v-icon>cloud_download</v-icon>
                            </v-btn>
                        </template>
                        <span>Load More Data</span>
                    </v-tooltip>
                    <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="openSearchAgentsDialog()"
                            >
                                <v-icon>search</v-icon>
                            </v-btn>
                        </template>
                        <span>Search for agent(s)</span>
                    </v-tooltip> -->

                    <v-tooltip bottom v-if="SEARCH_MODE === false">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshAgents()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>

                    <v-tooltip v-if="selectedAgent !== null">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="openEditAgentDetailsDialog()"
                            >
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </template>
                        <span>Edit Record</span>
                    </v-tooltip>
                </v-col>

                <!-- Search Result Indicator -->
                <v-col sm="4">
                    <v-card-actions v-if="SEARCH_MODE === true">
                        <h3>Search Results</h3>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="clearSearchResults()"
                                    v-on="on"
                                >
                                    <v-icon small>search</v-icon>
                                    <v-icon small>close</v-icon>
                                </v-btn>
                            </template>
                            <span>Clear Search Results</span>
                        </v-tooltip>
                    </v-card-actions>
                </v-col>

                <!-- Action Buttons -->
                <v-col sm="6" style="margin-top:10px">
                    <!-- <v-spacer></v-spacer> -->
                    <div class="text-right">
                        <!-- Onboard  -->
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openOnboardingDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>person_add</v-icon>
                                </v-btn>
                            </template>
                            <span>Onboard a Person</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="selectedAgent !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="purple white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openAgentBranchAssignmentDialog()"
                                >
                                    <v-icon medium>mdi-source-branch</v-icon> Update Agent Branch
                                </v-btn>
                            </template>
                            <span>Update Agent Branch</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="selectedAgent !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="black white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openAgenCommissionsReportDialog('agent')"
                                >
                                    <v-icon medium>mdi-book</v-icon> Get Commissions Report
                                </v-btn>
                            </template>
                            <span>Get Commissions Report</span>
                        </v-tooltip>

                        <v-tooltip
                            v-if="(
                                isCentralUnderwriterAdminUser
                            )"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openAgenCommissionsReportDialog('global')"
                                >
                                    <v-icon medium>mdi-book</v-icon> Get Global Commissions Report
                                </v-btn>
                            </template>
                            <span>Get Global Commissions Report</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%" @paneResize="paneResizing">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="agent_details_headers"
                    :items="agents"
                    item-key="_id"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :loading="data_table_loading"
                    :disable-pagination="true"
                >
                    <!-- Display create date -->
                    <template v-slot:[`item.created`]="{ item }">
                        <td>{{item.created.split('T')[0]}}</td>
                    </template>
                    <!-- User who captured the details -->
                    <template v-slot:[`item.captured_by`]="{ item }" >
                        <td v-if="item.captured_by !== null">
                            {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                        </td>
                    </template>
                    <!-- Display Formated agent -->
                    <template v-slot:[`item.entity`]="{ item }">
                        <td>{{item.entity.name + ' ' + item.entity.surname + ' (' + item.entity.id_number + ')'}}</td>
                    </template>

                    <!-- Display Formated recruiter -->
                    <template v-slot:[`item.recruiting_agent`]="{ item }">
                        <td>
                            {{
                                item.recruiting_agent !== null ?
                                item.recruiting_agent.entity.name + ' ' +
                                item.recruiting_agent.entity.surname + ' (' +
                                item.recruiting_agent.entity.id_number + ')'
                                :
                                ''
                            }}
                        </td>
                    </template>
                    <!-- Display Formated Branch -->
                    <template v-slot:[`item.branch`]="{ item }">
                        <td>
                            {{
                                item.branch !== null ?
                                item.branch.name + '  (' +
                                item.branch.branch_number + ')'
                                :
                                ''
                            }}
                        </td>
                    </template>
                </v-data-table>
            </div>
            <multipane-resizer></multipane-resizer>
            <div class="pane tab-nav" :style="{ height: '30%', maxHeight: '50%', minHeight: '30%' }">
                <v-tabs
                    v-model="sub_tabs"
                    color="transparent"
                    class="tab-nav"
                    hide-slider
                    height="35px"
                    grow
                >
                    <!-- Contracts Sub Tab -->
                    <template>
                        <v-tab key="contracts_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Contracts
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Commission Rules Sub Tab -->
                    <template>
                        <v-tab key="commission_rules_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Commission Rules
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Finance Transactions Sub Tab -->
                    <template>
                        <v-tab key="finance_transaction_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Finance Transactions
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Branch History Sub Tab -->
                    <template>
                        <v-tab key="branch_history_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Branch History
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Allowed Funeral Products Sub Tab -->
                    <template
                        v-if="(
                            selectedAgent !== null &&
                            (
                                selectedAgent.partnership_network === 'STRATEGIC' ||
                                selectedAgent.partnership_network === 'EXTERNAL'
                            )
                        )"
                    >
                        <v-tab key="allowed_funeral_products_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Allowed Funeral Products
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                </v-tabs>
                <v-tabs-items v-model="sub_tabs">
                    <!-- Sub-Tab Toolbar -->
                    <v-toolbar
                        color="secondary"
                        dark
                        class="pa-0"
                        height="50"
                        extension-height="3"
                    >
                        <!-- Show following buttons only for the CONTRACT-DOCUMENTS sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 0 && selectedAgent !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddDocumentsDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Agent Contract Document(s)</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 0  && selectedDocument !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openViewDocumentDialog()"
                                >
                                    <v-icon>content_paste</v-icon>
                                </v-btn>
                            </template>
                            <span>View Contract Document Info</span>
                        </v-tooltip>

                        <!-- Add Commission Rule -->
                        <v-tooltip bottom v-if="sub_tabs === 1 && selectedAgent !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddCommissionRuleDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Commission Rule</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 1  && selectedCommissionRule !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="removeAgentCommissionRule()"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Agent Commission Rule</span>
                        </v-tooltip>

                        <!-- Transactions History Sub-Tab-Buttons -->
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 2 &&
                                selectedAgent !== null
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="amber black--text"
                                    small
                                    @click="refreshAgentTransactions()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh Payment Events</span>
                        </v-tooltip>

                        <!-- Allowed Funeral Products -->
                        <v-tooltip bottom v-if="sub_tabs === 4 && selectedAgent !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddAllowedFuneralProductsDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Allowed Funeral Product</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 4  && selectedAllowedFuneralProduct !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="removeAllowedFuneralProduct()"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Allowed Funeral Product</span>
                        </v-tooltip>

                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <!-- Documents Sub-Tab Content -->
                    <v-tab-item key="contracts_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="contract_details_headers"
                            :items="contracts"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectContractsTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display create date -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- User who captured the details -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                            <!-- Display start_date -->
                            <template v-slot:[`item.start_date`]="{ item }">
                                <td>{{item.start_date.split('T')[0]}}</td>
                            </template>
                            <!-- Display end_date -->
                            <template v-slot:[`item.end_date`]="{ item }">
                                <td>{{item.end_date.split('T')[0]}}</td>
                            </template>
                            <!-- Display approved -->
                            <template v-slot:[`item.approved`]="{ item }">
                                <td>{{item.approved ? 'Yes' : 'No'}}</td>
                            </template>
                            <!-- Display deleted -->
                            <template v-slot:[`item.deleted`]="{ item }">
                                <td>{{item.deleted ? 'Yes' : 'No'}}</td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Commission Rules Sub-Tab Content -->
                    <v-tab-item key="commission_rules_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="commission_rules_headers"
                            :items="commission_rules"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectCommissionRulesTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display create date -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- User who captured the details -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Finance Transaction History Sub-Tab Content -->
                    <v-tab-item key="finance_transaction_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="transactions_headers"
                            :items="transactions"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectTransactionHistoryRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display create date -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- User who captured the details -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Branch History Sub-Tab Content -->
                    <v-tab-item key="branch_history_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="branch_details_headers"
                            :items="branch_history"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectBranchHistoryTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display start date -->
                            <template v-slot:[`item.start_date`]="{ item }">
                                <td>{{item.start_date.split('T')[0]}}</td>
                            </template>
                            <!-- Display create date -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- User who captured the details -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                            <!-- Display Formated Branch -->
                            <template v-slot:[`item.branch`]="{ item }">
                                <td>
                                    {{
                                        item.branch !== null ?
                                        item.branch.name + '  (' +
                                        item.branch.branch_number + ')'
                                        :
                                        ''
                                    }}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Allowed Funeral Products Sub-Tab Content -->
                    <v-tab-item
                        key="allowed_funeral_products_sub_tab"
                        v-if="(
                            selectedAgent !== null &&
                            (
                                selectedAgent.partnership_network === 'STRATEGIC' ||
                                selectedAgent.partnership_network === 'EXTERNAL'
                            )
                        )"
                    >
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="allowed_funeral_products_headers"
                            :items="allowed_funeral_products"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectAllowedFuneralProductTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display create date -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td v-if="item.created === null || item.created === undefined">{{item.created.split('T')[0]}}</td>
                                <td v-else>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- User who captured the details -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                            <!-- Selling premium -->
                            <template v-slot:item.product="{ item }" >
                                <td>
                                    {{
                                        (item.product.primary_product.underwriter_premium + item.product.primary_product.broker_markup) +
                                        (item.product.primary_product.underwriter_premium + item.product.primary_product.broker_markup) * (item.product.markup_percentage/100) +
                                        item.product.markup_fixed
                                    }}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </multipane>
        <OnboardingDialog
            :dialog="open_onboarding_dialog"
        />
        <AddAgentDocumentDialog
            v-if="selectedAgent !== null"
            :dialog="open_add_document_dialog"
            :agent="selectedAgent"
        />
        <ViewDocumentDialog
            v-if="selectedDocument !== null"
            :dialog="open_view_agent_document_dialog"
            :document="selectedDocument"
            param_path="agent"
            :param_id="selectedAgent._id"
        />

        <AddCommissionRuleDialog
            v-if="selectedAgent !== null"
            :dialog="open_add_commission_rule_dialog"
            :agent_id="selectedAgent._id"
        />

        <SearchAgentsDialog
            :dialog="open_search_agents_dialog"
        />
        <EditAgentDetailsDialog
            v-if="selectedAgent !== null"
            :dialog="open_edit_agent_dialog"
            :agent="selectedAgent"
        />
        <UpdateBranchAssignmentDialog
            v-if="selectedAgent !== null"
            :dialog="open_update_agent_branch_dialog"
            :agent="selectedAgent"
        />
        <AgentCommissionsDialog
            :mode="mode"
            :dialog="open_agent_commissions_report_dialog"
            :agent="selectedAgent"
        />
        <AddAllowedFuneralProductDialog 
            v-if="selectedAgent !== null"
            :dialog="open_add_allowed_funeral_products_dialog"
            :agent="selectedAgent"
        />
        <!-- <AgentCommissionsDialog
            v-if="selectedAgent !== null"
            :mode="mode"
            :dialog="open_agent_commissions_report_dialog"
            :agent="selectedAgent"
        /> -->
    </div>
</template>
<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import OnboardingDialog from './dialogs/onboarding';
import EditAgentDetailsDialog from './dialogs/edit_agent_details';
import AddAgentDocumentDialog from './dialogs/add_agent_contracts';
import AddCommissionRuleDialog from './dialogs/add_agent_commission_rule.vue';
import ViewDocumentDialog from '../../components/common_dialogs/view_document_info';
import SearchAgentsDialog from './dialogs/search_agents';
import UpdateBranchAssignmentDialog from './dialogs/update_agent_branch_assignment.vue';
import AgentCommissionsDialog from './dialogs/agent_commissions_report.vue';
import AddAllowedFuneralProductDialog from './dialogs/add_allowed_funeral_product.vue';
import { deleteAgentCommissionRule, emitAlert, emitSuccess } from "../../utils/api";
import { eventBus } from "../../main";

import { getAgents, getFinanceTransactionsList } from "./gql/queries";
import { entityProcessAgent } from "./gql/mutations";
import { store } from "../../../store";
import { BUSINESS_PARTNER_TYPES } from "../../../config";

export default {
    components: {
        Multipane,
        MultipaneResizer,
        OnboardingDialog,
        SearchAgentsDialog,

        EditAgentDetailsDialog,
        AddAgentDocumentDialog,

        AddCommissionRuleDialog,
        ViewDocumentDialog,
        UpdateBranchAssignmentDialog,
        AgentCommissionsDialog,
        AddAllowedFuneralProductDialog,
    },

    data: () => ({
        sub_tabs: null,
        main_tab_height: '250px',
        sub_tab_height:'180',

        // main data-table row
        selectedAgent: null,
        selectedRow: null,
        
        // transaction-history sub-tab data-table row
        selectedTransactionHistory: null,
        selectedTransactionHistoryRow: null,

        // documents sub-tab data-table row
        selectedDocument: null,
        selectedDocumentRow: null,

        selectedCommissionRule: null,
        selectedCommissionRuleRow: null,

        selectedAllowedFuneralProduct: null,
        selectedAllowedFuneralProductRow: null,

        selectedBranchHistory: null,
        selectedBranchHistoryRow: null,
        

        agent_details_headers: [
            { text: 'Agent Number', value: 'agent_number' },
            { text: 'Agent Name', value: 'entity' },
            { text: 'Recruited By', value: 'recruiting_agent' },
            { text: 'Branch', value: 'branch' },
            { text: 'Created', value: 'created' },
            { text: 'Partnership Network', value: 'partnership_network' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        transactions_headers: [
            { text: 'Transaction Date', value: 'transaction_date' },
            { text: 'Created', value: 'created' },
            { text: 'Payment Method', value: 'from.name' },
            // { text: 'Financial Calendar Month', value: 'fin_calendar_month.name' },
            { text: 'Reference', value: 'reference' },
            { text: 'Amount', value: 'amount' },
            { text: 'Running Balance', value: 'balance' },
        ],
        commission_rules_headers: [
            { text: 'Type', value: 'type' },
            { text: 'Amount', value: 'amount' },
            { text: 'Created', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        contract_details_headers: [
            { text: 'Document Type', value: 'type' },
            { text: 'Name', value: 'name' },
            { text: 'Created', value: 'created' },
            { text: 'Start Date', value: 'start_date' },
            { text: 'End Date', value: 'end_date' },
            { text: 'Approved', value: 'approved' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        branch_details_headers: [
            { text: 'Branch', value: 'branch' },
            { text: 'Start Date', value: 'start_date' },
            // { text: 'End Date', value: 'end_date' },
            { text: 'Captured By', value: 'captured_by' },
            { text: 'Created', value: 'created' },
        ],
        allowed_funeral_products_headers: [
            { text: 'Product Name', value: 'product.name' },
            { text: 'Customer Premium', value: 'product' },
            { text: 'Captured By', value: 'captured_by' },
            { text: 'Created At', value: 'created' },
        ],
        
        // Table data models
        transactions: [],
        contracts: [],
        commission_rules: [],
        branch_history: [],
        allowed_funeral_products: [],

        // Dialogs
        
        agents: [],
        open_onboarding_dialog: false,
        open_search_agents_dialog: false,
        
        open_add_document_dialog: false,
        open_view_agent_document_dialog: false,
        open_edit_agent_dialog: false,
        open_update_agent_branch_dialog: false,
        open_agent_commissions_report_dialog: false,
        open_add_allowed_funeral_products_dialog: false,
        mode: 'agent',

        open_add_commission_rule_dialog: false,
        
        data_table_loading: false,
        SEARCH_MODE: false,

        transactions_loading: false,

        page: 1,
        limit: 50,
    }),

    computed: {
        isCentralUnderwriterAdminUser() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'admin - central underwriter'
                                                            );
                                                        }).length > 0;

            return result;
        },
    },

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openOnboardingDialog() {
            this.open_onboarding_dialog = true;
        },
        openEditAgentDetailsDialog() {
            this.open_edit_agent_dialog = true;
        },
        openSearchAgentsDialog() {
            this.unselectSelectedRow();
            this.open_search_agents_dialog = true;
        },
        openAddDocumentsDialog() {
            this.open_add_document_dialog = true;
        },
        openViewDocumentDialog() {
            this.open_view_agent_document_dialog = true;
        },
        openAddCommissionRuleDialog() {
            this.open_add_commission_rule_dialog = true;
        },
        openAgentBranchAssignmentDialog() {
            this.open_update_agent_branch_dialog = true;
        },
        openAgenCommissionsReportDialog(mode) {
            this.mode = mode;
            this.open_agent_commissions_report_dialog = true;
        },
        openAddAllowedFuneralProductsDialog() {
            this.open_add_allowed_funeral_products_dialog = true;
        },

        //------------------- CLOSE DIALOGS---------------------//
        closeOnboardingDialog(new_agent) {
            if (new_agent !== null) {
                this.agents.push(new_agent);
            }
            this.open_onboarding_dialog = false;
        },
        closeEditAgentDialog(new_agent) {
            if (new_agent !== null) {
                const obj_index = this.agents.map(obj => { return obj._id; }).indexOf(new_agent._id)
                this.agents[obj_index] = new_agent;

                 // Deep copy agents for refreshing main data table
                const temp_arr = Array.from(this.agents);
                this.agents = temp_arr;

                // Clear sub-tabs as the main row get automatically unselected
                this.contracts = new_agent.documents.filter(doc => { return !doc.deleted });
                this.commission_rules = new_agent.commission_rules;
                this.transactions = [];
            }

            this.open_edit_agent_dialog = false;
        },
        closeAddAgentDocumentsDialog(new_agent) {
            if (new_agent !== null) {
                const obj_index = this.agents.map(obj => { return obj._id; }).indexOf(new_agent._id)
                this.agents[obj_index] = new_agent;

                 // Deep copy agents for refreshing main data table
                const temp_arr = Array.from(this.agents);
                this.agents = temp_arr;

                // Also update the documents tab
                this.contracts = new_agent.documents.filter(doc => { return !doc.deleted });
                this.commission_rules = new_agent.commission_rules;
            }

            this.open_add_document_dialog = false;
        },
        closeAddCommissionRulesDialog(new_agent) {
            if (new_agent !== null) {
                const obj_index = this.agents.map(obj => { return obj._id; }).indexOf(new_agent._id)
                this.agents[obj_index] = new_agent;

                 // Deep copy agents for refreshing main data table
                const temp_arr = Array.from(this.agents);
                this.agents = temp_arr;

                // Also update the documents tab
                this.contracts = new_agent.documents.filter(doc => { return !doc.deleted });
                this.commission_rules = new_agent.commission_rules;
            }

            this.open_add_commission_rule_dialog = false;
        },
        closeAgentBranchAssignmentDialog(new_agent) {
            if (new_agent !== null) {
                const obj_index = this.agents.map(obj => { return obj._id; }).indexOf(new_agent._id)
                this.agents[obj_index] = new_agent;

                 // Deep copy agents for refreshing main data table
                const temp_arr = Array.from(this.agents);
                this.agents = temp_arr;

                // Also update the documents tab
                this.branch_history = new_agent.branch_history;
            }

            this.open_update_agent_branch_dialog = false;
        },
        closeAddAllowedFuneralProductsDialog(new_agent) {
            if (new_agent !== null) {
                const obj_index = this.agents.map(obj => { return obj._id; }).indexOf(new_agent._id)
                this.agents[obj_index] = new_agent;

                 // Deep copy agents for refreshing main data table
                const temp_arr = Array.from(this.agents);
                this.agents = temp_arr;

                // Also update the documents tab
                this.allowed_funeral_products = new_agent.allowed_funeral_products;
            }

            this.open_add_allowed_funeral_products_dialog = false;
        },
        closeViewServerDocumentsDialog() {
            this.open_view_agent_document_dialog = false;
        },
        closeAgentCommissionsReportDialog() {
            this.open_agent_commissions_report_dialog = false;
        },
        closeSearchAgentsDialog(searchResults) {
            if (searchResults !== null) {
                this.agents = searchResults;
                this.SEARCH_MODE = true;

                // Update agent documents model
                this.contracts = [];
                // Update commission rules
                this.commission_rules = [];
            }
            this.open_search_agents_dialog = false;
        },
        unselectSubTabRows() {
            // Unselect documents sub-tab row
            this.selectedDocument = null;
            if (this.selectedDocumentRow !== null) {
                this.selectedDocumentRow.select(false);
            }

            // Unselect commission rule sub-tab row
            this.selectedCommissionRule = null;
            if (this.selectedCommissionRuleRow !== null) {
                this.selectedCommissionRuleRow.select(false);
            }

            // Unselect allowed funeral product sub-tab row
            this.selectedAllowedFuneralProduct = null;
            if (this.selectedAllowedFuneralProductRow !== null) {
                this.selectedAllowedFuneralProductRow.select(false);
            }

            // Unselect transaction sub-tab row
            this.selectedTransactionHistory = null;
            if (this.selectedTransactionHistoryRow !== null) {
                this.selectedTransactionHistoryRow.select(false);
            }
        },
        selectMainTableRow(item, item_metadata) {
            this.unselectSubTabRows();

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                this.selectedRow = item_metadata;

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                this.selectedAgent = item;
                // Update agent documents model
                this.contracts = item.documents.filter(doc => { return !doc.deleted });
                // Update commission rules
                this.commission_rules = item.commission_rules;
                // Update Branch History
                this.branch_history = item.branch_history;
                // Update Allowed Funeral Products
                this.allowed_funeral_products = item.allowed_funeral_products;
                // Transactions
                this.transactions = [];

                // ----------------------UPDATE VISIBILITY OF ACTION BUTTONS -----------------------//
            } else {
                this.selectedAgent = null;
                // Unighlight selected row
                item_metadata.select(false);
                this.selectedRow = null;

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                // Update agent documents model
                this.contracts = [];
                // Update commission rules
                this.commission_rules = [];
                // Update Branch History
                this.branch_history = [];
                // Update transaction history
                this.transactions = [];
                // Update Allowed Funeral Products
                this.allowed_funeral_products = [];

                // ----------------------UPDATE VISIBILITY OF ACTION BUTTONS -----------------------//
            }
        },
        selectContractsTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedDocument = item;
                this.selectedDocumentRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedDocument = null;
                this.selectedDocumentRow = null;
            }
        },
        selectCommissionRulesTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedCommissionRule = item;
                this.selectedCommissionRuleRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedCommissionRule = null;
                this.selectedCommissionRuleRow = null;
            }
        },
        selectAllowedFuneralProductTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedAllowedFuneralProduct = item;
                this.selectedAllowedFuneralProductRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedAllowedFuneralProduct = null;
                this.selectedAllowedFuneralProductRow = null;
            }
        },
        selectTransactionHistoryRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedTransactionHistory = item;
                this.selectedTransactionHistoryRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedTransactionHistory = null;
                this.selectedTransactionHistoryRow = null;
            }
        },
        selectBranchHistoryTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedBranchHistory = item;
                this.selectedBranchHistoryRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedBranchHistory = null;
                this.selectedBranchHistoryRow = null;
            }
        },

        clearSearchResults() {
            // Unselect a row if there was any
            this.unselectSelectedRow();

            this.SEARCH_MODE = false;
            this.refreshAgents();
        },

        unselectSelectedRow() {
            if (this.selectedRow !== null) {
                this.selectedRow.select(false);
                this.selectedRow = null;

                // Update agent documents model
                this.contracts = [];
                // Update transaction history
                this.transactions = [];
            }
            this.selectedAgent = null;
        },

        async removeAgentCommissionRule() {
            try {
                this.data_table_loading = true;
                
                const res = await deleteAgentCommissionRule(this.selectedAgent._id, this.selectedCommissionRule._id);
                console.log('res: ', res)
                if (res.errors === true) {
                    emitAlert(res.message);
                    this.data_table_loading = false;
                    return;
                }
                
                this.data_table_loading = false;
                emitSuccess(res.message);

                // Prepare data returned
                this.selectedCommissionRule = null;
                this.selectedCommissionRuleRow = null;
                this.commission_rules = res.agent.commission_rules;

                // refresh main data table
                const obj_index = this.agents.map(obj => { return obj._id; }).indexOf(res.agent._id);
                this.agents[obj_index] = res.agent;

                // Deep copy products for refreshing main data table
                const temp_arr = Array.from(this.agents);
                this.agents = temp_arr;
                
                this.selectedAgent = res.agent;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        async removeAllowedFuneralProduct() {
            try {
                this.data_table_loading = true;

                const payloadObj = {
                    OP_TYPE: 'DELETE_ALLOWED_FUNERAL_PRODUCT',
                    _id: this.selectedAgent._id,

                    allowed_funeral_products: [
                        {
                            _id: this.selectedAllowedFuneralProduct._id,
                        }
                    ]
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: entityProcessAgent,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.entityProcessAgent.errors === true) {
                    emitAlert(res.data.entityProcessAgent.message);
                    this.data_table_loading = false;
                    return;
                }
                
                this.data_table_loading = false;
                emitSuccess(res.data.entityProcessAgent.message);

                this.selectedAllowedFuneralProduct = null;
                if (this.selectedAllowedFuneralProductRow !== null) {
                    this.selectedAllowedFuneralProductRow.select(false);
                }

                this.allowed_funeral_products = res.data.entityProcessAgent.business_object.allowed_funeral_products;

                // refresh main data table
                const obj_index = this.agents.map(obj => { return obj._id; }).indexOf(res.data.entityProcessAgent.business_object._id);
                this.agents[obj_index] = res.data.entityProcessAgent.business_object;

                // Deep copy agents for refreshing main data table
                const temp_arr = Array.from(this.agents);
                this.agents = temp_arr;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessAgent.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        // async removeAllowedFuneralProduct() {
        //     try {
        //         this.data_table_loading = true;
                
        //         const res = await deleteAgentCommissionRule(this.selectedAgent._id, this.selectedAllowedFuneralProduct._id);
        //         if (res.errors === true) {
        //             emitAlert(res.message);
        //             this.data_table_loading = false;
        //             return;
        //         }
                
        //         this.data_table_loading = false;
        //         emitSuccess(res.message);

        //         // Prepare data returned
        //         this.selectedAllowedFuneralProduct = null;
        //         this.selectedAllowedFuneralProductRow = null;
        //         this.allowed_funeral_products = res.agent.commission_rules;

        //         // refresh main data table
        //         const obj_index = this.agents.map(obj => { return obj._id; }).indexOf(res.agent._id);
        //         this.agents[obj_index] = res.agent;

        //         // Deep copy products for refreshing main data table
        //         const temp_arr = Array.from(this.agents);
        //         this.agents = temp_arr;
                
        //         this.selectedAgent = res.agent;
        //     } catch (error) {
        //         this.data_table_loading = false;
        //         console.log(error);
        //         if (error !== undefined) {
        //             emitAlert(error.data.message);
        //         } else {
        //             emitAlert('The server has taken too long in responding to your request');
        //         }
                
        //         return;
        //     }
        // },

        async loadMoreData() {
            this.data_table_loading = true;
            this.page += 1;
            
            try {
                // const res = await getAgents(this.page, this.limit);
                // if (res.errors === true) {
                //     emitAlert(res.message);
                //     this.data_table_loading = false;
                //     return;
                // }

                const res = await this.$apollo
                                    .query({
                                        query: getAgents,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'AGENT',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.agents = this.agents.concat(res.data.getBusinessObject.business_objects);
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
    
        async refreshAgents() {
            this.data_table_loading = true;
            this.agents = [];
            this.page = 1;

            try {
                // const res = await getAgents(this.page, this.limit);
                // if (res.errors === true) {
                //     emitAlert(res.message);
                //     this.data_table_loading = false;
                //     return;
                // }

                const res = await this.$apollo
                                    .query({
                                        query: getAgents,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'AGENT',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.agents = this.agents.concat(res.data.getBusinessObject.business_objects);
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        paneResizing(pane, container, size) {
            const size_int = parseInt(size);

            this.main_tab_height = size;
            const max_screen_height = window.innerHeight - 300;

            const sub_tab_percentage = 1 - (size_int/max_screen_height);
            this.sub_tab_height = sub_tab_percentage * max_screen_height;
        },

        async refreshAgentTransactions() {
            try {
                this.transactions_loading = true;
                // const res = await getFinanceTransactionsList(this.selectedAgent._id);
                // if (res.errors === true) {
                //     emitAlert(res.message);
                //     this.transactions_loading = false;
                //     return;
                // }

                const res = await this.$apollo
                                    .query({
                                        query: getFinanceTransactionsList,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'FINANCE_ACCOUNT_TRANSACTION',
                                            pagination: {
                                                page: 1,
                                                limit: 50
                                            },
                                            searchInput: {
                                                account_id: this.selectedAgent._id
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.transactions_loading = false;
                    return;
                }

                this.transactions_loading = false;
                this.transactions = res.data.getBusinessObject.business_objects;

                // Prepare balance
                let runningBalance = 0;
                let runningAmount = 0;
                for (const trans of this.transactions) {
                    if (this.transactions.indexOf(trans) === 0) {
                        if (trans.from._id === this.selectedAgent._id) {
                            trans.balance = trans.from.balance;
                        }
    
                        if (trans.to._id === this.selectedAgent._id) {
                            trans.balance = trans.to.balance;
                        }

                        runningBalance = trans.balance;
                        runningAmount = trans.amount;
                    } else {
                        // NB: We're working the running balances backwards, so we've filpped the signs

                        if (trans.from._id === this.selectedAgent._id) {
                            runningBalance = runningBalance + runningAmount;
                        }
    
                        if (trans.to._id === this.selectedAgent._id) {
                            runningBalance = runningBalance - runningAmount;
                        }
                        trans.balance = runningBalance;
                        runningAmount = trans.amount;
                    }
                }
            } catch (error) {
                this.transactions_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        }
    },

    async mounted() {
        eventBus.$on('CLOSE_CREATE_AGENT_DIALOG', this.closeOnboardingDialog);
        eventBus.$on('CLOSE_EDIT_AGENT_DIALOG', this.closeEditAgentDialog);
        eventBus.$on('CLOSE_ADD_COMMISSION_RULE_DIALOG', this.closeAddCommissionRulesDialog);
        eventBus.$on('CLOSE_ADD_AGENT_CONTRACTS_DIALOG', this.closeAddAgentDocumentsDialog);
        eventBus.$on('CLOSE_DELETE_AGENT_CONTRACT_DIALOG', this.closeDeleteAgentDocumentDialog);
        eventBus.$on('CLOSE_VIEW_DOCUMENT_DIALOG', this.closeViewServerDocumentsDialog);
        eventBus.$on('CLOSE_AGENT_COMMISSIONS_REPORT_DIALOG', this.closeAgentCommissionsReportDialog);
        eventBus.$on('CLOSE_MEMBER_SEARCH_DIALOG', this.closeSearchAgentsDialog);
        eventBus.$on('CLOSE_AGENT_ASSIGNMENT_DIALOG', this.closeAgentBranchAssignmentDialog);
        eventBus.$on('CLOSE_ADD_AGENT_ALLOWED_FUNERAL_PRODUCTS_DIALOG', this.closeAddAllowedFuneralProductsDialog);

        /**
         * The following logic is for manually triggering a pane-divider movement
         * so as to get the windows to properly resize
         */
        this.paneResizing(null, null, 250);

        this.data_table_loading = true;

        try {
            // const res = await getAgents(this.page, this.limit);
            // if (res.errors === true) {
            //     emitAlert(res.message);
            //     this.data_table_loading = false;
            //     return;
            // }

            const res = await this.$apollo
                                    .query({
                                        query: getAgents,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'AGENT',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.data_table_loading = false;
            this.agents = this.agents.concat(res.data.getBusinessObject.business_objects);
            // this.prepareAgentDisplayFields();
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>