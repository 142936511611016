<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="60%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Generate Journals Report</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <!-- Branch -->
                        <!-- <v-col cols="12" sm="12">
                            <v-autocomplete
                                v-model="fin_calendar_month"
                                :items="fin_calendar_months_lookup"
                                color="secondary"
                                label="Financial Calendar Month"
                                item-value="_id"
                                item-text="name"
                            ></v-autocomplete>
                        </v-col> -->
                        <v-col cols="12" sm="6">
                            <v-menu
                                v-model="start_date_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="start_date"
                                    color="secondary"
                                    label="Start Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="start_date"
                                    @input="start_date_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-menu
                                v-model="end_date_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="end_date"
                                    color="secondary"
                                    label="End Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="end_date"
                                    @input="end_date_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Retreiving Journals Report
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitGetJournalsReportBtnDisabled()"
                                @click="downloadJournalsReport()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Get Journals Report</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, getFinanceJournalsReport } from "../../../../utils/api";
import { eventBus } from "../../../../main";

export default {
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        mode: {
            type: String,
            default: null
        },
    },
    data: () => ({
        server_response_obj: null,
        submitting: false,

        fin_calendar_month: null,
        fin_calendar_months_lookup: [],

        start_date_menu: false,
        start_date: null,

        end_date_menu: false,
        end_date: null,

        branch_details: {
            branch: null,
            start_date: null,
        },
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_GENERATE_JOURNALS_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.submitting = false;
            this.start_date = null;
            this.end_date = null;
        },

        submitGetJournalsReportBtnDisabled() {
            if (this.start_date === null) {
                return true;
            }
            if (this.end_date === null) {
                return true;
            }
            return false;
        },

        async downloadJournalsReport() {
            this.submitting = true;
            try {
                let res = null;
                let fileName = `JOURNALS-RPORT-(${this.start_date}-TO-${this.end_date}).xlsx`; // Set the file name
                res = await getFinanceJournalsReport(this.start_date, this.end_date, this.mode);
                
                const url = window.URL.createObjectURL(new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));

                const a = document.createElement("a");
                a.href = url;
                a.download = fileName;

                // Append the anchor to the body
                document.body.appendChild(a);

                // Programmatically click the anchor to trigger the download
                a.click();

                // Remove the anchor from the document
                document.body.removeChild(a);

                // Release the ObjectURL when done
                URL.revokeObjectURL(url);
                
                // if (this.mode === 'agent') {
                // } else {
                //     // global report
                //     res = await getAgentGloabCommissionsReport(this.start_date, this.end_date, this.mode);
                //     emitSuccess(res.message);
                //     this.closeDialog();
                //     this.resetForm();
                // }
                
                
                this.submitting = false;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessScheme.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },
    },
    async mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>