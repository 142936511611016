<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add Allowed Funeral Product</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddProductDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1" color="success">Details</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="2" color="success">Processing</v-stepper-step>
                </v-stepper-header>
            
                <v-stepper-items>
                    <!-- Basic Details -->
                    <v-stepper-content step="1">
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-autocomplete
                                            v-model="allowed_funeral_product.selected_product"
                                            :items="filtered_insurance_product_items"
                                            color="secondary"
                                            label="Insurance Product"
                                            item-text="name"
                                            item-value="_id"
                                            return-object
                                            :disabled="refreshing_insurance_product_lookup_list"
                                        >
                                            <!-- Format prepend inner icon -->
                                            <template v-slot:prepend-inner>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon
                                                            v-on="on"
                                                            color="success"
                                                            @click="refreshInsuranceProductsList()"
                                                        >
                                                            mdi-refresh
                                                        </v-icon>
                                                    </template>
                                                    <span>Refresh Insurance Products List</span>
                                                </v-tooltip>
                                            </template>
                                        </v-autocomplete>
                                        <v-progress-linear
                                            color="green"
                                            class="mb-0"
                                            height="4"
                                            indeterminate
                                            :active="refreshing_insurance_product_lookup_list"
                                        ></v-progress-linear>
                                    </v-col>

                                    <v-col cols="12" sm="3" v-if="allowed_funeral_product.selected_product !== null">
                                        <v-text-field
                                            :value="derived_final_price"
                                            prefix="ZAR"
                                            color="secondary"
                                            label="Customer Price"
                                            type="number"
                                            hint="The policy price sold to the customer"
                                            persistent-hint
                                            disabled
                                            required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    large
                                    color="success"
                                    :disabled="submitAllowedProductBtnDisabled()"
                                    @click="submitProduct()"
                                >
                                    <v-icon large>save</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Submission Results Section -->
                    <v-stepper-content step="2">
                        <v-card>
                            <v-card-text class="text-center">
                                <v-progress-linear
                                    color="green"
                                    class="mb-0"
                                    height="10"
                                    indeterminate
                                    :active="submitting"
                                ></v-progress-linear>
                            </v-card-text>
                            <v-list flat>
                                <v-subheader>Saving Product Info</v-subheader>
                                <v-list-item-group color="primary">
                                    <v-list-item
                                        v-for="(item, i) in backendActionItems"
                                        :key="i"
                                    >
                                        <v-list-item-icon>
                                            <v-icon>done</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    large
                                    color="success"
                                    @click="closeAddProductDialog()"
                                >
                                    <v-icon large>done</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>
    </v-row>
</template>
<script>

import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";

import { getProducts } from "../gql/queries";
import { entityProcessAgent } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        agent: {
            type: Object,
            required: false
        }
    },
    data: () => ({
        e1: 1,
        refreshing_insurance_product_lookup_list: false,
        
        allowed_funeral_product: {
            selected_product: null,
        },

        insurance_product_items: [],

        server_response_obj: null,
        submitting: false,
        backendActionItems: [],

        name_rules: [
            v => !!v || 'Name is required',
            v => v.length >= 2 || 'Min 2 characters'
        ],
    }),
    computed: {
        derived_final_price() {
            if (this.allowed_funeral_product.selected_product !== null) {
                const afrosurance_premium = this.allowed_funeral_product.selected_product.primary_product.underwriter_premium + 
                                                this.allowed_funeral_product.selected_product.primary_product.broker_markup;

                let markup_percentage_val = 0;
                if (!isNaN(parseFloat(this.allowed_funeral_product.selected_product.markup_percentage))) {
                    markup_percentage_val = parseFloat(this.allowed_funeral_product.selected_product.markup_percentage);
                }
                const percentage_markup = afrosurance_premium * (markup_percentage_val/100);

                let fixed_markup = 0;
                if (!isNaN(parseFloat(this.allowed_funeral_product.selected_product.markup_fixed))) {
                    fixed_markup = parseFloat(this.allowed_funeral_product.selected_product.markup_fixed);
                }

                const final_selling_price = afrosurance_premium + percentage_markup + fixed_markup;
                return final_selling_price;
            }
            return 0;
        },
        filtered_insurance_product_items() {
            if (this.agent === null) {
                return [];
            } else {
                return this.insurance_product_items.filter(item => { return item.partnership_network === this.agent.partnership_network; });
            }
        }
    },
    methods: {
        closeAddProductDialog() {
            eventBus.$emit('CLOSE_ADD_AGENT_ALLOWED_FUNERAL_PRODUCTS_DIALOG', this.server_response_obj);
        },
        
        resetForm() {
            this.e1 = 1;

            this.submitting = false;
            this.server_response_obj = null;

            this.backendActionItems = [];
            this.allowed_funeral_product = {
                selected_product: null,
            };
        },

        submitAllowedProductBtnDisabled() {
            // NB: We will not enforce broker commission and markup to be greate than zero
            if (this.allowed_funeral_product.selected_product === null) {
                return true;
            }

            return false;
        },
        
        async submitProduct() {
            // Move to next page
            this.e1 = 2;
            this.submitting = true;

            // Now submit the product to the server
            this.backendActionItems.push("Submiting allowed funeral product");

            try {
                this.submitting = true;

                const payloadObj = {
                    OP_TYPE: 'ADD_ALLOWED_FUNERAL_PRODUCT',

                    _id: this.agent._id,
                    allowed_funeral_products: [
                        {
                            product: this.allowed_funeral_product.selected_product._id
                        }
                    ]
                }

                const res = await this.$apollo
                                            .mutate({
                                                mutation: entityProcessAgent,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.entityProcessAgent.errors === true) {
                    emitAlert(res.data.entityProcessAgent.message);
                    this.submitting = false;
                    return;
                }
                
                this.submitting = false;
                this.server_response_obj = res.data.entityProcessAgent.business_object;

                this.closeAddProductDialog();
                emitSuccess(res.data.entityProcessAgent.message);

                // Reset fields
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessAgent.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
        async refreshInsuranceProductsList() {
            this.insurance_product_items = [];
            this.refreshing_insurance_product_lookup_list = true;
            try {
                // Load Insurance Products
                const res = await this.$apollo
                                        .query({
                                            query: getProducts,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'INSURANCE_PRODUCT_SECONDARY',
                                                pagination: {
                                                    page: this.page,
                                                    limit: this.limit
                                                }
                                            },
                                        });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
                
                this.refreshing_insurance_product_lookup_list = false;
                this.insurance_product_items = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        }
    },
    async mounted() {
        // Load lookup data
        try {
            // Load Insurance Products
            const res = await this.$apollo
                                    .query({
                                        query: getProducts,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'INSURANCE_PRODUCT_SECONDARY',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.insurance_product_items = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
            
            return;
        }
    },
    watch: {
        
    }
}
</script>
<style scoped>
.error-multi-line {
  white-space: pre-line;
}

</style>