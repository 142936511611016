<template>
    <div
        style="
            padding:0px;
            width:100%;
            border-top:3px solid #4590a0;
            border-bottom:3px solid #4590a0;
        "
    >
        <v-toolbar
            color="secondary"
            dark
            height="50"
        >
            <!-- Show following buttons only for the DOCUMENTS sub-tab -->
            <!-- <v-tooltip
                bottom
                v-if="(
                    selectedDocument !== null &&
                    loanObj !== null
                )"
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        color="secondary"
                        small
                        v-on="on"
                        @click="openViewDocumentDialog()"
                    >
                        <v-icon>content_paste</v-icon>
                    </v-btn>
                </template>
                <span>View Document Info</span>
            </v-tooltip> -->

            <!-- <v-tooltip
                bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        color="amber black--text"
                        small
                        @click="refreshLapsablePolicies()"
                        v-on="on"
                    >
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </template>
                <span>Refresh Lapsable Policies</span>
            </v-tooltip> -->
        </v-toolbar>


        <v-data-table
            fixed-header
            :height="sub_tab_height"
            :headers="document_details_headers"
            :items="loanObj.upcoming_policy_payments"
            item-key="_id"
            :single-select="true"
            @click:row="selectDocumentsTabRow"
            class="elevation-1"
            hide-default-footer
            :disable-pagination="true"
        >
            <!-- Display create date -->
            <!-- <template v-slot:item.created="{ item }">
                <td>{{item.created.split('T')[0]}}</td>
            </template> -->
            <!-- Display recording_imported -->
            <!-- <template v-slot:item.recording_imported="{ item }">
                <td v-if="item.recording_imported === true">YES</td>
                <td v-else>NOT-YET</td>
            </template> -->
            <template v-slot:item.expected_amount="{ item }">
                <td>R {{item.expected_amount}}</td>
            </template>
            <template v-slot:item.policy_month_age="{ item }">
                <td>{{item.policy_month_age}} Months</td>
            </template>
            <template v-slot:item.age_day="{ item }">
                <td>{{item.age_day}} Days</td>
            </template>
            <template v-slot:item.policy.payment_information.premium_payer.payment_method="{ item }">
                <td v-if="item.policy.payment_information.premium_payer.payment_method === 'NOT APPLICABLE'">SCHEME</td>
                <td v-else>{{ item.policy.payment_information.premium_payer.payment_method }}</td>
            </template>
            <!-- User who created the details -->
            <!-- <template v-slot:item.captured_by="{ item }" >
                <td v-if="item.captured_by !== null">
                    <span v-if="item.captured_by.entity !== null">
                        {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                    </span>
                    <span v-else>N/A</span>
                </td>
            </template> -->
        </v-data-table>

        <!-- <ViewDocumentDialog
            v-if="selectedDocument !== null"
            :dialog="open_view_loan_document_dialog"
            :document="selectedDocument"
            param_path="loan"
            :param_id="loanObj._id"
        /> -->
    </div>
</template>

<script>
import { eventBus } from "../../../../../../main";
// import { emitAlert } from "../../../../../../utils/api";
// import ViewDocumentDialog from '../../../../../../components/common_dialogs/view_document_info';
// import { getEntityCallRecordings } from "../../../../gql/queries";
export default {
    name: "UpcomingPolicyPayments",
    components: {
        // ViewDocumentDialog
    },
    props: {
        loanObj: {
            required: true,
        },
    },
    data: () => ({
        sub_tab_height:'450',
        document_details_headers: [
            { text: 'Policy Number', value: 'policy.policy_number' },
            { text: 'Policy Age', value: 'policy_month_age' },
            { text: 'Status', value: 'policy.status' },
            { text: 'Payment Date', value: 'policy.elected_payment_date' },
            // { text: 'Premium', value: 'policy.premium' },
            { text: 'Expected Amount', value: 'expected_amount' },
            { text: 'Payment Method', value: 'policy.payment_information.premium_payer.payment_method' },
            { text: 'Days To Payment', value: 'age_day' },
        ],

        selectedDocument: null,
        selectedDocumentRow: null,


        open_view_loan_document_dialog: false,
    }),
    computed: {},
    mounted() {
        eventBus.$on('CLOSE_VIEW_DOCUMENT_DIALOG', this.closeViewServerDocumentsDialog);
    },
    methods: {
        selectDocumentsTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedDocument = item;
                this.selectedDocumentRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedDocument = null;
                this.selectedDocumentRow = null;
            }
        },
        openViewDocumentDialog() {
            this.open_view_loan_document_dialog = true;
        },
        closeViewServerDocumentsDialog() {
            this.open_view_loan_document_dialog = false;
        },
    },
    watch: {
        loanObj() {
            // console.log('CHANGED', val);

            if (this.selectedDocumentRow !== null) {
                // Unselect currently selected row
                this.selectedDocumentRow.select(false);
            }
            this.selectedDocument = null;
            this.selectedDocumentRow = null;
        }
    }
};
</script>
<style>
  .v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
    transform: rotate(180deg);
  }
</style>