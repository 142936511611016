var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"2"}},[(_vm.SEARCH_MODE === false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.loadMoreData()}}},on),[_c('v-icon',[_vm._v("cloud_download")])],1)]}}],null,false,3262684405)},[_c('span',[_vm._v("Load More Data")])]):_vm._e(),(_vm.SEARCH_MODE === false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.refreshAgents()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,247564043)},[_c('span',[_vm._v("Refresh")])]):_vm._e(),(_vm.selectedAgent !== null)?_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openEditAgentDetailsDialog()}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,false,885244235)},[_c('span',[_vm._v("Edit Record")])]):_vm._e()],1),_c('v-col',{attrs:{"sm":"4"}},[(_vm.SEARCH_MODE === true)?_c('v-card-actions',[_c('h3',[_vm._v("Search Results")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"amber black--text"},on:{"click":function($event){return _vm.clearSearchResults()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("search")]),_c('v-icon',{attrs:{"small":""}},[_vm._v("close")])],1)]}}],null,false,1354360713)},[_c('span',[_vm._v("Clear Search Results")])])],1):_vm._e()],1),_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"6"}},[_c('div',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"amber black--text"},on:{"click":function($event){return _vm.openOnboardingDialog()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("person_add")])],1)]}}])},[_c('span',[_vm._v("Onboard a Person")])]),(_vm.selectedAgent !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"purple white--text"},on:{"click":function($event){return _vm.openAgentBranchAssignmentDialog()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-source-branch")]),_vm._v(" Update Agent Branch ")],1)]}}],null,false,12990026)},[_c('span',[_vm._v("Update Agent Branch")])]):_vm._e(),(_vm.selectedAgent !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"black white--text"},on:{"click":function($event){return _vm.openAgenCommissionsReportDialog('agent')}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-book")]),_vm._v(" Get Commissions Report ")],1)]}}],null,false,4213452718)},[_c('span',[_vm._v("Get Commissions Report")])]):_vm._e(),((
                            _vm.isCentralUnderwriterAdminUser
                        ))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"amber black--text"},on:{"click":function($event){return _vm.openAgenCommissionsReportDialog('global')}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-book")]),_vm._v(" Get Global Commissions Report ")],1)]}}],null,false,2878218281)},[_c('span',[_vm._v("Get Global Commissions Report")])]):_vm._e()],1)])],1)],1),_c('multipane',{staticClass:"horizontal-panes",staticStyle:{"height":"100%"},attrs:{"layout":"horizontal"},on:{"paneResize":_vm.paneResizing}},[_c('div',{staticClass:"pane",style:({ height: '50%', maxHeight: '50%'})},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.main_tab_height,"headers":_vm.agent_details_headers,"items":_vm.agents,"item-key":"_id","single-select":true,"item-class":"secondary","hide-default-footer":"","loading":_vm.data_table_loading,"disable-pagination":true},on:{"click:row":_vm.selectMainTableRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}},{key:"item.captured_by",fn:function(ref){
                        var item = ref.item;
return [(item.captured_by !== null)?_c('td',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_vm._e()]}},{key:"item.entity",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.entity.name + ' ' + item.entity.surname + ' (' + item.entity.id_number + ')'))])]}},{key:"item.recruiting_agent",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.recruiting_agent !== null ? item.recruiting_agent.entity.name + ' ' + item.recruiting_agent.entity.surname + ' (' + item.recruiting_agent.entity.id_number + ')' : '')+" ")])]}},{key:"item.branch",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.branch !== null ? item.branch.name + ' (' + item.branch.branch_number + ')' : '')+" ")])]}}],null,true)})],1),_c('multipane-resizer'),_c('div',{staticClass:"pane tab-nav",style:({ height: '30%', maxHeight: '50%', minHeight: '30%' })},[_c('v-tabs',{staticClass:"tab-nav",attrs:{"color":"transparent","hide-slider":"","height":"35px","grow":""},model:{value:(_vm.sub_tabs),callback:function ($$v) {_vm.sub_tabs=$$v},expression:"sub_tabs"}},[[_c('v-tab',{key:"contracts_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Contracts ")]),_c('div',{staticClass:"spacer"})])],[_c('v-tab',{key:"commission_rules_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Commission Rules ")]),_c('div',{staticClass:"spacer"})])],[_c('v-tab',{key:"finance_transaction_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Finance Transactions ")]),_c('div',{staticClass:"spacer"})])],[_c('v-tab',{key:"branch_history_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Branch History ")]),_c('div',{staticClass:"spacer"})])],((
                        _vm.selectedAgent !== null &&
                        (
                            _vm.selectedAgent.partnership_network === 'STRATEGIC' ||
                            _vm.selectedAgent.partnership_network === 'EXTERNAL'
                        )
                    ))?[_c('v-tab',{key:"allowed_funeral_products_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Allowed Funeral Products ")]),_c('div',{staticClass:"spacer"})])]:_vm._e()],2),_c('v-tabs-items',{model:{value:(_vm.sub_tabs),callback:function ($$v) {_vm.sub_tabs=$$v},expression:"sub_tabs"}},[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[(_vm.sub_tabs === 0 && _vm.selectedAgent !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openAddDocumentsDialog()}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,2302782764)},[_c('span',[_vm._v("Add Agent Contract Document(s)")])]):_vm._e(),(_vm.sub_tabs === 0  && _vm.selectedDocument !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openViewDocumentDialog()}}},on),[_c('v-icon',[_vm._v("content_paste")])],1)]}}],null,false,3437369687)},[_c('span',[_vm._v("View Contract Document Info")])]):_vm._e(),(_vm.sub_tabs === 1 && _vm.selectedAgent !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openAddCommissionRuleDialog()}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,163237203)},[_c('span',[_vm._v("Add Commission Rule")])]):_vm._e(),(_vm.sub_tabs === 1  && _vm.selectedCommissionRule !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.removeAgentCommissionRule()}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,false,4074472489)},[_c('span',[_vm._v("Delete Agent Commission Rule")])]):_vm._e(),((
                            _vm.sub_tabs === 2 &&
                            _vm.selectedAgent !== null
                        ))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"amber black--text","small":""},on:{"click":function($event){return _vm.refreshAgentTransactions()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,1937593430)},[_c('span',[_vm._v("Refresh Payment Events")])]):_vm._e(),(_vm.sub_tabs === 4 && _vm.selectedAgent !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openAddAllowedFuneralProductsDialog()}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,290980599)},[_c('span',[_vm._v("Add Allowed Funeral Product")])]):_vm._e(),(_vm.sub_tabs === 4  && _vm.selectedAllowedFuneralProduct !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.removeAllowedFuneralProduct()}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,false,4016083079)},[_c('span',[_vm._v("Delete Allowed Funeral Product")])]):_vm._e(),_c('v-spacer')],1),_c('v-tab-item',{key:"contracts_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.contract_details_headers,"items":_vm.contracts,"item-key":"_id","single-select":true,"hide-default-footer":"","disable-pagination":true},on:{"click:row":_vm.selectContractsTabRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}},{key:"item.captured_by",fn:function(ref){
                        var item = ref.item;
return [(item.captured_by !== null)?_c('td',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_vm._e()]}},{key:"item.start_date",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.start_date.split('T')[0]))])]}},{key:"item.end_date",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.end_date.split('T')[0]))])]}},{key:"item.approved",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.approved ? 'Yes' : 'No'))])]}},{key:"item.deleted",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.deleted ? 'Yes' : 'No'))])]}}],null,true)})],1),_c('v-tab-item',{key:"commission_rules_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.commission_rules_headers,"items":_vm.commission_rules,"item-key":"_id","single-select":true,"hide-default-footer":"","disable-pagination":true},on:{"click:row":_vm.selectCommissionRulesTabRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}},{key:"item.captured_by",fn:function(ref){
                        var item = ref.item;
return [(item.captured_by !== null)?_c('td',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_vm._e()]}}],null,true)})],1),_c('v-tab-item',{key:"finance_transaction_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.transactions_headers,"items":_vm.transactions,"item-key":"_id","single-select":true,"hide-default-footer":"","disable-pagination":true},on:{"click:row":_vm.selectTransactionHistoryRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}},{key:"item.captured_by",fn:function(ref){
                        var item = ref.item;
return [(item.captured_by !== null)?_c('td',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_vm._e()]}}],null,true)})],1),_c('v-tab-item',{key:"branch_history_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.branch_details_headers,"items":_vm.branch_history,"item-key":"_id","single-select":true,"hide-default-footer":"","disable-pagination":true},on:{"click:row":_vm.selectBranchHistoryTabRow},scopedSlots:_vm._u([{key:"item.start_date",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.start_date.split('T')[0]))])]}},{key:"item.created",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}},{key:"item.captured_by",fn:function(ref){
                        var item = ref.item;
return [(item.captured_by !== null)?_c('td',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_vm._e()]}},{key:"item.branch",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.branch !== null ? item.branch.name + ' (' + item.branch.branch_number + ')' : '')+" ")])]}}],null,true)})],1),((
                        _vm.selectedAgent !== null &&
                        (
                            _vm.selectedAgent.partnership_network === 'STRATEGIC' ||
                            _vm.selectedAgent.partnership_network === 'EXTERNAL'
                        )
                    ))?_c('v-tab-item',{key:"allowed_funeral_products_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.allowed_funeral_products_headers,"items":_vm.allowed_funeral_products,"item-key":"_id","single-select":true,"hide-default-footer":"","disable-pagination":true},on:{"click:row":_vm.selectAllowedFuneralProductTabRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
                    var item = ref.item;
return [(item.created === null || item.created === undefined)?_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))]):_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}},{key:"item.captured_by",fn:function(ref){
                    var item = ref.item;
return [(item.captured_by !== null)?_c('td',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_vm._e()]}},{key:"item.product",fn:function(ref){
                    var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s((item.product.primary_product.underwriter_premium + item.product.primary_product.broker_markup) + (item.product.primary_product.underwriter_premium + item.product.primary_product.broker_markup) * (item.product.markup_percentage/100) + item.product.markup_fixed)+" ")])]}}],null,true)})],1):_vm._e()],1)],1)],1),_c('OnboardingDialog',{attrs:{"dialog":_vm.open_onboarding_dialog}}),(_vm.selectedAgent !== null)?_c('AddAgentDocumentDialog',{attrs:{"dialog":_vm.open_add_document_dialog,"agent":_vm.selectedAgent}}):_vm._e(),(_vm.selectedDocument !== null)?_c('ViewDocumentDialog',{attrs:{"dialog":_vm.open_view_agent_document_dialog,"document":_vm.selectedDocument,"param_path":"agent","param_id":_vm.selectedAgent._id}}):_vm._e(),(_vm.selectedAgent !== null)?_c('AddCommissionRuleDialog',{attrs:{"dialog":_vm.open_add_commission_rule_dialog,"agent_id":_vm.selectedAgent._id}}):_vm._e(),_c('SearchAgentsDialog',{attrs:{"dialog":_vm.open_search_agents_dialog}}),(_vm.selectedAgent !== null)?_c('EditAgentDetailsDialog',{attrs:{"dialog":_vm.open_edit_agent_dialog,"agent":_vm.selectedAgent}}):_vm._e(),(_vm.selectedAgent !== null)?_c('UpdateBranchAssignmentDialog',{attrs:{"dialog":_vm.open_update_agent_branch_dialog,"agent":_vm.selectedAgent}}):_vm._e(),_c('AgentCommissionsDialog',{attrs:{"mode":_vm.mode,"dialog":_vm.open_agent_commissions_report_dialog,"agent":_vm.selectedAgent}}),(_vm.selectedAgent !== null)?_c('AddAllowedFuneralProductDialog',{attrs:{"dialog":_vm.open_add_allowed_funeral_products_dialog,"agent":_vm.selectedAgent}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }