import gql from 'graphql-tag'

export const getPolicyProspects = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on ProspectPolicy {
                    __typename
                    _id
                    prospect_number

                    total_cover_amount
                    premium

                    payment_date
                    scheme_level_payment
                    payment_information {
                        premium_payer {
                            payment_method
                            percentage_amount
                        }
                        scheme {
                            payment_method
                            percentage_amount
                        }
                    }

                    principal_member {
                        _id
                        # is_premium_payer
                        settlement_banking
                        gender
                        cover_amount
                        entity {
                            _id
                            name
                            surname
                            id_number
                            addresses {
                                _id
                                type
                                line1
                                line2
                                line3
                                line4
                                created
                                deleted
                                delete_date
                                delete_reason
                            }

                            contact_details {
                                __typename
                                _id
                                type
                                primary
                                details
                                created
                                deleted
                                delete_date
                                delete_reason
                            }

                            documents {
                                __typename
                                _id
                                type
                                mime_type
                                name
                                created
                                deleted
                                delete_date
                                delete_reason
                            }

                            bank_account_details {
                                __typename
                                _id
                                purpose
                                bank {
                                    __typename
                                    _id
                                    name
                                    universal_branch_code
                                }
                                account_type
                                account_number
                                created
                                deleted
                                delete_date
                                delete_reason
                            }
                        }
                    }

                    premium_payer {
                        _id
                        collection_banking
                        gender
                        entity {
                            _id
                            name
                            surname
                            id_number
                            addresses {
                                _id
                                type
                                line1
                                line2
                                line3
                                line4
                                created
                                deleted
                                delete_date
                                delete_reason
                            }

                            contact_details {
                                __typename
                                _id
                                type
                                primary
                                details
                                created
                                deleted
                                delete_date
                                delete_reason
                            }

                            documents {
                                __typename
                                _id
                                type
                                mime_type
                                name
                                created
                                deleted
                                delete_date
                                delete_reason
                            }

                            bank_account_details {
                                __typename
                                _id
                                purpose
                                bank {
                                    __typename
                                    _id
                                    name
                                    universal_branch_code
                                }
                                account_type
                                account_number
                                created
                                deleted
                                delete_date
                                delete_reason
                            }
                        }
                    }

                    dependents {
                        _id
                        spouse_to_principal
                        elected_dependent
                        gender
                        cover_amount
                        entity {
                            _id
                            name
                            surname
                            id_number
                            addresses {
                                _id
                                type
                                line1
                                line2
                                line3
                                line4
                                created
                                deleted
                                delete_date
                                delete_reason
                            }

                            contact_details {
                                __typename
                                _id
                                type
                                primary
                                details
                                created
                                deleted
                                delete_date
                                delete_reason
                            }

                            documents {
                                __typename
                                _id
                                type
                                mime_type
                                name
                                created
                                deleted
                                delete_date
                                delete_reason
                            }

                            bank_account_details {
                                __typename
                                _id
                                purpose
                                bank {
                                    __typename
                                    _id
                                    name
                                    universal_branch_code
                                }
                                account_type
                                account_number
                                created
                                deleted
                                delete_date
                                delete_reason
                            }
                        }
                    }

                    scheme {
                        _id
                        name
                    }
                    agent {
                        _id
                        agent_number
                        entity {
                            _id
                            name
                            surname
                            id_number
                        }
                    }
                    product {
                        _id
                        name
                        markup_fixed
                        markup_percentage

                        primary_product {
                            _id
                            name
                            underwriter_premium
                            broker_commission
                            broker_markup

                            
                            payout_structure {
                                _id
                                payout_amount
                                category {
                                    _id
                                    name
                                    membership_category
                                    start_age
                                    end_age
                                }
                            }
                        }
                    }
                    status
                    commission_status
                    created
                    captured_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }

                    documents {
                        _id
                        type
                        mime_type
                        name

                        start_date
                        end_date

                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                }
            }
        }
    }
`

export const getProducts = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on InsuranceProductSecondary {
                    __typename
                    _id
                    name
                    markup_fixed
                    markup_percentage
                    created
                    captured_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }

                    primary_product {
                        __typename
                        _id
                        name

                        underwriter {
                            _id
                            name
                        }
                        underwriter_premium
                        broker_commission
                        broker_markup
                        active

                        payout_structure {
                            _id
                            category {
                                _id
                                name
                                membership_category
                                start_age
                                end_age
                            }
                            payout_amount
                        }
                        rules {
                            _id
                            rule_parameter {
                                _id
                                id
                                level
                                name
                                description
                                object_key
                            }
                            min
                            max
                        }
                        vas_services {
                            _id
                            limit_model
                            limit
                            created
                            vas_service {
                                _id

                                name
                                # category {
                                #     _id
                                #     name
                                # }
                                description
                                captured_by {
                                    _id
                                    entity {
                                        _id
                                        name
                                        surname
                                    }
                                }
                                created
                            }
                        }
                    }
                }
            }
        }
    }
`

export const getBanks = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on Bank {
                    __typename
                    _id
                    name
                    universal_branch_code
                }
            }
        }
    }
`

export const getSchemes = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on Scheme {
                    __typename
                    _id

                    scheme_number
                    name
                }
            }
        }
    }
`

export const searchSchemesAutocomplete = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on Scheme {
                    __typename
                    _id

                    scheme_number
                    name
                }
            }
        }
    }
`

export const searchAgentAutocomplete = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on Agent {
                    __typename
                    _id

                    agent_number
                    entity {
                        _id
                        name
                        surname
                        id_number
                    }
                }
            }
        }
    }
`

export const getEntities = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on Entity {
                    _id
                    business_partner { _id }
                    name
                    surname
                    id_number
                    gender
                    date_of_birth
                    marital_status
                    life_status
                    marketing_channels

                    version_history {
                        _id
                        changes {
                            type
                            description
                            old
                            new
                            captured_by {
                                __typename
                                _id
                                entity {
                                    __typename
                                    _id
                                    name
                                    surname
                                }
                            }
                            created
                        }
                    }

                    relationships {
                        __typename
                        _id
                        created
                        deleted
                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        member {
                            __typename
                            _id
                            name
                            surname
                        }
                        relationship_type {
                            __typename
                            _id
                            name
                            description
                            gender_map
                            opposite_relationship {
                                __typename
                                _id
                                name
                            }
                            payout_target_roles_for_principal
                        }
                    }
                    
                    addresses {
                        __typename
                        _id
                        type
                        line1
                        line2
                        line3
                        line4
                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    contact_details {
                        __typename
                        _id
                        type
                        primary
                        details

                        captured_by {
                            __typename
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    documents {
                        __typename
                        _id
                        type
                        mime_type
                        name

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    bank_account_details {
                        __typename
                        _id
                        purpose
                        bank {
                            __typename
                            _id
                            name
                            universal_branch_code
                        }
                        account_type
                        account_number

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    captured_by {
                        __typename
                        _id
                        entity {
                            __typename
                            _id
                            name
                            surname
                        }
                    }
                    created
                }
            }
        }
    }
`
export const searchEntityAutocomplete = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on Entity {
                    _id
                    business_partner { _id }
                    name
                    surname
                    id_number
                    gender
                    date_of_birth
                    marital_status
                    life_status
                    marketing_channels

                    # version_history {
                    #     _id
                    #     changes {
                    #         type
                    #         description
                    #         old
                    #         new
                    #         captured_by {
                    #             __typename
                    #             _id
                    #             entity {
                    #                 __typename
                    #                 _id
                    #                 name
                    #                 surname
                    #             }
                    #         }
                    #         created
                    #     }
                    # }

                    # relationships {
                    #     __typename
                    #     _id
                    #     created
                    #     deleted
                    #     captured_by {
                    #         __typename
                    #         _id
                    #         entity {
                    #             __typename
                    #             _id
                    #             name
                    #             surname
                    #         }
                    #     }
                    #     member {
                    #         __typename
                    #         _id
                    #         name
                    #         surname
                    #     }
                    #     relationship_type {
                    #         __typename
                    #         _id
                    #         name
                    #         description
                    #         gender_map
                    #         opposite_relationship {
                    #             __typename
                    #             _id
                    #             name
                    #         }
                    #         payout_target_roles_for_principal
                    #     }
                    # }
                    
                    addresses {
                        __typename
                        _id
                        type
                        line1
                        line2
                        line3
                        line4
                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    contact_details {
                        __typename
                        _id
                        type
                        primary
                        details

                        captured_by {
                            __typename
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    documents {
                        __typename
                        _id
                        type
                        mime_type
                        name

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    bank_account_details {
                        __typename
                        _id
                        purpose
                        bank {
                            __typename
                            _id
                            name
                            universal_branch_code
                        }
                        account_type
                        account_number

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    captured_by {
                        __typename
                        _id
                        entity {
                            __typename
                            _id
                            name
                            surname
                        }
                    }
                    created
                }
            }
        }
    }
`
