var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"0px","width":"100%","border-top":"3px solid #4590a0","border-bottom":"3px solid #4590a0"}},[_c('v-toolbar',{attrs:{"color":"secondary","dark":"","height":"50"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.document_details_headers,"items":_vm.loanObj.upcoming_policy_payments,"item-key":"_id","single-select":true,"hide-default-footer":"","disable-pagination":true},on:{"click:row":_vm.selectDocumentsTabRow},scopedSlots:_vm._u([{key:"item.expected_amount",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v("R "+_vm._s(item.expected_amount))])]}},{key:"item.policy_month_age",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.policy_month_age)+" Months")])]}},{key:"item.age_day",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.age_day)+" Days")])]}},{key:"item.policy.payment_information.premium_payer.payment_method",fn:function(ref){
var item = ref.item;
return [(item.policy.payment_information.premium_payer.payment_method === 'NOT APPLICABLE')?_c('td',[_vm._v("SCHEME")]):_c('td',[_vm._v(_vm._s(item.policy.payment_information.premium_payer.payment_method))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }