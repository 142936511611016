<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add New Product</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddProductDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1" color="success">Details</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="2" color="success">Processing</v-stepper-step>
                </v-stepper-header>
            
                <v-stepper-items>
                    <!-- Basic Details -->
                    <v-stepper-content step="1">
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="product_details.name"
                                            :rules="name_rules"
                                            ref="name_field"
                                            color="secondary"
                                            label="Product Name"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <!-- <v-col cols="12" sm="6" >
                                        <v-select
                                            v-model="product_details.primary_product"
                                            :items="primary_product_select_items"
                                            color="secondary"
                                            item-text="name"
                                            item-value="_id"
                                            label="Primary Product"
                                            return-object
                                            required
                                        ></v-select>
                                    </v-col> -->

                                    <v-col cols="12" sm="4" >
                                        <v-select
                                            v-model="product_details.partnership_network"
                                            :items="['AFROSURANCE', 'STRATEGIC', 'EXTERNAL']"
                                            color="secondary"
                                            label="Partnership Network"
                                            return-object
                                            required
                                        ></v-select>
                                    </v-col>

                                    <v-col cols="12" sm="4">
                                        <v-autocomplete
                                            v-model="product_details.primary_product"
                                            :items="primary_product_select_items"
                                            color="secondary"
                                            label="Primary Product"
                                            item-text="name"
                                            item-value="_id"
                                            return-object
                                            :disabled="refreshing_primary_product_lookup_list"
                                        >
                                            <!-- Format prepend inner icon -->
                                            <template v-slot:prepend-inner>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon
                                                            v-on="on"
                                                            color="success"
                                                            @click="refreshPrimaryProductsList()"
                                                        >
                                                            mdi-refresh
                                                        </v-icon>
                                                    </template>
                                                    <span>Refresh Primary Products List</span>
                                                </v-tooltip>
                                            </template>
                                        </v-autocomplete>
                                        <v-progress-linear
                                            color="green"
                                            class="mb-0"
                                            height="4"
                                            indeterminate
                                            :active="refreshing_primary_product_lookup_list"
                                        ></v-progress-linear>
                                    </v-col>

                                    <v-col cols="12" sm="3" v-if="product_details.primary_product !== null">
                                        <v-text-field
                                            :value="product_details.primary_product.underwriter_premium + product_details.primary_product.broker_markup"
                                            prefix="ZAR"
                                            color="secondary"
                                            label="Underwriter Premium"
                                            type="number"
                                            disabled
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" v-if="product_details.primary_product !== null">
                                        <v-text-field
                                            v-model="product_details.markup_percentage"
                                            color="secondary"
                                            label="Percentage Markup"
                                            type="number"
                                            prefix="%"
                                            hint="If your markup is percentage based"
                                            persistent-hint
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" v-if="product_details.primary_product !== null">
                                        <v-text-field
                                            v-model="product_details.markup_fixed"
                                            prefix="ZAR"
                                            color="secondary"
                                            label="Fixed Markup"
                                            type="number"
                                            hint="If your markup is fixed"
                                            persistent-hint
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" v-if="product_details.primary_product !== null">
                                        <v-text-field
                                            :value="derived_final_price"
                                            prefix="ZAR"
                                            color="secondary"
                                            label="Final Price"
                                            type="number"
                                            hint="The price you will be selling at"
                                            persistent-hint
                                            disabled
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" v-if="product_details.primary_product !== null">
                                        <v-text-field
                                            :value="product_details.primary_product.active === true ? 'YES' : 'NO'"
                                            color="secondary"
                                            label="Active"
                                            hint="Indicates whether the product is available for quoting for the agesnts"
                                            persistent-hint
                                            disabled
                                            required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    large
                                    color="success"
                                    :disabled="submitProductBtnDisabled()"
                                    @click="submitProduct()"
                                >
                                    <v-icon large>save</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Submission Results Section -->
                    <v-stepper-content step="2">
                        <v-card>
                            <v-card-text class="text-center">
                                <v-progress-linear
                                    color="green"
                                    class="mb-0"
                                    height="10"
                                    indeterminate
                                    :active="submitting"
                                ></v-progress-linear>
                            </v-card-text>
                            <v-list flat>
                                <v-subheader>Saving Product Info</v-subheader>
                                <v-list-item-group color="primary">
                                    <v-list-item
                                        v-for="(item, i) in backendActionItems"
                                        :key="i"
                                    >
                                        <v-list-item-icon>
                                            <v-icon>done</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    large
                                    color="success"
                                    @click="closeAddProductDialog()"
                                >
                                    <v-icon large>done</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>
    </v-row>
</template>
<script>

import { emitAlert, emitSuccess } from "../../../../../utils/api";
import { eventBus } from "../../../../../main";

import { getProducts } from "../../product_primary/gql/queries";
import { productProcessInsuranceProductSecondary } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        e1: 1,
        refreshing_primary_product_lookup_list: false,
        
        product_details: {
            name: '',
            primary_product: null,
            markup_percentage: 0,
            markup_fixed: 0,
            partnership_network: null
        },

        primary_product_select_items: [],

        server_response_obj: null,
        submitting: false,
        backendActionItems: [],

        name_rules: [
            v => !!v || 'Name is required',
            v => v.length >= 2 || 'Min 2 characters'
        ],
    }),
    computed: {
        derived_final_price() {
            if (this.product_details.primary_product !== null) {
                const afrosurance_premium = this.product_details.primary_product.underwriter_premium + 
                                                this.product_details.primary_product.broker_markup;

                let markup_percentage_val = 0;
                if (!isNaN(parseFloat(this.product_details.markup_percentage))) {
                    markup_percentage_val = parseFloat(this.product_details.markup_percentage);
                }
                const percentage_markup = afrosurance_premium * (markup_percentage_val/100);

                let fixed_markup = 0;
                if (!isNaN(parseFloat(this.product_details.markup_fixed))) {
                    fixed_markup = parseFloat(this.product_details.markup_fixed);
                }

                const final_selling_price = afrosurance_premium + percentage_markup + fixed_markup;
                return final_selling_price;
            }
            return 0;
        }
    },
    methods: {
        closeAddProductDialog() {
            eventBus.$emit('CLOSE_ADD_PRODUCT_DIALOG', this.server_response_obj);
        },
        
        resetForm() {
            this.e1 = 1;

            this.submitting = false;
            this.server_response_obj = null;

            this.backendActionItems = [];
            this.product_details = {
                name: '',
                primary_product: null,
                markup_percentage: 0,
                markup_fixed: 0,
                partnership_network: null,
            };

            this.$refs.name_field.resetValidation();
        },

        submitProductBtnDisabled() {
            // NB: We will not enforce broker commission and markup to be greate than zero
            if (
                this.product_details.name.trim().length < 2 ||
                this.product_details.primary_product === null ||
                this.product_details.partnership_network === null
            ) {
                return true;
            }

            this.product_details.name = this.product_details.name.trim();

            return false;
        },
        
        async submitProduct() {
            // Move to next page
            this.e1 = 2;
            this.submitting = true;

            // Now submit the product to the server
            this.backendActionItems.push("Submiting product");

            try {
                this.submitting = true;

                this.product_details.primary_product = this.product_details.primary_product._id;
                this.product_details.markup_fixed = parseFloat(this.product_details.markup_fixed);
                this.product_details.markup_percentage = parseFloat(this.product_details.markup_percentage);

                const payloadObj = {
                    OP_TYPE: 'CREATE',

                    ...this.product_details
                }

                const res = await this.$apollo
                                            .mutate({
                                                mutation: productProcessInsuranceProductSecondary,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.productProcessInsuranceProductSecondary.errors === true) {
                    emitAlert(res.data.productProcessInsuranceProductSecondary.message);
                    this.submitting = false;
                    return;
                }
                
                this.submitting = false;
                this.server_response_obj = res.data.productProcessInsuranceProductSecondary.business_object;

                this.closeAddProductDialog();
                emitSuccess(res.data.productProcessInsuranceProductSecondary.message);

                // Reset fields
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.productProcessInsuranceProductSecondary.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
        async refreshPrimaryProductsList() {
            this.primary_product_select_items = [];
            this.refreshing_primary_product_lookup_list = true;
            try {
                // Load Primary Products
                const res = await this.$apollo
                                        .query({
                                            query: getProducts,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'INSURANCE_PRODUCT_PRIMARY',
                                                pagination: {
                                                    page: this.page,
                                                    limit: this.limit
                                                }
                                            },
                                        });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
                
                this.refreshing_primary_product_lookup_list = false;
                this.primary_product_select_items = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        }
    },
    async mounted() {
        // Load lookup data
        try {
            // Load Primary Products
            const res = await this.$apollo
                                    .query({
                                        query: getProducts,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'INSURANCE_PRODUCT_PRIMARY',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.primary_product_select_items = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
            
            return;
        }
    },
    watch: {
        
    }
}
</script>
<style scoped>
.error-multi-line {
  white-space: pre-line;
}

</style>