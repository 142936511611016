<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Softy-Comp Feed Search</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeMemberSearchDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <!-- Date -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.date.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-menu
                                v-model="date_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="search_fields.date.value"
                                    label="Date"
                                    color="secondary"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="!search_fields.date.search"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="search_fields.date.value"
                                    @input="date_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>

                        <!-- Description / Reference -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.description_and_ref.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-text-field
                                v-model="search_fields.description_and_ref.value"
                                label="Description / Reference"
                                type="text"
                                color="secondary"
                                required
                                :disabled="!search_fields.description_and_ref.search"
                            ></v-text-field>
                        </v-col>

                        <!-- Contract Code -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.contract_code.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-text-field
                                v-model="search_fields.contract_code.value"
                                label="Contract Code"
                                type="text"
                                color="secondary"
                                required
                                :disabled="!search_fields.contract_code.search"
                            ></v-text-field>
                        </v-col>

                        <!-- No Classification -->
                        <!-- <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.classification.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-text-field
                                v-model="search_fields.classification.value"
                                label="Bank Feeds with No classification"
                                type="text"
                                color="secondary"
                                required
                                :disabled="true"
                            ></v-text-field>
                        </v-col> -->
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Searching Bank Feed...
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitBtnDisabled()"
                                @click="submitSearchQuery()"
                            >
                                <v-icon large>search</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Search Query</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from '../../../../main';
import { emitAlert } from '../../../../utils/api';
import { getSoftyCompFeed } from "../gql/queries";
export default {
    name: 'SearchBankFeedDialog',
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        date_menu: false,
        search_fields: {
            date: {
                search: false,
                value: null,
            },
            description_and_ref: {
                search: false,
                value: null,
            },
            contract_code: {
                search: false,
                value: null,
            },
            classification: {
                search: false,
                value: 'NO-CLASSIFICATION',
            },
        },
        submitting: false
    }),
    methods: {
        closeMemberSearchDialog() {
            eventBus.$emit('CLOSE_BANK_FEED_SEARCH_DIALOG', null);
        },
        resetForm() {
            this.search_fields = {
                description_and_ref: {
                    search: false,
                    value: null,
                },
                contract_code: {
                    search: false,
                    value: null,
                },
                date: {
                    search: false,
                    value: null,
                },
                classification: {
                    search: false,
                    value: 'NO-CLASSIFICATION',
                },
            };
        },
        submitBtnDisabled() {
            const searchObj = {};
            Object.keys(this.search_fields).forEach(searchField => {
                if (this.search_fields[ searchField ].search === true) {
                    if (
                        this.search_fields[ searchField ].value !== null &&
                        this.search_fields[ searchField ].value !== undefined
                    ) {
                        searchObj[ searchField ] = this.search_fields[ searchField ].value;
                    }

                    // Make sure we don't send empty strings
                    if (typeof(this.search_fields[ searchField ].value) === 'string') {
                        if (this.search_fields[ searchField ].value.trim().length === 0) {
                            delete searchObj[ searchField ];
                        }
                    }
                }
            });

            if (Object.keys(searchObj).length === 0) {
                return true;
            }
            return false;
        },
        async submitSearchQuery() {
            this.submitting = true;

            const searchObj = this.extractSubmitPayload();

            try {
                const res = await this.$apollo
                                        .query({
                                            query: getSoftyCompFeed,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'SOFTY_COMP_FEED',
                                                pagination: {
                                                    page: 1,
                                                    limit: 1000
                                                },
                                                searchInput: searchObj
                                            },
                                        });
                this.submitting = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }

                eventBus.$emit('CLOSE_BANK_FEED_SEARCH_DIALOG', res.data.getBusinessObject.business_objects);
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        extractSubmitPayload() {
            const resultObj = {};

            // Date
            if (
                this.search_fields.date.search === true &&
                this.search_fields.date.value !== null &&
                this.search_fields.date.value !== undefined
            ) {
                resultObj.date = this.search_fields.date.value;
            }

            // Description + Reference
            if (
                this.search_fields.description_and_ref.search === true &&
                this.search_fields.description_and_ref.value !== null &&
                this.search_fields.description_and_ref.value !== undefined
            ) {
                if (typeof(this.search_fields.description_and_ref.value) === 'string') {
                    if (this.search_fields.description_and_ref.value.trim() !== '') {
                        // resultObj.description = this.search_fields.description_and_ref.value;
                        resultObj.reference = this.search_fields.description_and_ref.value.trim();
                    }
                }
            }

            // Contract Code
            if (
                this.search_fields.contract_code.search === true &&
                this.search_fields.contract_code.value !== null &&
                this.search_fields.contract_code.value !== undefined
            ) {
                if (typeof(this.search_fields.contract_code.value) === 'string') {
                    if (this.search_fields.contract_code.value.trim() !== '') {
                        resultObj.contract_code = this.search_fields.contract_code.value;
                        // resultObj.description = this.search_fields.contract_code.value;
                        // resultObj.reference = this.search_fields.contract_code.value.trim();
                    }
                }
            }

            // // Classification
            // if (
            //     this.search_fields.classification.search === true
            // ) {
            //     resultObj.classification = null;
            // }

            return resultObj;
        }
    },
    mounted() {
        
    },
    computed: {},
    watch: {}
}
</script>
<style scoped>

</style>