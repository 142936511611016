<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Capture Loan Payout Transaction</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddLoanTransactionDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row style="background:#eaeaea; padding-bottom:10px">
                        <!-- Bank Feed -->
                        <v-col cols="12" sm="12">
                            <BankFeedSearch
                                :item="bank_feed_search"
                                :unclassified_filter="true"
                            />
                        </v-col>
                    </v-row>
                    <br><br>
                    <v-row
                        v-if="bank_feed_search.bank_feed_item !== null"
                        style="background:#eaeaea; padding-bottom:10px"
                    >
                        <v-col cols="12" sm="12">
                            <h2>Bank Feed Details</h2>
                        </v-col>
                        <!-- Date -->
                        <v-col cols="12" sm="2">
                            <v-text-field
                                :value="bank_feed_search.bank_feed_item.date.split('T')[0]"
                                color="secondary"
                                label="Transaction Date"
                                disabled
                                required
                            ></v-text-field>
                            <!-- <v-menu
                                v-model="transaction_date_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="premium_details.transaction_date"
                                    color="secondary"
                                    label="Transaction Date"
                                    hint="The date on which the transaction occurred"
                                    persistent-hint
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="premium_details.transaction_date"
                                    @input="transaction_date_menu = false"
                                ></v-date-picker>
                            </v-menu> -->
                        </v-col>
                        <!-- Description -->
                        <v-col cols="12" sm="4">
                            <v-text-field
                                :value="bank_feed_search.bank_feed_item.description"
                                color="secondary"
                                label="Transaction Description"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- Description -->
                        <v-col cols="12" sm="3">
                            <v-text-field
                                :value="bank_feed_search.bank_feed_item.reference"
                                color="secondary"
                                label="Transaction Reference"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- Amount -->
                        <v-col cols="12" sm="2">
                            <v-text-field
                                :value="bank_feed_search.bank_feed_item.amount"
                                color="secondary"
                                label="Amount"
                                prefix="R"
                                disabled
                                required
                            ></v-text-field>
                            <!-- <v-text-field
                                v-model="premium_details.additional_reference"
                                color="secondary"
                                label="Customer Reference"
                                hint="For EFT or CASH or ATM clients"
                                persistent-hint
                                required
                            ></v-text-field> -->
                        </v-col>

                        <!-- Classified -->
                        <v-col
                            cols="12"
                            sm="1"
                            :style="bank_feed_search.bank_feed_item.classification !== null ? 'background-color: rgb(234, 121, 121);' : 'background-color: green'">
                            <v-text-field
                                :value="bank_feed_search.bank_feed_item.classification === null ? 'NO' : 'YES'"
                                color="secondary"
                                label="Classified"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        
                        <!-- <v-col cols="12" sm="2">
                            <v-text-field
                                v-model="premium_details.amount"
                                color="secondary"
                                label="Amount"
                                type="number"
                                prefix="R"
                                required
                            ></v-text-field>
                        </v-col> -->
                    </v-row>
                </v-card-text>
                <!-- Progress Section -->
                <v-progress-linear
                    color="green"
                    class="mb-0"
                    height="10"
                    indeterminate
                    :active="submitting"
                ></v-progress-linear>
                <v-list flat v-if="submitting">
                    <v-subheader>Saving Loan Transaction</v-subheader>
                    <!-- <v-list-item-group v-model="item" color="primary"> -->
                    <v-list-item-group color="primary">
                        <v-list-item
                            v-for="(item, i) in backendActionItems"
                            :key="i"
                        >
                            <v-list-item-icon>
                                <v-icon>done</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>

                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        large
                        color="success"
                        :disabled="submitLoanDocumentBtnDisabled()"
                        @click="saveLoanTransaction()"
                    >
                        <v-icon large>save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import BankFeedSearch from "../components/bank_feed_loan_payout_search.vue";
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";
import config from "../../../../config";

import { businessProcessLoan } from "../gql/mutations";

export default {
    components: {
        BankFeedSearch,
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        loan: {
            type: Object,
            required: false,
            default: () => {
                return {
                    // name,
                    documents: []
                }
            }
        },
    },
    data: () => ({
        server_resp_obj: null,
        transaction_date_menu: false,
        submitting: false,

        premium_details: {
            transaction_date: null,
            additional_reference: null,
            payment_method: null,
            amount: 0,
        },

        bank_feed_search: {
            bank_feed_item: null
        },

        backendActionItems: [],
    }),
    methods: {
        closeAddLoanTransactionDialog() {
            eventBus.$emit('CLOSE_CAPTURE_LOAN_PAYOUT_DIALOG', this.server_resp_obj);
        },
        resetForm() {
            this.premium_details = {
                transaction_date: null,
                payment_method: null,
                additional_reference: null,
                amount: 0,
            };
            this.bank_feed_search.bank_feed_item = null;
            this.server_resp_obj = null;
            this.backendActionItems = [];
        },

        submitLoanDocumentBtnDisabled() {
            if (this.bank_feed_search.bank_feed_item === null) {
                return true;
            }
            
            return false;
        },
        
        async saveLoanTransaction() {
            if (this.loan.status === config.LOAN_STATUSES.DRAFT) {
                emitAlert(`Adding a premium payment is not allowed for loan in status: ${this.loan.status}`);
                return;
            }

            this.submitting = true;
            try {
                const transactionObj = {
                    OP_TYPE: 'CAPTURE_PAYOUT_TRANSACTION',
                    _id: this.loan._id,
                    bank_feed_id: this.bank_feed_search.bank_feed_item._id,
                };

                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessLoan,
                                                variables: {
                                                    input: transactionObj
                                                },
                                            });

                this.submitting = false;
                if (res.data.businessProcessLoan.errors === true) {
                    emitAlert(res.data.businessProcessLoan.message);
                    return;
                }

                emitSuccess(res.data.businessProcessLoan.message);

                this.server_resp_obj = res.data.businessProcessLoan.business_object;
                this.closeAddLoanTransactionDialog();

                // Reset form
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessLoan.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    async mounted() {},
    watch: {
        'loan': function() {
            this.bank_feed_search.bank_feed_item = null;
        }
    }
}
</script>
<style scoped>

</style>