<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="5" style="margin-top:10px">
                    <v-tooltip bottom v-if="SEARCH_MODE === false">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                @click="loadMoreData()"
                                v-on="on"
                            >
                                <v-icon>cloud_download</v-icon>
                            </v-btn>
                        </template>
                        <span>Load More Data</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshDataTable()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="openSearchBankFeedDialog()"
                            >
                                <v-icon>search</v-icon>
                            </v-btn>
                        </template>
                        <span>Search for Bank Feed Transactions</span>
                    </v-tooltip>
                </v-col>

                <!-- Search Result Indicator -->
                <v-col sm="3">
                    <v-card-actions v-if="SEARCH_MODE === true">
                        <h3>Search Results <span style="color: #f6c209;">[ {{ this.bank_feed_list.length }} records ]</span></h3>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="clearSearchResults()"
                                    v-on="on"
                                >
                                    <v-icon small>search</v-icon>
                                    <v-icon small>close</v-icon>
                                </v-btn>
                            </template>
                            <span>Clear Search Results</span>
                        </v-tooltip>
                    </v-card-actions>
                </v-col>

                <!-- Action Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <div class="text-right">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openCaptureBankFeedDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Bank</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%" @paneResize="paneResizing">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="bank_details_headers"
                    :items="bank_feed_list"
                    item-key="_id"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :disable-pagination="true"
                    :loading="data_table_loading"
                    loading-text="Loading... Please wait"
                >
                    <!-- Display Formated date -->
                    <template v-slot:item.transaction_date="{ item }">
                        <td>{{item.transaction_date.split('T')[0]}}</td>
                    </template>
                    <!-- Display Formated created date -->
                    <template v-slot:item.created="{ item }">
                        <td>{{item.created !== null ? item.created.split('T')[0] + '  @  ' + item.created.split('T')[1].substr(0,8) : ''}}</td>
                    </template>
                </v-data-table>
            </div>
            <multipane-resizer></multipane-resizer>
        </multipane>
        <CaptureBankFeedDialog
            :dialog="open_add_bank_dialog"
        />
        <SearchBankFeedDialog
            :dialog="open_search_bank_feed_dialog"
        />
    </div>
</template>
<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import { emitAlert } from '../../../utils/api';
import CaptureBankFeedDialog from './dialogs/capture_softy_comp_feed.vue';
import SearchBankFeedDialog from './dialogs/search_bank_feed.vue';
import { eventBus } from "../../../main";

import { getSoftyCompFeed } from "./gql/queries";

export default {
    components: {
        Multipane,
        MultipaneResizer,
        CaptureBankFeedDialog,
        SearchBankFeedDialog,
    },

    data: () => ({
        main_tab_height: '250px',
        sub_tab_height:'180',

        selectedBank: null,
        selectedRow: null,
        data_table_loading: false,

        bank_details_headers: [
            { text: 'Contract Code', value: 'contract_code' },
            { text: 'Reference', value: 'reference' },
            { text: 'Transaction Type', value: 'transaction_type' },
            { text: 'Account Name', value: 'account_name' },
            { text: 'Account Number', value: 'account_number' },
            { text: 'Amount', value: 'amount' },
            { text: 'Transaction Date', value: 'transaction_date' },
            { text: 'Status Description', value: 'status_description' },
            { text: 'Created', value: 'created' },
        ],
        
        // Table data models
        bank_feed_list: [],

        // Dialogs
        open_add_bank_dialog: false,
        open_search_bank_feed_dialog: false,

        page: 1,
        limit: 200,
        SEARCH_MODE: false,
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openCaptureBankFeedDialog() {
            // Open onboarding dialog
            this.open_add_bank_dialog = true;
        },
        openSearchBankFeedDialog() {
            this.open_search_bank_feed_dialog = true;
        },

        //------------------- CLOSE DIALOGS---------------------//
        closeCaptureBankFeedDialog(bankFeedList) {
            if (bankFeedList !== null) {
                this.bank_feed_list = this.bank_feed_list.concat(bankFeedList);
            }

            this.open_add_bank_dialog = false;
        },
        closeSearchBankFeedDialog(searchResults) {
            if (searchResults !== null) {
                this.bank_feed_list = searchResults;
                this.SEARCH_MODE = true;
            }
            this.open_search_bank_feed_dialog = false;
        },
        selectMainTableRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                this.selectedBank = item;
            } else {
                this.selectedBank = null;
                // Unighlight selected row
                item_metadata.select(false);
            }
        },
        clearSearchResults() {
            // Unselect a row if there was any
            this.unselectSelectedRow();

            this.SEARCH_MODE = false;
            this.refreshDataTable();
        },
        unselectSelectedRow() {
            if (this.selectedRow !== null) {
                this.selectedRow.select(false);
                this.selectedRow = null;
            }
            this.selectedBank = null;
        },
        updateUITableModel(new_bank_feed_item) {
            if (new_bank_feed_item !== null) {
                const obj_index = this.bank_feed_list.map(obj => { return obj._id; }).indexOf(new_bank_feed_item._id)
                this.bank_feed_list[obj_index] = new_bank_feed_item;

                this.selectedLoan = new_bank_feed_item;
                this.selectedLoanCopy = JSON.parse( JSON.stringify(new_bank_feed_item) );

                 // Deep copy bank_feed_list for refreshing main data table
                const temp_arr = Array.from(this.bank_feed_list);
                this.bank_feed_list = temp_arr;
            }
        },
        async refreshDataTable() {
            this.bank_feed_list = [];
            this.page = 1;

            try {
                this.data_table_loading = true;
                const res = await this.$apollo
                                    .query({
                                        query: getSoftyCompFeed,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'SOFTY_COMP_FEED',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.bank_feed_list = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async loadMoreData() {
            this.page += 1;

            try {
                this.data_table_loading = true;
                const res = await this.$apollo
                                    .query({
                                        query: getSoftyCompFeed,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'SOFTY_COMP_FEED',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.bank_feed_list = this.bank_feed_list.concat(res.data.getBusinessObject.business_objects);
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        paneResizing(pane, container, size) {
            const size_int = parseInt(size);

            this.main_tab_height = size;
            const max_screen_height = window.innerHeight - 300;

            const sub_tab_percentage = 1 - (size_int/max_screen_height);
            this.sub_tab_height = sub_tab_percentage * max_screen_height;
        }
    },

    async mounted() {
        /**
         * The following logic is for manually triggering a pane-divider movement
         * so as to get the windows to properly resize
         */
        this.paneResizing(null, null, 600);

        try {
            this.data_table_loading = true;
            const res = await this.$apollo
                                    .query({
                                        query: getSoftyCompFeed,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'SOFTY_COMP_FEED',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.data_table_loading = false;
            this.bank_feed_list = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }

        eventBus.$on('CLOSE_CAPTURE_SOFTY_COMP_FEED_DIALOG', this.closeCaptureBankFeedDialog);
        eventBus.$on('CLOSE_BANK_FEED_SEARCH_DIALOG', this.closeSearchBankFeedDialog);
    },

    watch: {
        
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>