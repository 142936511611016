var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.loadMoreData()}}},on),[_c('v-icon',[_vm._v("cloud_download")])],1)]}}])},[_c('span',[_vm._v("Load More Data")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.refreshUsers()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh")])])],1),_c('v-col',{attrs:{"sm":"4"}}),_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"4"}},[_c('div',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"black white--text"},on:{"click":function($event){return _vm.openGenerateJournalsDialog()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-book")]),_vm._v(" Generate Journals Report ")],1)]}}])},[_c('span',[_vm._v("Generate Journals Report")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"amber black--text"},on:{"click":function($event){return _vm.openAddClassificationDialog()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Add a New Classification")])])],1)])],1)],1),_c('multipane',{staticClass:"horizontal-panes",staticStyle:{"height":"100%"},attrs:{"layout":"horizontal"},on:{"paneResize":_vm.paneResizing}},[_c('div',{staticClass:"pane",style:({ height: '50%', maxHeight: '50%'})},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.main_tab_height,"headers":_vm.user_details_headers,"items":_vm.users,"item-key":"_id","single-select":true,"item-class":"secondary","hide-default-footer":"","loading":_vm.data_table_loading,"disable-pagination":true},on:{"click:row":_vm.selectMainTableRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}},{key:"item.captured_by",fn:function(ref){
var item = ref.item;
return [(item.captured_by !== null && item.captured_by !== undefined)?_c('td',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_vm._e()]}}],null,true)})],1),_c('multipane-resizer'),_c('div',{staticClass:"pane tab-nav",style:({ height: '30%', maxHeight: '50%', minHeight: '30%' })},[_c('v-tabs',{staticClass:"tab-nav",attrs:{"color":"transparent","hide-slider":"","height":"35px","grow":""},model:{value:(_vm.sub_tabs),callback:function ($$v) {_vm.sub_tabs=$$v},expression:"sub_tabs"}},[[_c('v-tab',{key:"statement_keywords_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Statement Keywords ")]),_c('div',{staticClass:"spacer"})])]],2),_c('v-tabs-items',{model:{value:(_vm.sub_tabs),callback:function ($$v) {_vm.sub_tabs=$$v},expression:"sub_tabs"}},[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[_c('v-spacer')],1),_c('v-tab-item',{key:"statement_keywords_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.user_role_details_headers,"items":_vm.statement_keywords,"item-key":"_id","single-select":true,"item-class":"secondary","hide-default-footer":""},on:{"click:row":_vm.selectUserRoleTabRow}})],1)],1)],1)],1),_c('AddClassificationDialog',{attrs:{"dialog":_vm.open_add_user_dialog}}),_c('GenerateJournalsDialog',{attrs:{"dialog":_vm.open_generate_journals_dialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }